import { AbstractBexWISEService } from "../bexWISEResolutionService/AbstractBexWISEService";
import {
   IAppointmentDataService,
   IBookAppointmentRequest,
   IBookAppointmentResponse,
   ICancelAppointmentResponse,
   INonAuthenticatedDonorAppointmentRequest,
   IRescheduleAppointmentRequest,
} from "./IAppointmentDataService";
import { IServerErrorArrayResponse } from "../../../types/IServerErrorArrayResponse";
import { IAppointment } from "../../../types/IAppointment";

export class AppointmentDataService
   extends AbstractBexWISEService
   implements IAppointmentDataService
{
   public bookAppointment(
      bookAppointmentRequest: IBookAppointmentRequest,
   ): Promise<IBookAppointmentResponse> {
      return this.postBex(
         `donors/${bookAppointmentRequest.donorId}/appointments`,
         bookAppointmentRequest,
      );
   }

   public cancelAppointment(
      donorId: string,
      appointmentId: string,
   ): Promise<ICancelAppointmentResponse> {
      return this.deleteBex(`donors/${donorId}/appointments/${appointmentId}`);
   }

   public requestAppointment(
      appointmentRequest: INonAuthenticatedDonorAppointmentRequest,
   ): Promise<IServerErrorArrayResponse> {
      return this.postBex(`appointments/request`, appointmentRequest);
   }

   public rescheduleAppointment(
      rescheduleAppointmentRequest: IRescheduleAppointmentRequest,
      reschedulingAppointmentId: number,
   ): Promise<IServerErrorArrayResponse> {
      return this.putBex(
         `donors/${rescheduleAppointmentRequest.donorId}/appointments/${reschedulingAppointmentId}`,
         rescheduleAppointmentRequest,
      );
   }

   public getDonorAppointment(
      donorId: string,
      appointmentId: string,
   ): Promise<IAppointment> {
      return this.getBex<IAppointment>(
         `donors/${donorId}/appointments/${appointmentId}`,
      );
   }

   public getDonorAppointments(donorId: string): Promise<IAppointment[]> {
      return this.getBex<IAppointment[]>(`donors/${donorId}/appointments`);
   }
}
