import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import { PRIMARY_COLORS } from "../../../../../consts/colors";

export const DropdownToggle = styled(Dropdown.Toggle)`
   color: black;
   font-size: 11px !important;
   background: transparent;
   gap: 15px;
   display: flex;
   align-items: center;
   font-size: 14px;
   height: inherit;
   border: ${props =>
      props.error
         ? `1px solid ${PRIMARY_COLORS.JASPER}`
         : `1px solid ${PRIMARY_COLORS.QUILL_GREY}`};
   &:hover,
   :focus {
      color: ${PRIMARY_COLORS.DARK};
      background-color: ${PRIMARY_COLORS.WHITE};
      border-color: ${PRIMARY_COLORS.JORDY_BLUE};
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
   }
`;

export const StyledDropdown = styled(Dropdown)`
   height: 50px;
   width: 100%;
   transition: all 0.5s;
`;
