import styled from "styled-components";
import { PRIMARY_COLORS } from "../../consts/colors";

export const ProfileContainer = styled.div`
   width: 100%;
   // padding-bottom: 20px;
   .section-css {
      padding: 0px;
   }
   &.mobile-css {
      margin-right: 0px !important;
      margin-left: 0px !important;
      padding-left: 0px;
      padding-right: 0px;
   }
   &.desktop-css {
      margin-top: 80px;
      margin-bottom: 20px;
   }

   .section-body {
      margin: 0px;
   }

   .content-css {
      flex-grow: 1;
   }

   .personal-card-css {
      .personal-content-css {
         padding: 0px 20px;
         .personal-section-css {
            padding: 2px;
         }
         .line-css {
            color: ${PRIMARY_COLORS.NEBULA};
            border-width: 1px;
            opacity: 1;
            margin: 0;
         }
      }
   }
   .donation-eligibility-table-css {
      border: none;
      margin: 5px;
      .table-div-css {
         font-size: 12px;
         overflow-y: auto;
         border-collapse: collapse;
         .table-header-css {
            background-color: ${PRIMARY_COLORS.CRYSTAL_BLUE};
            color: white;
         }
         .table-body-css {
            .bold-css {
               font-weight: bold;
            }
            tr {
               &.desktop-tr-css {
                  border-bottom: none;
               }
               &.mobile-tr-css {
                  .bold-css {
                     text-align: end;
                  }
               }
               td {
                  padding: 10px;
                  &.last-first-row-width {
                     width: 20px;
                     border-bottom: none;
                  }
                  &.border-bottom-css {
                     border-bottom: 1px solid ${PRIMARY_COLORS.NEBULA};
                     padding-left: 20px;
                  }
               }
            }
         }
      }
   }
`;

export const ProfileFooter = styled.div`
   z-index: 99;
   position: fixed;
   bottom: 0;
   background-color: white;
   width: 100%;
   padding-right: 50px;
`;
