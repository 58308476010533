import { IEnvironmentService } from "./IEnvironmentService";
import { EnvironmentService } from "./EnvironmentService";

let instance: IEnvironmentService | null = null;
export function getEnvironmentService(): IEnvironmentService {
   if (instance === null) {
      instance = new EnvironmentService();
   }
   return instance;
}
