export const PRIMARY_COLORS = {
   HAWKES_BLUE: "#CFE4FF",
   LIGHT_GREY: "#D5DAE0",
   SHUTTLE_GREY: "#63676C",
   IRON_COLOR: "#D0D6DC",
   DAVY_GREY: "#4C535D",
   CLOUDY_GREY: "#696969",
   BRIGHT_GREY: "#3A414A",
   QUILL_GREY: "#CED4DA",
   PLATINUM: "#DEE2E6",
   TOOLBOX: "#606BBC",
   RHINO: "#2d335e",
   PALE_SKY: "#707781",
   FIRE_ENGINE_RED: "#CD2129",
   BATTLESHIP_GREY: "#515C66",
   SOAP_STONE: "#FCFCFC",
   ASH_GREY: "#BABABA",
   MUTED_BLUE: "#326C9F",
   MERCURY: "#E2E2E2",
   DAWN_PINK: "#E9ECEF",
   BLUE_KOI: "#639BC6",
   GREY_GOOSE: "#CED1D7",
   TORY_BLUE: "#174EA6",
   LILY_WHITE: "#EDF5FF",
   LINK_WATER: "#DFE3E8",
   AQUA_HAZE: "#F2F3F5",
   WHITE: "#FFFFFF",
   ALABASTER: "#FAFAFA",
   LAVA_RED: "#E01A22",
   MYRTLE: "#1F4B19",
   CARBON_GREY: "#575064",
   DARK_GREEN_BLUE: "#2E614C",
   GAINSBORO: "#DADCE0",
   CRYSTAL_BLUE: "#6DB1FF",
   CADMIUM_RED: "#E01B23",
   GREEN_WHITE: "#E6E9ED",
   IRISH_GREEN: "#008A0E",
   NEBULA: "#CED4DB",
   DARK: "#212529",
   JORDY_BLUE: "#86B7FE",
   JASPER: "#DC3545",
   LAVENDER_MIST: "#E5E8EC",
   DOVE_GREY: "#6B6B6B",
   MERCURY_MIST: "#E3E7EB",
   BLUE_DRESS: "#1071e5",
   STORM_GREY: "#6F7681",
   BRANDEIS_BLUE: "#0D6EFD",
   STAR_DUST: "#A1A1A1",
   SCIENCE_BLUE: "#0074D9",
   COSMOS: "#FFD9D9",
   FLUSH_MAHOGANY: "#CC3737",
   COOL_GREY: "#979EA8",
   GREY_CLOUD: "#B3B9C0",
   CHARCOAL_GREY: "#403F3F",
   FRESH_GREEN: "#4CD662",
   BLUISH: "#3972C0",
};
