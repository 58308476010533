import React, { ReactElement, useContext } from "react";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../context/OpportunitySearchContext";
import { Banner } from "../../components/Banner/Banner";
import { OpportunitySearchForm } from "../../components/OpportunitySearchForm/OpportunitySearchForm";
import { NoRecordFound } from "../../../NoRecordFound";
import { Col, Row } from "react-bootstrap";
import { OpportunityList } from "../../components/OpportunityList/OpportunityList";
import { OpportunityMap } from "../../components/OpportunityMap/OpportunityMap";
import { useMediaQuery } from "react-responsive";

export interface ILandingLayoutProps { }

export function LandingLayout(props: ILandingLayoutProps): ReactElement {
   const { showNoDriveFound } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );
   const isMobile: boolean = useMediaQuery({ maxWidth: 720 });

   return (
      <>
         <Banner />
         <OpportunitySearchForm />
         {showNoDriveFound ? (
            <NoRecordFound />
         ) : (
            isMobile ? (<Row className={"d-flex flex-grow-1 flex-row"}>
               <Col xs={12} className="mobileMap">
                  <OpportunityMap />
               </Col>
               <Col className={"d-flex flex-column"} xs={12}>
                  <OpportunityList />
               </Col>

            </Row>
            ) : (
               <Row className={"d-flex flex-grow-1 flex-row"}>

                  <Col className={"d-flex flex-column"} xs={6}>
                     <OpportunityList />
                  </Col>
                  <Col xs={6}>
                     <OpportunityMap />
                  </Col>
               </Row>)

         )}
      </>
   );
}
