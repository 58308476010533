import { IEnvironmentConfiguration } from "../../services/environmentService/IEnvironmentConfiguration";
import { getEnvironmentService } from "../../services/environmentService/getEnvironmentService";
import { Base64Image } from "services/environmentService/Base64Image";
import { IEnvironmentProperties } from "../../services/environmentService/IEnvironmentProperties";

export interface IUseEnvironment {
   getConfig(): Promise<IEnvironmentConfiguration>;
   updateConfig(update: Partial<IEnvironmentConfiguration>): Promise<void>;
   getImage(imageName: string): Promise<Base64Image | undefined>;
   getProperties(): Promise<IEnvironmentProperties>;
}

export function useEnvironment(): IUseEnvironment {
   return {
      getConfig(): Promise<IEnvironmentConfiguration> {
         return getEnvironmentService().getConfiguration();
      },
      getProperties(): Promise<IEnvironmentProperties> {
         return getEnvironmentService().getProperties();
      },
      updateConfig(update: Partial<IEnvironmentConfiguration>) {
         return getEnvironmentService().updateConfiguration(update);
      },
      getImage(imageName: string) {
         return getEnvironmentService().getImage(imageName);
      },
   };
}
