import React, { ReactElement } from "react";
import { ConvertStringToPascalCase } from "../../../utils/convertStringToPascalCase";
import "./style.scss";
export enum ModalInputListGroupType {
   CheckList,
   RadioList,
}

export type ModalInputListProps =
   | {
        type: ModalInputListGroupType.CheckList;
        options: string[];
        selectedOptions: string[];
        onSelectedValuesChange: (selectedValue: string[]) => void;
        name: string;
        title: string;
     }
   | {
        type: ModalInputListGroupType.RadioList;
        options: string[];
        selectedOption: string;
        onSelectedValueChange: (selectedValue: string) => void;
        name: string;
        title: string;
     };

export function ModalInputListGroup(
   props: ModalInputListProps,
): ReactElement<ModalInputListProps> {
   const { options } = props;
   return (
      <div className={"filter-control-wrapper"}>
         <div className={"modal-input-list-group"}>
            <p key={`${props.name}Title`} className="title">
               {props.title}
            </p>
            <div
               key={`${props.name}Options`}
               className="modal-input-list-group-item-container"
            >
               {options.map((currentOption: string) => {
                  return (
                     <div
                        key={currentOption}
                        className=" d-flex gap-2 mb-2 align-items-center modal-input-list-group-item"
                     >
                        {props.type === ModalInputListGroupType.CheckList && (
                           <input
                              type="checkbox"
                              value={currentOption}
                              checked={props.selectedOptions.some(
                                 v => v === currentOption,
                              )}
                              onChange={e => {
                                 const isChecked = props.selectedOptions.some(
                                    v => v === currentOption,
                                 );
                                 if (isChecked) {
                                    props.onSelectedValuesChange(
                                       props.selectedOptions.filter(
                                          o => o !== currentOption,
                                       ),
                                    );
                                 } else {
                                    props.onSelectedValuesChange([
                                       ...props.selectedOptions,
                                       currentOption,
                                    ]);
                                 }
                              }}
                           />
                        )}
                        {props.type === ModalInputListGroupType.RadioList && (
                           <input
                              type="radio"
                              id={currentOption}
                              name={`${props.name}`}
                              checked={props.selectedOption === currentOption}
                              value={currentOption}
                              onChange={e =>
                                 props.onSelectedValueChange(e.target.value)
                              }
                           />
                        )}
                        <label htmlFor={currentOption}>
                           {ConvertStringToPascalCase(currentOption)}
                        </label>
                     </div>
                  );
               })}
            </div>
         </div>
      </div>
   );
}
