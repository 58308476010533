import styled from "styled-components";
import { Row } from "react-bootstrap";
import { PRIMARY_COLORS } from "../../../../../../consts/colors";
import { ReactComponent as ChevronDownImg } from "../../../../../../../assets/svgs/chevron-down.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../../../../../assets/svgs/arrow-left.svg";
export const HistoricalDropdownRow = styled(Row)`
   margin-right: 0px;
   margin-left: 0px;
   padding: 12px;
   .year-dropdown-section-css {
      .label-css {
         width: 350px;
         font-weight: bold;
      }
      display: flex;
      align-items: center;
      gap: 20px;
      padding-top: 20px;
   }
   .value-section-css {
      padding-left: 0px;
      padding-right: 0px;
      display: flex;
      .arrow-section {
         display: flex;
         justify-content: center;
         margin-top: 35px;
      }
      .no-arrow-section {
         width: 30px;
      }
      .selected-result {
         padding: 15px 0px;
         width: 80px;
         text-align: center;
      }
      .unselected-result {
         padding: 15px 0px;
         display: flex;
         .each-result-section {
            width: 80px;
            text-align: center;
            margin-top: 27px;
         }
      }
      font-size: 12px;
      font-weight: bold;
   }
`;
export const ChevronIcon = styled(ChevronDownImg)`
   width: 12px;
   height: 12px;
   margin-bottom: 15px;
   font-size: 12px;
   fill: ${PRIMARY_COLORS.CHARCOAL_GREY};
`;

export const ArrowRightImg = styled(ArrowLeftIcon)`
   fill: ${PRIMARY_COLORS.DOVE_GREY};
   cursor: pointer;
   transform: rotate(180deg);
`;

export const ArrowLeftImg = styled(ArrowLeftIcon)`
   fill: ${PRIMARY_COLORS.DOVE_GREY};
   cursor: pointer;
`;
