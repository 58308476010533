import { createContext } from "react";
import { IDonorEligibilityDate } from "../../../services/bexWISE/donorDataService/IDonorDataService";
import { IDonorDTO } from "../../../types/IDonorDTO";

export interface IUserContext {
   userDonorId: string | null;
   eligibilityDates: IDonorEligibilityDate[] | null;
   profileData: IDonorDTO | null;
}

export const defaultUserContext: IUserContext = {
   userDonorId: null,
   eligibilityDates: null,
   profileData: null,
};

export const UserContext = createContext<IUserContext>(defaultUserContext);
