import { format } from "date-fns";
import React from "react";
import { SeparatorContainer } from "./styled";

interface SeparatorProps {
   startTime: Date;
   endTime?: Date;
}

const Separator: React.FC<SeparatorProps> = ({ startTime, endTime }) => {
   return (
      <SeparatorContainer>
         <>{format(new Date(startTime), "PPPP")}</>
      </SeparatorContainer>
   );
};

export default Separator;
