import React, { ReactElement, useContext } from "react";
import { FiltersModal } from "../../../../../components/FiltersModal/FiltersModal";
import { MyDonationsFiltersModalBody } from "./MyDonationsFiltersModalBody";
import { LocationOption, SortBy } from "../../../../../consts";
import {
   DonationContext,
   IDonationContext,
} from "../../../context/DonationContext";

export interface IMyDonationFiltersModalProps {
   isMobile: boolean;
   showModal: boolean;
   setShowModal: (showModal: boolean) => void;
   showClose: boolean;
}

export function MyDonationFiltersModal({
   isMobile,
   showModal,
   setShowModal,
   showClose,
}: IMyDonationFiltersModalProps): ReactElement<IMyDonationFiltersModalProps> {
   const { filters, updateDonationFilter } =
      useContext<IDonationContext>(DonationContext);

   return (
      <FiltersModal
         fullScreen={isMobile}
         showClose={showClose}
         title={"Filters"}
         FilterRenderer={props => (
            <MyDonationsFiltersModalBody isMobile={isMobile} {...props} />
         )}
         initialFilterValue={filters}
         clearAllValues={{
            startDate: filters.startDate,
            endDate: filters.endDate,
            creditTo: [],
            sortBy: SortBy.CHRONOLOGICAL_ORDER,
            selectedLocationSource: LocationOption.ALL,
            selectedDonationOutcomes: [],
            selectedProcedures: [],
         }}
         onFilterApplied={updatedValues => {
            updateDonationFilter(updatedValues);
            setShowModal(false);
         }}
         show={showModal}
         onHide={() => {
            setShowModal(false);
         }}
      />
   );
}
