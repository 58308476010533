import React, { useContext } from "react";
import Image from "react-bootstrap/Image";
import { useMediaQuery } from "react-responsive";
import { EnvironmentContext } from "../../../../contexts/environment/environmentContext";
import { Col, Row } from "react-bootstrap";
export const Banner = () => {
   const { environmentConfig, bannerSrc } = useContext(EnvironmentContext);
   const isMobile = useMediaQuery({ maxWidth: 767 });
   return (
      <>
         <Row id={"banner-image-row"} className={"flex-grow-0"}>
            <Col xs={12} id={"banner-image-container"}>
               {bannerSrc && (
                  <Image src={bannerSrc} fluid alt="banner-img" width="100%" />
               )}
            </Col>
         </Row>
         <Row className="d-flex flex-row" id={"banner-text-row"}>
            <div
               id={"banner-text-container"}
               className={`text-center flex-col flex-fill`}
            >
               {environmentConfig?.opportunityLocatorText}
            </div>
         </Row>
      </>
   );
};
