import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { PRIMARY_COLORS } from "../../../consts/colors";
import { Modal } from "react-bootstrap";

export const ChangePasswordForm = styled(Form)`
   &.desktop-form-css {
      padding: 0 50px 30px 50px;
   }
   .mobile-padding-css {
      padding: 20px 20px 5px 20px;
   }
   .mobile-desktop-css {
      padding-top: 10px;
      padding-bottom: 5px;
   }
   .header-css {
      font-size: 13px;
   }
   .footer-section-css {
      .btn-css {
         font-size: 12px;
         background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
         border-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
         width: 100%;
      }
      &.mobile-footer-css {
         padding: 20px;
      }
      &.desktop-footer-css {
         margin-top: 60px;
      }
   }
`;

export const ChangePwdBody = styled(Modal.Body)`
   .change-pwd-header {
      h5 {
         font-size: 16px;
      }
      margin-bottom: 50px;
   }
`;
