import React from "react";
import { Col, Row, Stack } from "react-bootstrap";
import PersonalInformation from "../PersonalInformation";
import DonorCard from "../DonorCard";
import DonationEligibilityTable from "../DonationEligibilityTable";
import ChangePasswordSection from "../ChangePasswordSection";
import DemographicInformation from "../DemographicInformation";
import { ProfileMobileViewProps } from "../ProfileMobileView";

interface ProfileDesktopViewProps extends ProfileMobileViewProps {
   modalShow: boolean;
}
const ProfileDesktopView: React.FC<ProfileDesktopViewProps> = ({
   isMobile,
   profileData,
   eligibilityDates,
   modalShow,
   setModalShow,
   fullName,
   dateDisplay,
}) => {
   return (
      <Stack gap={3}>
         <Row>
            <Col sm={9}>
               <PersonalInformation
                  profileData={profileData}
                  fullName={fullName}
                  dateDisplay={dateDisplay}
               />
            </Col>
            <Col sm={3}>
               <DonorCard profileData={profileData} />
            </Col>
         </Row>
         <Row>
            <Col sm={7}>
               <DonationEligibilityTable
                  eligibilityDates={eligibilityDates}
                  isMobile={isMobile}
               />
            </Col>
            <Col sm={5}>
               <ChangePasswordSection
                  modalShow={modalShow}
                  setModalShow={setModalShow}
               />
            </Col>
         </Row>
         <DemographicInformation
            isMobile={isMobile}
            profileData={profileData}
         />
      </Stack>
   );
};

export default ProfileDesktopView;
