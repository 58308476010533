import styled from "styled-components";

export const Div = styled.div`
   width: 100%;
   display: flex;
   gap: 20px;

   .hr-years {
      position: absolute;
      top: -20px;
      right: -12px;
      width: fit-content;
   }
`;
