import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthStatus } from "../../../services/cognitoService/AuthStatus";
import { ICognitoService } from "../../../services/cognitoService/ICognitoService";
import {
   CognitoUserAttribute,
   CognitoUserSession,
   ISignUpResult,
} from "amazon-cognito-identity-js";
import { getCognitoService } from "../../../services/cognitoService/getCognitoService";
import { ICognitoSignInResponse } from "../../../services/cognitoService/ICognitoSignInResponse";
import { IDonorValidationRequest } from "../../../services/bexWISE/donorDataService/IDonorDataService";
import { getDonorDataServiceInstance } from "../../../services/bexWISE/donorDataService/getDonorDataServiceInstance";

export interface ISessionInfo {
   username?: string;
   email?: string;
   sub?: string;
   accessToken?: string;
   refreshToken?: string;
}

export interface IAuthContext {
   sessionInfo?: ISessionInfo;
   userDonorId?: string;
   attrInfo?: CognitoUserAttribute[];
   authStatus: AuthStatus;
   signInWithEmail: (username: string, password: string) => Promise<void>;
   signUpWithEmail: (
      email: string,
      password: string,
      donorId: string,
      firstName: string,
      lastName: string,
      dateOfBirth: string,
   ) => Promise<ISignUpResult>;
   signOut: () => void;
   verifyCode: (username: string, code: string) => Promise<void>;
   getSession: (cognitoUsername: string) => Promise<CognitoUserSession>;
   sendCode: (username: string) => Promise<void>;
   forgotPassword: (username: string) => Promise<void>;
   confirmPassword: (
      username: string,
      code: string,
      password: string,
   ) => Promise<void>;
   resendCode: (username: string) => Promise<void>;
   changePassword: (
      oldPassword: string,
      newPassword: string,
   ) => Promise<string>;
   getAttributes: () => Promise<CognitoUserAttribute[] | undefined>;
   setAttribute: (attr: CognitoUserAttribute) => Promise<string | undefined>;
}

const defaultState: IAuthContext = {
   signInWithEmail(username: string, password: string): Promise<void> {
      throw new Error("Not Instantiated");
   },
   signUpWithEmail(
      email: string,
      password: string,
      donorId: string,
      firstName: string,
      lastName: string,
   ): Promise<ISignUpResult> {
      throw new Error("Not Instantiated");
   },
   changePassword(oldPassword: string, newPassword: string): Promise<string> {
      throw new Error("Not Instantiated");
   },
   confirmPassword(
      username: string,
      code: string,
      password: string,
   ): Promise<void> {
      throw new Error("Not Instantiated");
   },
   forgotPassword(username: string): Promise<void> {
      throw new Error("Not Instantiated");
   },
   getAttributes(): Promise<CognitoUserAttribute[] | undefined> {
      throw new Error("Not Instantiated");
   },
   getSession(cognitoUsername: string): Promise<CognitoUserSession> {
      throw new Error("Not Instantiated");
   },
   resendCode(username: string): Promise<void> {
      throw new Error("Not Instantiated");
   },
   sendCode(username: string): Promise<void> {
      throw new Error("Not Instantiated");
   },
   setAttribute(attr: CognitoUserAttribute): Promise<string | undefined> {
      throw new Error("Not Instantiated");
   },
   verifyCode(username: string, code: string): Promise<void> {
      throw new Error("Not Instantiated");
   },
   sessionInfo: {},
   authStatus: AuthStatus.Loading,
   userDonorId: "",
   signOut: () => {
      throw new Error("Not Instantiated");
   },
};

type Props = {
   children?: React.ReactNode;
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }: Props) => {
   const { authStatus }: IAuthContext = useContext(AuthContext);

   return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

const AuthProvider = ({ children }: Props) => {
   const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
   const [userDonorId, setUserDonorId] = useState<string>("");
   const [sessionInfo, setSessionInfo] = useState<ISessionInfo | {}>({});
   const [attrInfo, setAttrInfo] = useState<[]>([]);
   const authServiceRef = useRef<ICognitoService<any>>(getCognitoService());
   const authService: ICognitoService<any> = authServiceRef.current;
   const storedCognitoUsername: string | null =
      window.localStorage.getItem("cognitoUsername");
   const settingSession = async (
      session: CognitoUserSession,
   ): Promise<void> => {
      const jwtToken: string = session.getAccessToken().getJwtToken();
      const refreshToken: string = session.getRefreshToken().getToken();
      const cognitoUsername: string =
         session.getAccessToken().payload?.username;
      setSessionInfo({
         accessToken: jwtToken,
         refreshToken: session.getRefreshToken().getToken(),
      });
      window.localStorage.setItem("accessToken", `${jwtToken}`);
      window.localStorage.setItem("refreshToken", `${refreshToken}`);
      window.localStorage.setItem("cognitoUsername", cognitoUsername);
      const response: CognitoUserAttribute[] | undefined =
         await getAttributes();
      if (response && Array.isArray(response)) {
         const donorId: string | undefined = response.find(
            eachAttribute => eachAttribute.Name === "custom:donorId",
         )?.Value;
         if (donorId) {
            setUserDonorId(donorId);
         }
      }
      setAuthStatus(AuthStatus.SignedIn);
   };

   async function getSessionInfo(cognitoUsername: string): Promise<void> {
      try {
         const session: CognitoUserSession = await authService.getSession(
            cognitoUsername,
         );
         await settingSession(session);
      } catch (err) {
         setAuthStatus(AuthStatus.SignedOut);
      }
   }

   useEffect(() => {
      if (storedCognitoUsername) {
         getSessionInfo(storedCognitoUsername);
      } else {
         setAuthStatus(AuthStatus.SignedOut);
      }
   }, []);

   if (authStatus === AuthStatus.Loading) {
      return null;
   }

   async function signInWithEmail(
      username: string,
      password: string,
   ): Promise<void> {
      try {
         const response: ICognitoSignInResponse =
            await authService.signInWithEmail(username, password);
         if (response?.session) {
            await settingSession(response.session);
         }
      } catch (err) {
         setAuthStatus(AuthStatus.SignedOut);
         throw err;
      }
   }

   async function signUpWithEmail(
      email: string,
      password: string,
      donorId: string,
      firstName: string,
      lastName: string,
      dateOfBirth: string,
   ): Promise<ISignUpResult> {
      const validationRequest: IDonorValidationRequest = {
         firstName: firstName,
         lastName: lastName,
         emailAddress: email,
         donorId: parseInt(donorId),
         dateOfBirth: dateOfBirth,
      };

      // Validate
      try {
         const validated: boolean =
            await getDonorDataServiceInstance().validateDonor(
               validationRequest,
            );
         if (validated) {
            // Create user
            const response: ISignUpResult = await authService.signUp({
               usernameField: "email",
               password,
               email,
               donorId,
            });
            return response;
         } else {
            throw new Error("Donor Data is not valid");
         }
      } catch (err) {
         throw err;
      }
   }

   function signOut(): void {
      authService.signOut();
      setUserDonorId("");
      window.localStorage.removeItem("accessToken");
      window.localStorage.removeItem("refreshToken");
      window.localStorage.removeItem("cognitoUsername");
      setAuthStatus(AuthStatus.SignedOut);
   }

   async function verifyCode(username: string, code: string): Promise<void> {
      try {
         await authService.verifyCode(username, code);
      } catch (err) {
         throw err;
      }
   }

   async function getSession(
      cognitoUsername: string,
   ): Promise<CognitoUserSession> {
      try {
         const session: CognitoUserSession = await authService.getSession(
            cognitoUsername,
         );
         return session;
      } catch (err) {
         throw err;
      }
   }

   async function getAttributes(): Promise<CognitoUserAttribute[] | undefined> {
      try {
         const attr: CognitoUserAttribute[] | undefined =
            await authService.getAttributes();
         return attr;
      } catch (err) {
         throw err;
      }
   }

   async function setAttribute(
      attr: CognitoUserAttribute,
   ): Promise<string | undefined> {
      try {
         const res: string | undefined = await authService.setAttribute(attr);
         return res;
      } catch (err) {
         throw err;
      }
   }

   async function sendCode(username: string): Promise<void> {
      try {
         await authService.sendCode(username);
      } catch (err) {
         throw err;
      }
   }

   async function forgotPassword(username: string): Promise<void> {
      try {
         await authService.forgotPassword(username);
      } catch (err) {
         throw err;
      }
   }

   async function resendCode(username: string): Promise<void> {
      try {
         await authService.resendCode(username);
      } catch (err) {
         throw err;
      }
   }

   async function confirmPassword(
      username: string,
      code: string,
      password: string,
   ): Promise<void> {
      try {
         await authService.confirmPassword(username, code, password);
      } catch (err) {
         throw err;
      }
   }

   async function changePassword(
      oldPassword: string,
      newPassword: string,
   ): Promise<"SUCCESS"> {
      try {
         const response: "SUCCESS" = await authService.changePassword(
            oldPassword,
            newPassword,
         );
         return response;
      } catch (err) {
         throw err;
      }
   }

   const state: IAuthContext = {
      authStatus,
      userDonorId,
      sessionInfo,
      attrInfo,
      signUpWithEmail,
      signInWithEmail,
      signOut,
      verifyCode,
      getSession,
      sendCode,
      forgotPassword,
      resendCode,
      confirmPassword,
      changePassword,
      getAttributes,
      setAttribute,
   };

   return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
