import React from "react";
import { InputContainer, ReactCalendar } from "./styled";
import { addDays, format } from "date-fns";
import { DateCalendarType } from "./DateCalendarType";

interface RangeDatePickerProps {
   date: DateCalendarType;
   handleChange: (arg: DateCalendarType | Date) => void;
   isCustomDateSelected: boolean;
}

const RangeDatePicker: React.FC<RangeDatePickerProps> = ({
   date,
   handleChange,
   isCustomDateSelected,
}) => {
   const startDate = date && Array.isArray(date) ? date[0] : undefined;
   const endDate = date && Array.isArray(date) ? date[1] : undefined;

   const formatDate = (value: number | Date): string | number => {
      if (!value) return value;
      return format(value, "MM/dd/yyyy");
   };

   const tileDisabled = ({ date }) => {
      const current = new Date();
      current.setMonth(current.getMonth() + 12);
      const firstDateOfMonth = new Date(format(current, "yyyy-MM-01"));
      return date < addDays(new Date(), -1) || date > firstDateOfMonth;
   };

   return (
      <>
         <InputContainer>
            <label htmlFor="from">From</label>
            <input
               readOnly
               id="from"
               placeholder="From"
               value={formatDate(startDate!)}
            />

            <label htmlFor="to">To</label>
            <input
               readOnly
               id="to"
               placeholder="To"
               value={formatDate(endDate!)}
            />
         </InputContainer>
         <ReactCalendar
            formatShortWeekday={(locale, date) =>
               format(new Date(date), "EEEEE")
            }
            tileDisabled={tileDisabled}
            view="month"
            calendarType="US"
            value={date}
            onChange={handleChange}
            selectRange={isCustomDateSelected}
         />
      </>
   );
};

export default RangeDatePicker;
