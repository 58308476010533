import { CognitoUserSession } from "amazon-cognito-identity-js";

export enum CognitoSignInResponseType {
   Success,
   Error,
   NewPasswordRequired,
}
export interface ICognitoSignInResponse {
   status: CognitoSignInResponseType;
   error?: Error;
   // Return session if successful
   session?: CognitoUserSession;
}
