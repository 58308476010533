import styled from "styled-components";
import { PRIMARY_COLORS } from "../../../../../../consts/colors";

export const ZoomButton = styled("button")`
   position: absolute;
   right: 14px;
   top: 14px;
   padding: 5px;
   background: white;
   border-radius: 3px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid ${PRIMARY_COLORS.SHUTTLE_GREY};
`;
