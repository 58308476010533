import { ICognitoService } from "./ICognitoService";
import {
   AuthenticationDetails,
   CognitoUser,
   CognitoUserAttribute,
   CognitoUserPool,
   CognitoUserSession,
   ICognitoUserPoolData,
   ISignUpResult,
} from "amazon-cognito-identity-js";
import {
   CognitoSignInResponseType,
   ICognitoSignInResponse,
} from "./ICognitoSignInResponse";
import { ICognitoSignUpParams } from "./ICognitoSignUpParams";
import { IEnvironmentService } from "../environmentService/IEnvironmentService";
import { IEnvironmentProperties } from "../environmentService/IEnvironmentProperties";

export class CognitoService<N extends ICognitoSignUpParams>
   implements ICognitoService<N>
{
   private userPool: CognitoUserPool | null = null;
   private currentUser: CognitoUser | null = null;
   constructor(private environmentService: IEnvironmentService) {}

   public getUserPool(): Promise<CognitoUserPool> {
      if (this.userPool === null) {
         // User pool not yet initialized.
         // Fetch initialization props
         return this.environmentService
            .getProperties()
            .then((environmentProperties: IEnvironmentProperties) => {
               const cognitoUserPoolData: ICognitoUserPoolData = {
                  UserPoolId: environmentProperties.donorPoolUserPoolId,
                  ClientId: environmentProperties.donorPoolClientId,
               };
               this.userPool = new CognitoUserPool(cognitoUserPoolData);
               return this.userPool;
            });
      } else {
         return Promise.resolve(this.userPool);
      }
   }

   public signOut(): void {
      if (this.currentUser !== null) {
         this.currentUser.signOut();
      }
   }
   public changePassword(
      oldPassword: string,
      newPassword: string,
   ): Promise<"SUCCESS"> {
      return new Promise((resolve, reject) => {
         if (this.currentUser === null) {
            throw new Error("User must be logged in to change password");
         } else {
            this.currentUser.changePassword(
               oldPassword,
               newPassword,
               function (err: any, res: any) {
                  if (err) {
                     reject(err);
                  } else {
                     resolve("SUCCESS");
                  }
               },
            );
         }
      });
   }

   public forgotPassword(username: string): Promise<any> {
      return this.getCognitoUser(username).then((cognitoUser: CognitoUser) => {
         return new Promise((resolve, reject) => {
            if (!cognitoUser) {
               reject(`could not find ${username}`);
               return;
            }
            cognitoUser.forgotPassword({
               onSuccess: function (res) {
                  resolve(res);
               },
               onFailure: function (err) {
                  reject(err);
               },
            });
         }).catch(err => {
            throw err;
         });
      });
   }

   public confirmPassword(
      username: string,
      code: string,
      password: string,
   ): Promise<any> {
      return this.getCognitoUser(username).then((cognitoUser: CognitoUser) => {
         return new Promise((resolve, reject) => {
            if (!cognitoUser) {
               reject(`could not find ${username}`);
               return;
            }
            cognitoUser.confirmPassword(code, password, {
               onSuccess: function (res) {
                  resolve(res);
               },
               onFailure: function (err) {
                  reject(err);
               },
            });
         }).catch(err => {
            throw err;
         });
      });
   }
   public getAttributes(): Promise<CognitoUserAttribute[] | undefined> {
      return new Promise<CognitoUserAttribute[] | undefined>(
         (resolve, reject) => {
            if (this.currentUser === null) {
               reject("User is not logged in");
            } else {
               this.currentUser.getUserAttributes(
                  (
                     err: Error | undefined,
                     attributes: CognitoUserAttribute[] | undefined,
                  ) => {
                     if (err) {
                        reject(err);
                     } else {
                        resolve(attributes);
                     }
                  },
               );
            }
         },
      ).catch(err => {
         throw err;
      });
   }

   public getSession(cognitoUsername: string): Promise<CognitoUserSession> {
      return this.getCognitoUser(cognitoUsername).then(
         (cognitoUser: CognitoUser) => {
            return new Promise<CognitoUserSession>((resolve, reject) => {
               cognitoUser.getSession(
                  (err: any, session: CognitoUserSession) => {
                     if (err) {
                        reject(err);
                     } else {
                        this.currentUser = cognitoUser;
                        resolve(session);
                     }
                  },
               );
            });
         },
      );
   }

   public sendCode(username: string): Promise<any> {
      return this.getCognitoUser(username).then((cognitoUser: CognitoUser) => {
         return new Promise((resolve, reject) => {
            if (!cognitoUser) {
               reject(`could not find ${username}`);
               return;
            }

            cognitoUser.forgotPassword({
               onSuccess: function (res) {
                  resolve(res);
               },
               onFailure: function (err) {
                  reject(err);
               },
            });
         }).catch(err => {
            throw err;
         });
      });
   }

   public resendCode(username: string): Promise<any> {
      return this.getCognitoUser(username).then((cognitoUser: CognitoUser) => {
         return new Promise((resolve, reject) => {
            if (!cognitoUser) {
               reject(`could not find ${username}`);
               return;
            }

            cognitoUser.resendConfirmationCode((err: any, res: any) => {
               if (err) {
                  reject(err);
               } else {
                  resolve(res);
               }
            });
         }).catch(err => {
            throw err;
         });
      });
   }

   public setAttribute(
      attr: CognitoUserAttribute,
   ): Promise<string | undefined> {
      return new Promise<string | undefined>((resolve, reject) => {
         if (!this.currentUser) {
            throw new Error("User not logged in");
         } else {
            const attributeList: CognitoUserAttribute[] = [];
            attributeList.push(attr);
            this.currentUser.updateAttributes(
               attributeList,
               (err: Error | undefined, res: string | undefined) => {
                  if (err) {
                     reject(err);
                  } else {
                     resolve(res);
                  }
               },
            );
         }
      }).catch(err => {
         throw err;
      });
   }

   public signInWithEmail(
      username: string,
      password: string,
   ): Promise<ICognitoSignInResponse> {
      return this.getCognitoUser(username).then((user: CognitoUser) => {
         return new Promise<ICognitoSignInResponse>((resolve, reject) => {
            const authenticationData = {
               Username: username,
               Password: password,
            };
            const authenticationDetails: AuthenticationDetails =
               new AuthenticationDetails(authenticationData);
            user.authenticateUser(authenticationDetails, {
               onSuccess: (session: CognitoUserSession) => {
                  this.currentUser = user;
                  resolve({
                     status: CognitoSignInResponseType.Success,
                     session,
                  });
               },
               onFailure: (error: Error) =>
                  reject({ status: CognitoSignInResponseType.Error, error }),
               newPasswordRequired: () =>
                  reject({
                     status: CognitoSignInResponseType.NewPasswordRequired,
                  }),
            });
         }).catch(err => {
            throw err;
         });
      });
   }

   public signUp(signUpParams: N): Promise<ISignUpResult> {
      return this.getUserPool().then((userPool: CognitoUserPool) => {
         return new Promise<ISignUpResult>((resolve, reject) => {
            const username: string = signUpParams[signUpParams.usernameField];
            const { password } = signUpParams;

            // Build attribute list dynamically
            const attributeList: CognitoUserAttribute[] = [];
            Object.keys(signUpParams).forEach((key: string) => {
               if (
                  key !== "password" &&
                  key !== "usernameField" &&
                  key !== "email"
               ) {
                  let value: string = signUpParams[key];
                  if (key === "donorId") {
                     value = value.replaceAll("-", "");
                  }
                  const newAttribute: CognitoUserAttribute =
                     new CognitoUserAttribute({
                        Name: `custom:${key}`,
                        Value: `${value}`,
                     });
                  attributeList.push(newAttribute);
               }
            });

            userPool.signUp(
               username,
               password,
               attributeList,
               [],
               (err: Error | undefined, res: ISignUpResult | undefined) => {
                  if (err) {
                     reject(err);
                  } else {
                     if (res !== undefined) {
                        resolve(res);
                     } else {
                        reject("Error signing up");
                     }
                  }
               },
            );
         }).catch(err => {
            throw err;
         });
      });
   }

   public verifyCode(username: string, code: string): Promise<any> {
      return this.getCognitoUser(username).then((cognitoUser: CognitoUser) => {
         return new Promise((resolve, reject) => {
            cognitoUser.confirmRegistration(code, true, (err, result) => {
               if (err) {
                  reject(err);
               } else {
                  resolve(result);
               }
            });
         }).catch(err => {
            throw err;
         });
      });
   }

   public getCognitoUser(username: string): Promise<CognitoUser> {
      return this.getUserPool().then((userPool: CognitoUserPool) => {
         const userData = {
            Username: username,
            Pool: userPool,
         };
         return new CognitoUser(userData);
      });
   }

   public async getAuthorizationCode(): Promise<string | undefined> {
      return new Promise((resolve, reject) => {
         if (this.currentUser) {
            this.currentUser?.getSession((error, session) => {
               if (error) {
                  reject(error);
               } else {
                  resolve(session?.getAccessToken().getJwtToken());
               }
            });
         } else {
            resolve(undefined);
         }
      });
   }
}
