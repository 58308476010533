import React from "react";
import { ReactComponent as InformationIcon } from "assets/svgs/info.svg";
import { PRIMARY_COLORS } from "../../consts/colors";

export const NoRecordFound = (): JSX.Element => {
   return (
      <div className="d-flex justify-content-center align-items-center">
         <InformationIcon
            fill={PRIMARY_COLORS.SCIENCE_BLUE}
            style={{ width: "28px", height: "28px" }}
         />
         <div className="m-3" style={{ fontSize: "14px" }}>
            {/* Changed "No Records Found With Applied Filters" to "No Drives Found for the Applied Filters" (AEP-60)*/}
            No Drives Found for the Applied Filters
         </div>
      </div>
   );
};
