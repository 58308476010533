import { getBexWISEResolutionServiceInstance } from "../bexWISEResolutionService/getBexWISEResolutionService";
import { ICenterDataService } from "./ICenterDataService";
import { CenterDataService } from "./CenterDataService";
import { getCognitoService } from "../../cognitoService/getCognitoService";
import { ICognitoSignUpParams } from "../../cognitoService/ICognitoSignUpParams";

let instance: ICenterDataService | null = null;

export function getCenterDataServiceInstance(): ICenterDataService {
   if (instance === null) {
      instance = new CenterDataService(
         getBexWISEResolutionServiceInstance(),
         getCognitoService<ICognitoSignUpParams>(),
      );
   }
   return instance;
}
