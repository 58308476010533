export type OpportunitySearchAccountQuery = {
   accountName: string;
   // Removed the searchRadius filter to prevent going to the server in the filter change (SCREDP-86)
   // searchRadius: string;
};
export type OpportunitySearchLocationQuery = {
   origin: string;
   // Removed the searchRadius filter to prevent going to the server in the filter change (SCREDP-86)
   // searchRadius: string;
};
export type OpportunitySearchQuery =
   | OpportunitySearchLocationQuery
   | OpportunitySearchAccountQuery;

export function isOpportunitySearchLocationQuery(
   query: OpportunitySearchQuery | null,
): query is OpportunitySearchLocationQuery {
   return (
      query !== null &&
      (query as OpportunitySearchLocationQuery).origin !== undefined
   );
}

export function isOpportunitySearchAccountQuery(
   query: OpportunitySearchQuery | null,
): query is OpportunitySearchAccountQuery {
   return (
      query !== null &&
      (query as OpportunitySearchAccountQuery).accountName !== undefined
   );
}
export function areOpportunitySearchQueriesEqual(
   qa: OpportunitySearchQuery | null,
   qb: OpportunitySearchQuery | null,
): boolean {
   if (qa === null && qb === null) {
      return true;
   } else if (
      isOpportunitySearchAccountQuery(qa) &&
      isOpportunitySearchAccountQuery(qb)
   ) {
      return (
         qa.accountName === qb.accountName 
         // Removed the searchRadius filter to prevent going to the server in the filter change (SCREDP-86)
         //&& qa.searchRadius === qb.searchRadius
      );
   } else if (
      isOpportunitySearchLocationQuery(qa) &&
      isOpportunitySearchLocationQuery(qb)
   ) {
      return qa.origin === qb.origin 
      // Removed the searchRadius filter to prevent going to the server in the filter change (SCREDP-86)
      //&& qa.searchRadius === qb.searchRadius;
   } else {
      return false;
   }
}
