export enum ROUTE_PATHS {
   LANDING_PATH = "/",
   APPOINTMENT_LIST = "/appointmentList",
   MY_APPOINTMENTS = "/my-appointments",
   MY_Donations = "/my-donations",
   FUTURE_APPOINTMENTS_MAP = "/my-appointments/map",
   PROFILE = "/profile",
   PHYSICAL_EXAMS = "/physical-exams",
   PREFERENCES = "/preferences",
}
