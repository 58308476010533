import { format } from "date-fns";
import React from "react";
import { IAppointment } from "types/IAppointment";
import { isCurrentDateEqualToPreviousDate } from "utils/isCurrentDateEqualPreviousDate";
import FutureAppointmentMobileSlot from "./FutureAppointmentMobileSlot";

interface FutureAppointmentMobileProps {
   appointment: IAppointment;
   appointments: IAppointment[];
   index: number;
   setTriggerAppointmentPageCall: (_: boolean) => void;
}

const FutureAppointmentMobile: React.FC<FutureAppointmentMobileProps> =
   React.memo(
      ({ appointment, appointments, index, setTriggerAppointmentPageCall }) => {
         const formatYear = (appointmentTime: string | Date): JSX.Element => {
            return (
               <p className="my-2 fw-bold ">
                  {format(new Date(appointmentTime), "yyyy")}
               </p>
            );
         };
         return (
            <>
               {!isCurrentDateEqualToPreviousDate(
                  appointment?.appointmentDateTime,
                  index,
                  appointments,
               ) && formatYear(appointment?.appointmentDateTime)}

               <FutureAppointmentMobileSlot
                  appointment={appointment}
                  setTriggerAppointmentPageCall={setTriggerAppointmentPageCall}
               />
            </>
         );
      },
   );

export default FutureAppointmentMobile;
