import React from "react";
import { Header } from "./styled";

interface CommonHeaderProps {
   title: string;
   icon?: React.ReactElement;
}

const CommonHeader: React.FC<CommonHeaderProps> = ({ title, icon }) => {
   return (
      <Header>
         {icon}
         <h4>{title}</h4>
      </Header>
   );
};

export default CommonHeader;
