import styled from "styled-components";
import { ReactComponent as ForwardArrowIcon } from "assets/svgs/forward-arrow.svg";

export const ArrowRightImg = styled(ForwardArrowIcon)`
   width: 15px;
   height: 15px;
   transition: transform 0.2s;
`;

export const ArrowLeftImg = styled(ForwardArrowIcon)`
   width: 15px;
   height: 15px;
   transform: rotate(180deg);
`;
