import { PRIMARY_COLORS } from "app/consts/colors";
import styled from "styled-components";

export const StyledDonationHistory = styled.div`
   width: 100%;
   display: flex;
   border: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
   border-radius: 5px;
   padding: 40px 20px;

   h5 {
      font-size: 20px;
      margin-left: 30px;
      margin-top: 10px;
   }
`;

export const StyledBookApponiment = styled.div<{ isMobile: boolean }>`
   display: ${props => (props.isMobile ? undefined : "flex")};
   align-items: ${props => (props.isMobile ? undefined : "center")};
   min-height: ${props => (props.isMobile ? "30vh" : "34vh")};
   border: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
   border-radius: 5px;
   position: relative;
   height: 100%;
   padding: ${props => (props.isMobile ? "20px 50px" : "20px 30px")};

   .make-appointment-button {
      width: ${props => (props.isMobile ? "100%" : undefined)};
      padding: 5px 40px;
      font-size: 12px;
      background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
      border-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
      box-shadow: 0 1px 3px 0 rgba(60,64,67,.3), 0 4px 8px 3px rgba(60,64,67,.15);
   }

   img {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;
      width: ${props => (props.isMobile ? "60%" : undefined)};
   }
`;
