import React from "react";
import { IModalDisplayParams } from "../../../../Form/Inputs/Instances/MoreFiltersModalControl/IModalDisplayParams";
import { OpportunitySearchMoreFiltersMobileModal } from "../../MoreFiltersModal";
const OpportunitySearchBarMoreFiltersModal = (props: IModalDisplayParams) => {
   return (
      <OpportunitySearchMoreFiltersMobileModal
         isMoreFiltersDisplayed={props.isDisplayed}
         setIsMoreFiltersDisplayed={props.setIsDisplayed}
      />
   );
};

export default OpportunitySearchBarMoreFiltersModal;
