import styled from "styled-components";
import { PRIMARY_COLORS } from "../../consts/colors";

export const Header = styled("header")`
   width: 100%;
   display: flex;
   gap: 20px;
   align-items: center;
   padding: 10px 30px;
   border-radius: 4px;
   background: radial-gradient(
      circle at center,
      ${PRIMARY_COLORS.TOOLBOX} 4%,
      ${PRIMARY_COLORS.RHINO} 30%
   );

   h4 {
      color: white;
      font-size: 12px;
      font-weight: bold;
   }
`;
