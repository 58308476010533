import styled from "styled-components";
import { ReactComponent as Search } from "assets/svgs/search.svg";
import { ReactComponent as Cross } from "assets/svgs/cross.svg";
import { ReactComponent as Aim } from "assets/svgs/aim.svg";
import Form from "react-bootstrap/Form";

export const CrossIcon = styled(Cross)`
   top: 12px;
   left: 5px;
   position: absolute;
   width: 25px;
   height: 25px;
   cursor: pointer;
`;

export const SearchIcon = styled(Search)`
   top: 15px;
   left: 8px;
   position: absolute;
`;

export const AimIcon = styled(Aim)`
   top: 14px;
   right: 15px;
   position: absolute;
   width: 20px;
   height: 20px;
   cursor: pointer;
`;

export const FormInput = styled(Form.Control)`
   background: none;
   padding: 0.375rem 2.5rem 0.55rem 1.95rem;
   height: 50px;
`;
