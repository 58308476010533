import styled from "styled-components";
import { Container } from "react-bootstrap";
import { PRIMARY_COLORS } from "../../../../consts/colors";

export const NoDonationsContainer = styled(Container)`
   margin-top: 80px;

   .profile-section {
      .profile-content-section {
         display: flex;
         flex-direction: column;
         justify-content: space-evenly;
         .middle-section {
            display: flex;
            flex-direction: column;
            gap: 10px;
            font-weight: bold;
            .name-section {
               font-size: 14px;
            }
            .details-section {
               display: flex;
               font-size: 12px;
               gap: 20px;
            }
         }
         .bottom-section-css {
            font-size: 12px;
            font-weight: bold;
         }
      }
      .profile--no-physical-exam-img-css {
         height: 200px;
      }
   }
   .make-an-appointment-section {
      padding-top: 35px;
      .btn-section {
         display: flex;
         align-items: center;
         justify-content: center;
         .btn-css {
            font-size: 12px !important;
            padding: 6px 20px;
            background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED} !important;
            border-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED} !important;
            border-radius: 10px;
            margin-bottom: 45px;
         }
      }
   }
   &.mobile-css {
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 14vh;
      .middle-section {
         padding: 20px 20px 0px 20px;
      }
      .bottom-section-css {
         padding: 5px 5px 20px 20px;
         border-bottom: 1px solid ${PRIMARY_COLORS.NEBULA};
      }
      .make-an-appointment-section {
         border-top: 1px solid ${PRIMARY_COLORS.NEBULA};
         border-bottom: 1px solid ${PRIMARY_COLORS.NEBULA};
         display: flex;
         flex-direction: column;
         margin-top: 20px;
         .make-an-apt-img-css {
            height: 140px;
            width: 200px;
            margin-left: auto;
         }
         .btn-css {
            width: 80%;
         }
      }
   }
   &.desktop-css {
      margin-bottom: 52vh;
      .profile-content-section {
         padding-left: 40px;
      }
      .make-an-appointment-section {
         background-color: white !important;
         box-shadow: 10px 3px 15px 0px rgb(0 0 0 / 28%);
         display: grid;
         grid-template-columns: 50% 50%;
         align-items: center;
         .make-an-apt-img-css {
            height: 200px;
         }
      }
   }
`;
