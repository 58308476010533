import styled from "styled-components";

export const LabelContentDiv = styled.div`
   .section-css {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-left: 30px;
      .content-css {
         font-weight: bold;
      }
   }
`;
