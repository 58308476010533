import { Spinner } from "react-bootstrap";
import styled from "styled-components";

export const LoaderWrapper = styled.div`
   position: fixed;
   left: 0px;
   top: 0px;
   width: 100%;
   height: 100%;
   z-index: 9999;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   gap: 30px;
   background: rgba(0, 0, 0, 0.6);
`;

export const StyledSpinner = styled(Spinner)`
   color: brown;
   font-size: 30px;
   height: 50px !important;
   width: 50px !important;
`;
