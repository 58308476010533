import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as CalendarCheck } from "../../../../assets/svgs/calender-check.svg";
import { ReactComponent as CalendarX } from "../../../../assets/svgs/calendar-x.svg";
import { ReactComponent as CrossIconLabel } from "../../../../assets/svgs/cross.svg";
import { ReactComponent as CalendarIcon } from "../../../../assets/svgs/calendar-days-solid.svg";
import { ReactComponent as CheckMarkIconLabel } from "../../../../assets/svgs/check-lg.svg";
import { IconsCss } from "../styled";
import { PRIMARY_COLORS } from "../../../consts/colors";

export const AptConfirmModalBody = styled(Modal.Body)`
   .content-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      &.content-desktop-section {
         border: 1px solid gray;
         border-radius: 5px;
         padding: 45px;
         gap: 30px;
      }
      &.content-mobile-section {
         justify-content: space-evenly;
         height: 75%;
      }
      margin: 0 20px;
      .header-section {
         font-weight: bold;
         font-size: 14px;
         text-align: center;
         color: ${PRIMARY_COLORS.DAVY_GREY};
      }
      .mid-section {
         font-size: 14px;
         font-weight: bold;
         display: flex;
         gap: 15px;
         color: ${PRIMARY_COLORS.STORM_GREY};
         .bar-section {
            color: ${PRIMARY_COLORS.NEBULA};
         }
         .name-section {
            width: 120px;
            text-align: center;
         }
      }
      .final-section {
         color: ${PRIMARY_COLORS.STORM_GREY};
         font-size: 11px;
         text-align: center;
         .final-second-section {
            display: flex;
            gap: 15px;
         }
      }
   }

   .footer-section-css {
      &.apmt-confirmed-css {
         display: grid;
         grid-template-columns: 45% 45%;
         grid-gap: 10%;
      }
      &.apmt-not-confirmed-css {
         display: flex;
         justify-content: flex-end;
         .mobile-btn-css {
            width: 100%;
         }
         .desktop-btn-css {
            width: 45%;
         }
      }
      .btn-css {
         font-size: 12px;
         background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
         border-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
         padding: 9px;
      }
      margin: 0 20px;
   }
`;

export const CalendarCheckImg = styled(CalendarCheck)`
   width: 30px;
   height: 30px;
   fill: ${PRIMARY_COLORS.BATTLESHIP_GREY};
`;

export const CalendarXImg = styled(CalendarX)`
   width: 30px;
   height: 30px;
   fill: ${PRIMARY_COLORS.BATTLESHIP_GREY};
`;

export const CrossIconImg = styled(CrossIconLabel)`
   ${IconsCss};
   float: right;
`;

export const CheckMarkImg = styled(CheckMarkIconLabel)`
   width: 15px;
   height: 15px;
   float: right;
`;

export const CalendarIconImg = styled(CalendarIcon)`
   width: 15px;
   height: 15px;
   float: right;
`;
