import React from "react";
import { MobileLayoutSteps } from "app/consts";
import MobileMapView from "../MobileMapView";
import { Div } from "./styled";
import { OpportunitySearchBar } from "../../../components/OpportunitySearchBar/OpportunitySearchBar";

interface MobileMapViewWithFiltersProps {
   setLayout: (arg: MobileLayoutSteps) => void;
}

const MobileMapViewWithFilters: React.FC<MobileMapViewWithFiltersProps> = ({
   setLayout,
}) => {
   return (
      <Div>
         <div>
            <OpportunitySearchBar />
         </div>
         <div>
            <MobileMapView setLayout={setLayout} />
         </div>
      </Div>
   );
};

export default MobileMapViewWithFilters;
