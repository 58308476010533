import React, { useContext, useState, useRef } from "react";
import { BasicLayoutOpportunityListWrapper } from "./styled";
import OpportunityCard from "../../../components/OpportunityCard";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "app/components/OpportunitySearchComponent/context/OpportunitySearchContext";
import { OpportunityLocatorViewContext } from "app/components/OpportunitySearchComponent/context/OpportunityLocatorViewContext";
import Loader from "app/components/OpportunitySearchComponent/components/Loader";
import { IBloodDrive } from "types/IBloodDrive";
import { v4 } from "uuid";
import { NoRecordFound } from "app/components/NoRecordFound";
import Separator from "../../../../OpportunitySearchComponent/components/OpportunityCard/Separator";
import { format } from "date-fns";

const BasicLayoutOpportunityList = () => {
   const observer = useRef<any>();
   const { filters, filtered, scrolledItems } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );
   const { startScrollLoading } = React.useContext(
      OpportunityLocatorViewContext,
   );
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const lastBookElementRef = React.useCallback(
      (node: React.LegacyRef<HTMLDivElement> | undefined) => {
         if (isLoading) return;
         if (observer.current) observer.current.disconnect();
         observer.current = new IntersectionObserver(entries => {
            if (
               filtered &&
               entries[0].isIntersecting &&
               scrolledItems.length < filtered.length
            ) {
               startScrollLoading();
               setIsLoading(true);
            }
         });
         if (node) observer.current.observe(node);
      },
      [isLoading, scrolledItems, filtered],
   );

   const renderSeparator = (
      card: IBloodDrive,
      index: number,
   ): JSX.Element | undefined => {
      if (filters?.dateKind === "For Today") return;
      if (filters?.startDate) {
         if (index === 0) return <Separator startTime={card.startTime} />;

         const currentDate = format(new Date(card.startTime), "dd-MM-yyyy");
         const prevDate = format(
            new Date(scrolledItems[index - 1].startTime),
            "dd-MM-yyyy",
         );

         if (currentDate !== prevDate) {
            return <Separator startTime={card.startTime} />;
         }
      }
   };

   return (
      <BasicLayoutOpportunityListWrapper>
         {scrolledItems?.map((card: IBloodDrive, index: number) => (
            <div key={v4()}>
               {index + 1 === scrolledItems.length ? (
                  <div ref={lastBookElementRef as any}>
                     <OpportunityCard details={card} />
                  </div>
               ) : (
                  <>
                     {renderSeparator(card, index)}
                     <OpportunityCard details={card} />

                  </>
               )}
            </div>
         ))}
         {isLoading && <Loader />}
         {/* Show no record found message if the list is empty. (AEP-60)*/}
         {scrolledItems.length === 0 && <NoRecordFound />}
      </BasicLayoutOpportunityListWrapper>
   );
};

export default BasicLayoutOpportunityList;
