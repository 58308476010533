import _ from "lodash";
import { getEndOfDay, getStartOfDay } from "./dateUtil";

export const getDefaultStartDate = (items: any[], dateKey: string): Date => {
   if (items.length > 0) {
      const sortedDates: Date[] = _(items)
         .sortBy(dateKey)
         .map(item => new Date(item[dateKey]))
         .value();
      return getStartOfDay(sortedDates[0]);
   } else {
      return new Date(new Date().getFullYear(), 0, 1);
   }
};
export const getDefaultEndDate = (items: any[], dateKey: string): Date => {
   if (items.length > 0) {
      const sortedDates: Date[] = _(items)
         .sortBy(dateKey)
         .map(item => new Date(item[dateKey]))
         .reverse()
         .value();
      return getEndOfDay(sortedDates[0]);
   } else {
      return new Date();
   }
};
