import styled from "styled-components";
import Container from "react-bootstrap/Container";

export const SignNewAptContainer = styled(Container)`
   font-size: 14px;
   &.desktop-css {
      width: 100%;
      .button-padding {
         padding: 7px;
         width: 100%;
         font-size: 12px;
      }
   }
   &.mobile-css {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
      width: 100%;
      .button-padding {
         padding: 10px;
         width: 100%;
         font-size: 12px;
      }
      .text-section-css {
         text-align: center;
         margin-bottom: 50px;
      }
   }
   .section-css {
      align-items: center;
   }
   .text-alignment {
      text-align: center;
   }
`;
