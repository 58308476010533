import React, { ReactElement, ReactNode, useContext, useMemo } from "react";
import { OpportunitySearchForm } from "../../components/OpportunitySearchForm/OpportunitySearchForm";
import { Col, Row } from "react-bootstrap";
import { OpportunityList } from "../../components/OpportunityList/OpportunityList";
import { OpportunityMap } from "../../components/OpportunityMap/OpportunityMap";
import { OpportunityLocatorViewContext } from "../../context/OpportunityLocatorViewContext";
import { OpportunityLocatorViews } from "../../../../consts";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../context/OpportunitySearchContext";
import { IBloodDrive } from "../../../../../types/IBloodDrive";
import { NoRecordFound } from "../../../NoRecordFound";

export interface IFullLayoutProps {
   children?: ReactNode[] | ReactNode;
}

/**
 * Return the size of the left column (0-12) conditional on the results and the view.
 * @param selectedView
 * @param results
 */
function calculateLeftColumnWidthSize(
   selectedView: OpportunityLocatorViews,
   results: IBloodDrive[],
): number {
   if (results.length === 0) {
      return 12;
   } else {
      return selectedView === OpportunityLocatorViews.FULL_MAP_CONTRACTED_LAYOUT
         ? 6
         : 4;
   }
}

/**
 * Return the size of the right column (0-12) conditional on the results and the view.
 * @param selectedView
 * @param results
 */
function calculateRightColumnWidthSize(
   selectedView: OpportunityLocatorViews,
   results: IBloodDrive[],
): number {
   if (results.length === 0) {
      return 0;
   } else {
      return selectedView === OpportunityLocatorViews.FULL_MAP_CONTRACTED_LAYOUT
         ? 6
         : 8;
   }
}
export function FullLayout(props: IFullLayoutProps): ReactElement {
   const { selectedView } = React.useContext(OpportunityLocatorViewContext);
   const { filtered } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );
   const displayNoRecordFound = useMemo(() => {
      return filtered === null || filtered?.length === 0;
   }, [filtered]);
   return (
      <>
         <Row
            className={`d-flex flex-row mt-3 ${displayNoRecordFound ? "flex-grow-0" : "flex-grow-1"
               }`}
         >
            {/*Left Col*/}
            <Col
               md={calculateLeftColumnWidthSize(selectedView, filtered || [])}
               className={"d-flex flex-column"}
            >
               <Row className={"d-flex flow-grow-0"}>
                  <Col xs={12}>
                     <OpportunitySearchForm />
                  </Col>
               </Row>
               <Row className={"d-flex flex-grow-1 flex-row"}>
                  <Col xs={12} className={"d-flex flex-grow-1"}>
                     <OpportunityList />
                  </Col>
               </Row>
            </Col>
            {/* Right col */}
            <Col
               md={calculateRightColumnWidthSize(selectedView, filtered || [])}
            >
               <OpportunityMap />
            </Col>
         </Row>
         {displayNoRecordFound && <NoRecordFound />}
      </>
   );
}
