import React, { ReactElement, useContext, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../context/OpportunitySearchContext";
import { IBloodDrive } from "../../../../../types/IBloodDrive";
import OpportunityCard from "../OpportunityCard";
import { OpportunityLocatorViewContext } from "../../context/OpportunityLocatorViewContext";
import { OpportunityLocatorViews } from "../../../../consts";
import Separator from "../OpportunityCard/Separator";
import { format } from "date-fns";
import "./style.scss";

export interface IOpportunityListProps { }

export function OpportunityList(
   props: IOpportunityListProps,
): ReactElement<IOpportunityListProps> {
   const { scrolledItems, filters } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );
   const { selectedView } = React.useContext(OpportunityLocatorViewContext);

   const renderSeparator = (
      card: IBloodDrive,
      index: number,
   ): JSX.Element | undefined => {
      {/* don't show date separetor if the selected date is for current date (SCREDP-66) */ }
      if (filters?.dateKind === "For Today") return;
      if (filters?.startDate) {
         if (index === 0) return <Separator startTime={card.startTime} />;

         const currentDate = format(new Date(card.startTime), "dd-MM-yyyy");
         const prevDate = format(
            new Date(scrolledItems[index - 1].startTime),
            "dd-MM-yyyy",
         );

         if (currentDate !== prevDate) {
            return <Separator startTime={card.startTime} />;
         }
      }
   };

   const listLayout = useMemo(() => {
      switch (selectedView) {
         case OpportunityLocatorViews.FULL_MAP_CONTRACTED_LAYOUT:
            return (
               <Row
                  className={"d-flex flex-grow-1"}
                  id={"opportunity-list"}
                  style={{ alignContent: "flex-start" }}
               >
                  {scrolledItems?.map((card: IBloodDrive, index: number) => (
                     <React.Fragment key={`opportunity-${index}`}>
                        {renderSeparator(card, index)}
                        <Col
                           className={"d-flex flex-column"}
                           sm={12}
                           md={scrolledItems.length > 1 ? 6 : 12}
                           style={{
                              paddingLeft: 0,
                              paddingRight: 0,
                           }}
                        >
                           <OpportunityCard details={card} />
                        </Col>
                     </React.Fragment>
                  ))}
               </Row>
            );

         case OpportunityLocatorViews.FULL_MAP_EXPANDED_LAYOUT:
            return (
               <Row className={"d-flex flex-grow-1"} id={"opportunity-list"}>
                  {scrolledItems?.map((card: IBloodDrive, index: number) => (
                     <React.Fragment key={`opportunity-${index}`}>
                        {renderSeparator(card, index)}
                        <Col
                           className={"d-flex flex-column"}
                           style={{
                              marginLeft: 0,
                              marginRight: 0,
                              marginBottom: 5,
                              paddingLeft: 0,
                              paddingRight: 0,
                           }}
                           sm={12}
                        >
                           <OpportunityCard details={card} />
                        </Col>
                     </React.Fragment>
                  ))}
               </Row>
            );
         case OpportunityLocatorViews.LANDING_LAYOUT:
            return (
               <Row
                  className={"d-flex flex-grow-1"}
                  id={"opportunity-list"}
                  style={{ alignContent: "flex-start" }}
               >
                  <Col xs={12}>
                     {scrolledItems?.map((card: IBloodDrive, index: number) => (
                        <Row key={`opportunity-${index}`}>
                           {/* show date separetor (SCREDP-66) */}
                           {renderSeparator(card, index)}
                           <OpportunityCard details={card} />
                        </Row>
                     ))}
                  </Col>
               </Row>
            );
         default:
            console.error("Invalid list view");
      }
   }, [selectedView, scrolledItems]);

   return <div id={"opportunity-list-wrapper"}>{listLayout}</div>;
}
