import React, { useEffect, useState } from "react";
import { HistoricalResultsDiv } from "./styled";
import HistoricalResultsDropdownSection from "./HistoricalResultsDropdownSection";
import MeasureComponent from "../MeasureComponent";
import { IDonorDonationDTO } from "../../../../../../services/bexWISE/donorDataService/IDonorDataService";
import { Section } from "../../../../../components/Section/Section";

interface MeasuresInterface {
   label: string;
   val: string;
}
export const PhysicalMeasuresLabelData: MeasuresInterface[] = [
   {
      label: "Height (ft, in)",
      val: "height",
   },
   {
      label: "Weight (lb)",
      val: "weight",
   },
   {
      label: "Body Mass Index (BMI) (kg/m²)",
      val: "bmi",
   },
   {
      label: "Blood Pressure (mmHg)",
      val: "bloodPressure",
   },
   {
      label: "Pulse (bpm)",
      val: "pulse",
   },
   {
      label: "Temperature (°F)",
      val: "temperature",
   },
];
export const BloodMeasuresDataLabel: MeasuresInterface[] = [
   {
      label: "Hemoglobin (gm/dL)",
      val: "hemoglobin",
   },
   {
      label: "Hematocrit (%)",
      val: "hematocrit",
   },
   {
      label: "Copper Sulfate",
      val: "cuSO4",
   },
];

interface HistoricalResultsSectionProps {
   yearsValues: number[];
   selectedYear: number;
   setSelectedYear: Function;
   selectedDate: string;
   setSelectedDate: Function;
   displayDonorData: IDonorDonationDTO[];
}
const HistoricalResultsSection: React.FC<HistoricalResultsSectionProps> = ({
   yearsValues,
   selectedYear,
   setSelectedYear,
   selectedDate,
   setSelectedDate,
   displayDonorData,
}) => {
   const [visibleData, setVisibleData] = useState<IDonorDonationDTO[]>([]);
   const [startIndex, setStartIndex] = useState<number>(0);

   useEffect(() => {
      if (displayDonorData) {
         setVisibleData(displayDonorData.slice(startIndex, startIndex + 9));
      }
   }, [displayDonorData, startIndex]);

   return (
      <HistoricalResultsDiv>
         <HistoricalResultsDropdownSection
            yearsValues={yearsValues}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            displayDonorData={displayDonorData}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            visibleData={visibleData}
            setStartIndex={setStartIndex}
            startIndex={startIndex}
         />
         <Section header={{ title: "PHYSICAL MEASURES" }}>
            {PhysicalMeasuresLabelData.map(eachMeasure => (
               <React.Fragment key={eachMeasure.val}>
                  <MeasureComponent
                     label={eachMeasure.label}
                     val={eachMeasure.val}
                     selectedDate={selectedDate}
                     visibleData={visibleData}
                  />
               </React.Fragment>
            ))}
         </Section>
         <Section header={{ title: "BLOOD MEASURES" }}>
            {BloodMeasuresDataLabel.map(eachMeasure => (
               <React.Fragment key={eachMeasure.val}>
                  <MeasureComponent
                     label={eachMeasure.label}
                     val={eachMeasure.val}
                     selectedDate={selectedDate}
                     visibleData={visibleData}
                  />
               </React.Fragment>
            ))}
         </Section>
      </HistoricalResultsDiv>
   );
};

export default HistoricalResultsSection;
