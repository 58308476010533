export const DONATION_ELIGIBILITY_LABEL = [
   {
      label: "Appointment Type",
      id: "appointmentTypeDescription",
   },
   {
      label: "Instrument",
      id: "instrumentDescription",
   },
   {
      label: "Eligible Date",
      id: "eligibleDate",
   },
];
