import styled from "styled-components";
import Container from "react-bootstrap/Container";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/svgs/arrow-left.svg";
import { ReactComponent as ExclamationIcon } from "../../../assets/svgs/exclamation-circle-fill.svg";
import { PRIMARY_COLORS } from "../../consts/colors";
import { Card } from "react-bootstrap";

export const AppointmentListOuterContainer = styled.div`
   position: relative;
   display: flex;
   font-family: "Roboto", "Helvetica Neue", sans-serif !important;

   width: 100%;
   .arrow-section {
      width: 45px;
      height: 45px;
      text-align: center;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 50%;
      left: 180px;
      bottom: 0px;
      background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
      border-color: transparent;
      color: white;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18)
   }

   .arrow-section:hover {
      background-color: rgba(205, 33, 41, 0.7);
      transition: background-color 0.2s;
   }
   .arrow-section:focus {
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
   }
   .bold{
      font-weight: bold;
   }

`;
export const ArrowLeftImg = styled(ArrowLeftIcon)`
   fill: ${PRIMARY_COLORS.DOVE_GREY};
   cursor: pointer;
   width: 38px;
   height: 38px;
`;

export const ExclamationImg = styled(ExclamationIcon)`
   fill: ${PRIMARY_COLORS.FLUSH_MAHOGANY};
   width: 30px;
   height: 30px;
`;

export const AppointmentListContainer = styled(Container)`
   margin-top: 30px;
   &.mobile-version-css {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 10px;
      .alert-msg-css {
         display: flex;
         flex-direction: column;
         gap: 10px;
      }
      .appointment-text {
         margin: 15px 20px;
      }
      .bottom-section-css {
         margin-left: 20px;
         margin-right: 20px;
      }
   }
   &.medium-screen-css {
      width: 80%;
      .alert-msg-css {
         display: flex;
         gap: 20px;
      }
   }
   .no-appointment-class {
      font-size: 14px;
      color: gray;
      margin-left: 18px;
      margin-bottom: 18px;
   }
   .alert-msg-css {
      font-size: 14px;
      align-items: center;
      background-color: ${PRIMARY_COLORS.COSMOS};
   }
   .appointment-text {
      font-size: 13px!important;
      margin-bottom: 25px;
      margin-top: 5px;
   }
   .subTitleSection {
      border-bottom: 1px solid #D5DAE0;
      padding-bottom: 20px;
      margin-bottom: 30px;
      margin-top: 15px;
   }
   .align-center-items {
      display: flex;
      align-items: center;
      gap: 10px;
   }
   .location-clock-mobile-css {
      display: flex;
      justify-content: space-between;
      float: right;
      
   }
   .clockStyle {
      width: 14px;
      height: auto;
   }
   .floatRight{
      float: right;
   }
   .floatLeft {
      h5{
         float: left;
      }
   }
   .stackContent{
      height: calc(100vh - 160px);
      display: flex;
      flex-direction: column;
      padding: 0px 8px;
      overflow-y: auto;
   }
   .stackContentMobile{
      height: calc(100vh - 122px);
      display: flex;
      flex-direction: column;
      padding: 0px 8px;
      overflow-y: auto;
   }
   .change-location-button {
      padding-right: 20px;
      padding-left: 25px;
      font-size: 12px;
      .search-icon {
         width: 12px;
         height: 12px;
      }
      margin: -10px -5px 0px;
      gap:10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 1px 3px 0 rgba(60,64,67,.3), 0 4px 8px 3px rgba(60,64,67,.15);
   }
`;

export const StyledAppointmentCard = styled(Card)`
   display: flex;
   padding: 30px;
   transition: all 0.3s;
   border-color: ${PRIMARY_COLORS.NEBULA};
   border-width: 1px;
   &.mobile-version-css {
      padding: 15px 0px 0px;
   }
`;

export const Title = styled.h5`
   font-size: 15px;
   font-weight: bold;
   font-family: "Roboto", "Helvetica Neue", sans-serif !important;
   color: ${PRIMARY_COLORS.DAVY_GREY};
`;