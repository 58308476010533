import * as React from "react";
import {
   defaultEnvironmentContext,
   EnvironmentContext,
   IEnvironmentContext,
} from "./environmentContext";
import { useEffect, useState } from "react";
import { ReactI18NextChild } from "react-i18next";
import { IEnvironmentProperties } from "../../../services/environmentService/IEnvironmentProperties";
import { useEnvironment } from "../../hooks/useEnvironment";
import { IEnvironmentConfiguration } from "../../../services/environmentService/IEnvironmentConfiguration";
import { Base64Image } from "../../../services/environmentService/Base64Image";
import ItsyLogo from "../../../assets/images/itsy-logo.png";
import ItsyBanner from "../../../assets/images/banner.png";
import { ICenterData } from "../../../services/bexWISE/centerDataService/ICenterDataService";
import { getCenterDataServiceInstance } from "../../../services/bexWISE/centerDataService/getCenterDataServiceInstance";

export interface IEnvironmentContextProviderProps {
   children: ReactI18NextChild;
}

export function EnvironmentContextProvider(
   props: IEnvironmentContextProviderProps,
) {
   const { getConfig, getProperties, getImage } = useEnvironment();
   const [context, setContext] = useState<IEnvironmentContext>(
      defaultEnvironmentContext,
   );
   const [loaded, setLoaded] = useState<boolean>(false);

   useEffect(() => {
      const configPromise: Promise<IEnvironmentConfiguration> = getConfig();
      const propsPromise: Promise<IEnvironmentProperties> = getProperties();
      const logoPromise: Promise<Base64Image> = getImage("logo").then(
         (imageData: string | undefined) => {
            if (imageData) {
               return imageData;
            } else {
               return ItsyLogo;
            }
         },
      );

      const bannerPromise: Promise<Base64Image> = getImage("banner").then(
         (imageData: string | undefined) => {
            if (imageData) {
               return imageData;
            } else {
               return ItsyBanner;
            }
         },
      );
      const centerPromise: Promise<ICenterData> =
         getCenterDataServiceInstance().getCenter();

      Promise.all([
         configPromise,
         propsPromise,
         logoPromise,
         bannerPromise,
         centerPromise,
      ])
         .then(
            ([
               environmentConfig,
               environmentProperties,
               logoSrc,
               bannerSrc,
               centerData,
            ]) => {
               setContext({
                  environmentConfig,
                  environmentProperties,
                  logoSrc,
                  bannerSrc,
                  centerData,
               });
               setLoaded(true);
            },
         )
         .catch(e => {
            console.error("Error fetching environment data", e);
         });
   }, []);
   return (
      <EnvironmentContext.Provider value={context}>
         {context.environmentProperties && props.children}
      </EnvironmentContext.Provider>
   );
}
