import styled from "styled-components";

export const PhysicalExamsContainer = styled.div`
   width: 100%;
   &.mobile-css {
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 0px;
      margin-right: 0px;
   }
`;
