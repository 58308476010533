import { IBloodDrive } from "../../../../types/IBloodDrive";
import { useEffect, useState } from "react";
import { getFilteredData } from "../util/filtersForOpportunityLocatorPage";
import { IOpportunitySearchFilter } from "./useOpportunitySearchFilter";

export interface IUseOpportunitySearchFilteredDataState {
   rawDrives: IBloodDrive[] | null;
   filteredData: IBloodDrive[] | null;
   filters: IOpportunitySearchFilter;
}

export interface IUseOpportunitySearchFilteredDataProps {
   initialDrives: IBloodDrive[] | null;
   initialFilters: IOpportunitySearchFilter;
}

function applyOpportunitySearchFilters(
   data: IBloodDrive[] | null,
   filters: IOpportunitySearchFilter,
): IBloodDrive[] | null {
   if (data === null) {
      return null;
   } else {
      return getFilteredData(data, filters);
   }
}

export function useOpportunitySearchFilteredData({
   initialDrives,
   initialFilters,
}: IUseOpportunitySearchFilteredDataProps) {
   const [state, setState] = useState<IUseOpportunitySearchFilteredDataState>({
      rawDrives: [],
      filters: initialFilters,
      filteredData: [],
   });

   useEffect(() => {
      setState({
         ...state,
         rawDrives: initialDrives,
         filteredData: applyOpportunitySearchFilters(
            initialDrives,
            state.filters,
         ),
      });
   }, []);

   const setData = async (rawDrives: IBloodDrive[] | null): Promise<void> => {
      setState({
         ...state,
         rawDrives: rawDrives,
         filteredData: applyOpportunitySearchFilters(
            rawDrives,
            state.filters,
         ),
      });
   };

   const setFilters = (filters: IOpportunitySearchFilter) => {
      setState({
         ...state,
         filters,
         filteredData: applyOpportunitySearchFilters(state.rawDrives, filters),
      });
   };

   return {
      ...state,
      setData,
      setFilters,
   };
}
