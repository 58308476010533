import React, { ReactNode, ReactElement } from "react";
import { ISectionHeaderProps, SectionHeader } from "./SectionHeader";
import "./style.scss";
export interface ISectionProps {
   children?: ReactNode[] | ReactNode;
   className?: string;
   header?: ISectionHeaderProps;
}

export function Section(props: ISectionProps): ReactElement {
   return (
      <div className={`section ${props.className ? props.className : ""}`}>
         {props.header && <SectionHeader {...props.header} />}
         <div className={"section-body"}>{props.children}</div>
      </div>
   );
}
