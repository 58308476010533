import React from "react";
import { isCurrentDateEqualToPreviousDate } from "utils/isCurrentDateEqualPreviousDate";
import HorizontalLine from "../HorizontalLine";
import PastAppointmentDesktopSlot from "./components/PastAppointmentDesktopSlot";
import { IAppointment } from "types/IAppointment";

interface PastAppointmentDesktopProps {
   appointment: IAppointment;
   appointments: IAppointment[];
   index: number;
}
export const PastAppointmentDesktop: React.FC<PastAppointmentDesktopProps> =
   React.memo(({ appointment, appointments, index }) => {
      return (
         <div className="d-flex">
            {Boolean(
               isCurrentDateEqualToPreviousDate(
                  appointment.appointmentDateTime,
                  index,
                  appointments,
               ),
            ) ? (
               <HorizontalLine>
                  <div className="d-flex w-100 mt-3">
                     <PastAppointmentDesktopSlot appointment={appointment} />
                  </div>
               </HorizontalLine>
            ) : (
               <div className="d-flex w-100 mt-4">
                  <HorizontalLine
                     appointmentTime={appointment.appointmentDateTime}
                  >
                     <PastAppointmentDesktopSlot appointment={appointment} />
                  </HorizontalLine>
               </div>
            )}
         </div>
      );
   });
