import { FilterInputForm } from "../../../../Form/Filter/FilterInputForm";
import { FilterInputFormRow } from "../../../../Form/Filter/FilterInputFormRow";
import { LocationOrAccountDropdown } from "../components/LocationOrAccountDropdown";
import { MobileOrDonationCenterDropdown } from "../components/MobileOrDonationCenterDropdown";
import { MoreFiltersModalControl } from "../../../../Form/Inputs/Instances/MoreFiltersModalControl/MoreFiltersModalControl";
import OpportunitySearchBarMoreFiltersModal from "../OppprtunityMoreFilters";
import { FilterControlWrapper } from "../../../../Form/Filter/FilterControlWrapper";
import { InputWrapper } from "../../../../Form/Inputs/InputWrapper/InputWrapper";
import Searchbar from "../components/Searchbar";
import FormInputCounter from "../../../../Form/Inputs/Generic/FormInputCounter";
import FormDatePicker from "../../../../Form/Inputs/Generic/FormDatePicker";
import { StyledButton } from "../styled";
import React, { useContext, useState, useEffect } from "react";
import { LocationOrAccountOptions, OpportunityLocatorViews } from "../../../../../consts";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../../context/OpportunitySearchContext";
import { OpportunityLocatorViewContext } from "app/components/OpportunitySearchComponent/context/OpportunityLocatorViewContext";
import { useMediaQuery } from "react-responsive";


export function OpportunitySearchBarLandingView() {
   const {
      filters: { searchType },
   } = useContext<IOpportunitySearchContext>(OpportunitySearchContext);

   const { selectedView, changeLayout } = React.useContext(
      OpportunityLocatorViewContext,
   );

   const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
   const isMobile: boolean = useMediaQuery({ maxWidth: 1000 });

   // Navigate to the full map contracted view when the search button is clicked (AEP-61)
   const handleButtonClick = () => {
      changeLayout(OpportunityLocatorViews.FULL_MAP_CONTRACTED_LAYOUT);
   }

   // Remove the search button if the view is not the landing view (AEP-61)
   useEffect(() => {
      if (selectedView !== OpportunityLocatorViews.LANDING_LAYOUT) {
         setIsSearchButtonClicked(true);
      }
   }, [isSearchButtonClicked]);

   return (
      <FilterInputForm>
         <FilterInputFormRow stretch={false}>
            <LocationOrAccountDropdown />
            <MobileOrDonationCenterDropdown />
            <MoreFiltersModalControl
               moreFiltersModal={OpportunitySearchBarMoreFiltersModal}
            />
         </FilterInputFormRow>
         <FilterInputFormRow>
            <FilterControlWrapper>
               <InputWrapper>
                  <Searchbar name="address" />
               </InputWrapper>
            </FilterControlWrapper>

            <FilterControlWrapper>
               {searchType === LocationOrAccountOptions.LOCATION && (
                  <FilterControlWrapper>
                     <InputWrapper>
                        <FormInputCounter name="miles" placeholder="Miles" />
                     </InputWrapper>
                  </FilterControlWrapper>
               )}
               <FilterControlWrapper>
                  <InputWrapper>
                     <FormDatePicker name="date" />
                  </InputWrapper>
               </FilterControlWrapper>

               {/* Added search button (SCREDP-86)*/}
               {isMobile && (
                  <FilterControlWrapper grow={false}>
                     <InputWrapper>
                        <StyledButton type="submit" onClick={() => handleButtonClick()}>
                           <i className="pi pi-search fs-large"></i>
                        </StyledButton>
                     </InputWrapper>
                  </FilterControlWrapper>

               )}
            </FilterControlWrapper>

            {/* Show search button and on click navigate to the FULL_MAP_CONTRACTED_LAYOUT (AEP-61)*/}
            {!isMobile && (
               <div>
                  <FilterControlWrapper grow={false}>
                     <InputWrapper>
                        <StyledButton type="submit" onClick={() => handleButtonClick()}>
                           <i className="pi pi-search fs-large"></i>
                        </StyledButton>
                     </InputWrapper>
                  </FilterControlWrapper>
               </div>

            )}
         </FilterInputFormRow>
      </FilterInputForm>
   );
}
