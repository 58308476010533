import {
   DonationOutcome,
   IDonorDonationDTO,
} from "services/bexWISE/donorDataService/IDonorDataService";
import { IMyDonationsFilters } from "../app/pages/MyDonationPage/context/DonationContext";
import { locationFilter } from "./filters/filterOperations/locationFilter";
import { dateFilter } from "./filters/filterOperations/dateFilter";

const statusFilter = (
   status: DonationOutcome[],
   donation: IDonorDonationDTO,
): boolean => {
   if (status.length) {
      return status.some((item: string) => item === donation.outcome);
   }
   return true;
};

const proceduresFilter = (
   procedures: string[],
   donations: IDonorDonationDTO,
): boolean => {
   if (procedures.length) {
      return procedures.some(
         (item: string) => item === donations.donationProcedureDescription,
      );
   }
   return true;
};

const creditToFilter = (
   creditToValue: string[],
   donation: IDonorDonationDTO,
) => {
   if (creditToValue.length) {
      return creditToValue.some(
         (item: string) => item === donation.creditToLocation.name,
      );
   }
   return true;
};

export const filterAlgoForDonations = (
   formValues: IMyDonationsFilters,
   donations: IDonorDonationDTO[],
): IDonorDonationDTO[] | undefined => {
   try {
      const filteredDonations: IDonorDonationDTO[] = donations.filter(
         (donation: IDonorDonationDTO) => {
            return (
               locationFilter(
                  donation.location,
                  formValues.selectedLocationSource,
               ) &&
               statusFilter(formValues.selectedDonationOutcomes, donation) &&
               proceduresFilter(formValues.selectedProcedures, donation) &&
               creditToFilter(formValues.creditTo, donation) &&
               dateFilter(
                  formValues.startDate,
                  formValues.endDate,
                  new Date(donation.date),
               )
            );
         },
      );
      return filteredDonations;
   } catch (err) {
      console.error("Error While Filtering Results:", err);
      return undefined;
   }
};
