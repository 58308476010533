export const displayDate = (dateString: string, isYear: boolean): string => {
   const date: Date = new Date(dateString);
   const estDate: Date = new Date(date.getTime() + 300 * 60 * 1000);
   const month: string = (estDate.getMonth() + 1).toString().padStart(2, "0");
   const day: string = estDate.getDate().toString().padStart(2, "0");
   if (isYear) {
      return `${month}/${day}/${estDate.getFullYear()}`;
   }
   return `${month}/${day}`;
};
