import styled from "styled-components";
import { ReactComponent as ClipBoardImg } from "../../../../../../assets/svgs/clipboard.svg";

export const LocationSectionDiv = styled.div`
   background-color: white !important;
   box-shadow: 10px 3px 15px 0px rgb(0 0 0 / 28%);
   display: flex;
   align-items: center;
   padding: 33px 20px;
   gap: 20px;
   height: 130px;
   .right-section {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .header-css {
         font-weight: bold;
         font-size: 12px;
      }
      .content-css {
         font-size: 10px;
      }
   }
`;

export const ClipBoardIcon = styled(ClipBoardImg)`
   width: 35px;
   height: 40px;
   fill: gray;
`;
