import React, { ReactElement } from "react";
import DatePickerReact from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CalendarDaysSolid } from "../../../../../../assets/svgs/calendar_month_solid.svg";
import "./style.scss";
import { FilterControlWrapper } from "../../../Filter/FilterControlWrapper";
import { InputWrapper } from "../../InputWrapper/InputWrapper";

interface ILabeledDatePickerGroup {
   label: string;
   children?: ReactElement;
}
function LabeledDatePickerGroup({ label, children }: ILabeledDatePickerGroup) {
   return (
      <div className="label-date-group">
         <label className={"label-from-to"}>{label}:</label>
         {children}
      </div>
   );
}

export function DateRangePicker({
   startDate,
   setStartDate,
   endDate,
   setEndDate,
}) {
   const ReactDatePickerInput = React.forwardRef<
      HTMLInputElement,
      React.DetailedHTMLProps<
         React.InputHTMLAttributes<HTMLInputElement>,
         HTMLInputElement
      >
   >((props, ref) => <input ref={ref} {...props} className="input-css" />);
   return (
      <FilterControlWrapper>
         <InputWrapper minWidth={200}>
            <div className={`input date-range-picker form-control`}>
               <div className={"input-label-container"}>
                  <div className="date-range-section-css">
                     <div className={"date-svg"}>
                        <CalendarDaysSolid />
                     </div>
                     <div className={"input-group"}>
                        <LabeledDatePickerGroup label={"From"}>
                           <DatePickerReact
                              selected={startDate}
                              onChange={date => setStartDate(date)}
                              selectsStart
                              startDate={startDate}
                              endDate={endDate}
                              showYearDropdown
                              customInput={<ReactDatePickerInput />}
                           />
                        </LabeledDatePickerGroup>
                        <LabeledDatePickerGroup label={"To"}>
                           <DatePickerReact
                              selected={endDate}
                              onChange={date => setEndDate(date)}
                              selectsEnd
                              startDate={startDate}
                              endDate={endDate}
                              minDate={startDate}
                              showYearDropdown
                              customInput={<ReactDatePickerInput />}
                           />
                        </LabeledDatePickerGroup>
                     </div>
                  </div>
               </div>
            </div>
         </InputWrapper>
      </FilterControlWrapper>
   );
}
