import React, { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { SwitchSectionDiv } from "./styled";
import {
   IDonorContactPreference,
   IDonorDataService,
} from "../../../../../services/bexWISE/donorDataService/IDonorDataService";
import { getDonorDataServiceInstance } from "../../../../../services/bexWISE/donorDataService/getDonorDataServiceInstance";
import { AuthContext } from "../../../../contexts/auth/authContext";

function SwitchSectionComponent({
   sectionsContent,
   preferencesDisplayData,
   setPreferencesDisplayData,
   isMobile,
}) {
   const { userDonorId } = useContext(AuthContext);
   const isPreferenceSelected = (
      eachSwitch: IDonorContactPreference,
   ): boolean => {
      return Boolean(
         preferencesDisplayData.find(
            eachVal =>
               eachVal.contactPreferenceCode ===
               eachSwitch.contactPreferenceCode,
         ),
      );
   };

   const setPreferencesAPI = async (
      preferences: IDonorContactPreference[],
   ): Promise<void> => {
      if (userDonorId) {
         try {
            const donorDriveDataService: IDonorDataService =
               getDonorDataServiceInstance();
            await donorDriveDataService.setDonorContactPreferences(
               userDonorId,
               preferences,
            );
            setPreferencesDisplayData(preferences);
         } catch (err) {
            throw err;
         }
      }
   };

   const setPreferencesFunction = (
      isChecked: boolean,
      eachSwitch: IDonorContactPreference,
   ) => {
      if (isChecked) {
         const preferencesSelected: IDonorContactPreference[] = [
            ...preferencesDisplayData,
            {
               contactPreferenceCode: eachSwitch.contactPreferenceCode,
               contactPreferenceDescription:
                  eachSwitch.contactPreferenceDescription,
            },
         ];
         setPreferencesAPI(preferencesSelected);
      } else {
         const preferencesSelected: IDonorContactPreference[] =
            preferencesDisplayData.filter(
               (eachVal: { contactPreferenceCode: string }) =>
                  eachVal.contactPreferenceCode !==
                  eachSwitch.contactPreferenceCode,
            );
         setPreferencesAPI(preferencesSelected);
      }
   };

   return (
      <SwitchSectionDiv className={isMobile ? "mobile-css" : "desktop-css"}>
         <Col className="image-section">
            <div>
               <Image
                  src={sectionsContent.image}
                  fluid
                  alt={`${sectionsContent.label}-img`}
                  className={`${sectionsContent.id}-image-css`}
               />
            </div>
            <div className="label-css">{sectionsContent.label}</div>
         </Col>
         {!isMobile && <Col className="vertical-line-css"></Col>}
         <Row>
            {sectionsContent.switches.map(eachSwitch => (
               <Col xs={6} sm={4} key={eachSwitch.contactPreferenceCode}>
                  <Form.Check
                     type="switch"
                     id={eachSwitch.contactPreferenceCode}
                     label={eachSwitch.name}
                     checked={isPreferenceSelected(eachSwitch)}
                     onChange={e =>
                        setPreferencesFunction(e.target.checked, eachSwitch)
                     }
                     className="each-switch-div"
                  />
               </Col>
            ))}
         </Row>
      </SwitchSectionDiv>
   );
}

export default SwitchSectionComponent;
