import styled from "styled-components";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ReactComponent as CheckIcon } from "../../../../../../../assets/svgs/check.svg";
import { PRIMARY_COLORS } from "../../../../../../consts/colors";

interface ButtonProps {
   selected?: boolean;
}

export const ReactCalendar = styled(Calendar)`
   border: none;
   width: auto;
   .react-calendar__month-view__weekdays {
      display: flex;
      text-decoration: none !important;
   }
   .react-calendar__month-view__weekdays__weekday {
      text-decoration: underline;
      background: ${PRIMARY_COLORS.ASH_GREY};
      border-color: red yellow green transparent;
      color: white;
      border: 1px solid;
      text-decoration: none !important;
      abbr[title] {
         text-decoration: none !important;
      }
   }
   .react-calendar__month-view__days {
      background: white;
   }
   .react-calendar__navigation__prev2-button,
   .react-calendar__navigation__next2-button {
      visibility: hidden;
   }
   .react-calendar__tile--rangeStart,
   .react-calendar__tile--active:enabled:hover,
   .react-calendar__tile--active:enabled:focus,
   .react-calendar__tile--rangeEnd,
   .react-calendar__tile--active {
      background: ${PRIMARY_COLORS.MUTED_BLUE};
      color: white !important;
   }
   .react-calendar__tile--now {
      background: white;
      color: black !important;
   }

   .react-calendar__month-view__days__day {
      border: 0.1px solid ${PRIMARY_COLORS.MERCURY};
      color: ${PRIMARY_COLORS.GREY_GOOSE};
   }
`;

export const Button = styled("button")<ButtonProps>`
   border: none;
   border-bottom: 1px solid lightgray;
   padding: 8px 15px;
   background: ${props =>
      props.selected ? "white" : `${PRIMARY_COLORS.DAWN_PINK}`};
   text-align: left;
   font-weight: light;
   font-size: 14px;
   width: 300px;
   display: flex;
   justify-content: space-between;
   color: ${props => (props.selected ? "red" : `${PRIMARY_COLORS.BLUE_KOI}`)};
`;

export const InputContainer = styled("div")`
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 10px;
   gap: 10px;

   label {
      font-weight: bold;
      font-size: 12px;
   }

   input {
      font-size: 13px;
      width: 100%;
      padding: 10px;
      text-align: center;
      border: 1px solid gray;
      border-radius: 3px;
      &:focus {
         outline: none;
         box-shadow: none;
      }
   }
`;
export const HelperText = styled("span")`
   font-size: 14px;
   font-weight: 500;
   color: ${PRIMARY_COLORS.BLUE_KOI};
`;

export const Div = styled("div")`
   padding: 8px 15px;
`;

export const Check = styled(CheckIcon)`
   stroke: 2px;
`;
