import React from "react";
import { CardHeader } from "./styled";

interface CardProps {
   icon?: React.ReactElement;
   title?: string;
   lifeTime: string | number;
   yearToDate: string | number;
   previous12Months: string | number;
   headerbB: string;
}

interface HeaderCardProps {
   card: CardProps;
}

const HeaderCard: React.FC<HeaderCardProps> = ({ card }) => {
   return (
      <div className="">
         <CardHeader bg={card.headerbB}>
            <div className="d-flex gap-3">
               {card.icon}
               <h4>{card.title}</h4>
            </div>

            <div className="d-flex gap-3 ">
               <span>Lifetime</span>
               <h4 className="count">{card.lifeTime || 0}</h4>
            </div>
         </CardHeader>

         <div className="d-flex justify-content-center align-items-center border">
            <div className="d-flex flex-sm-row flex-column justify-content-around w-100 fw-bold p-2 border-end align-items-center">
               <p className="subtitle">Year to date</p>
               <p className="count">{card?.yearToDate || 0}</p>
            </div>

            <div className="d-flex flex-sm-row flex-column justify-content-around w-100 fw-bold p-2 align-items-center">
               <p className="subtitle">Previous 12 Months</p>
               <p className="count">{card.previous12Months || 0}</p>
            </div>
         </div>
      </div>
   );
};

export default HeaderCard;
