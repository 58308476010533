import React from "react";
import CommonHeader from "../../../../components/CommonHeader";
import { Table } from "react-bootstrap";
import { CalendarMonthSolidImg, DonationImg, MoistureImg } from "./styled";
import { IDonorEligibilityDate } from "../../../../../services/bexWISE/donorDataService/IDonorDataService";
import { Section } from "../../../../components/Section/Section";
import { DONATION_ELIGIBILITY_LABEL } from "../../../../consts/DonationEligibilityLabels";

interface DonationEligibilityProps {
   eligibilityDates: IDonorEligibilityDate[];
   isMobile: boolean;
}

const DonationEligibilityTable: React.FC<DonationEligibilityProps> = ({
   eligibilityDates,
   isMobile,
}) => {
   const IconDisplay = (id: string): string | JSX.Element => {
      switch (id) {
         case "appointmentTypeDescription":
            return <DonationImg />;
         case "instrumentDescription":
            return <MoistureImg />;
         case "eligibleDate":
            return <CalendarMonthSolidImg />;
         default:
            return "";
      }
   };

   const getDisplayValue = (
      eachDonation: IDonorEligibilityDate,
      id: string,
   ) => {
      if (id === "eligibleDate") {
      	// Correctly parse the Eligible Date value for display (AEP-56)
         return new Date(eachDonation[id]).toLocaleDateString();
      }
      return eachDonation[id];
   };

   return (
      <Section className="donation-eligibility-table-css">
         <CommonHeader title="DONATION ELIGIBILITY" />
         <div className="table-div-css">
            <Table responsive>
               <thead className="table-header-css">
                  <tr>
                     {!isMobile && <th className="last-first-row-width"></th>}
                     {DONATION_ELIGIBILITY_LABEL.map(each => (
                        <th key={each.id}>
                           {IconDisplay(each.id)} {each.label}
                        </th>
                     ))}
                     {!isMobile && <th className="last-first-row-width"></th>}
                  </tr>
               </thead>
               <tbody className="table-body-css">
                  {eligibilityDates.map((eachDonation, index) => (
                     <tr
                        key={index}
                        className={`${
                           isMobile ? "mobile-tr-css" : "desktop-tr-css"
                        }`}
                     >
                        {!isMobile && (
                           <td className="last-first-row-width"></td>
                        )}
                        {DONATION_ELIGIBILITY_LABEL.map(eachLabel => (
                           <td
                              key={`${eachLabel.id}-${index}`}
                              className={`${
                                 isMobile ? "" : "border-bottom-css"
                              } ${
                                 eachLabel.id === "eligibleDate"
                                    ? "bold-css"
                                    : ""
                              }`}
                           >
                              {getDisplayValue(eachDonation, eachLabel.id)}
                           </td>
                        ))}
                        {!isMobile && (
                           <td className="last-first-row-width"></td>
                        )}
                     </tr>
                  ))}
               </tbody>
            </Table>
         </div>
      </Section>
   );
};

export default DonationEligibilityTable;
