import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { MyDonationDesktop } from "../MyDonationDesktop";
import MyDonationMobile from "../MyDonationMobile";
import {
   DonationContext,
   IDonationContext,
} from "../../context/DonationContext";
import { NoRecordFound } from "app/components/NoRecordFound";
import { IDonorDonationDTO } from "services/bexWISE/donorDataService/IDonorDataService";

const MyDonationSlots = () => {
   const { filteredDonations } = useContext<IDonationContext>(DonationContext);
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });

   if (!filteredDonations.length) {
      return <NoRecordFound />;
   }

   return (
      <div style={{ minHeight: 300 }}>
         {filteredDonations?.map(
            (donation: IDonorDonationDTO, index: number) => {
               return isMobile ? (
                  <MyDonationMobile
                     donation={donation}
                     donations={filteredDonations}
                     index={index}
                     key={index}
                  />
               ) : (
                  <MyDonationDesktop
                     donation={donation}
                     donations={filteredDonations}
                     index={index}
                     key={index}
                  />
               );
            },
         )}
      </div>
   );
};

export default MyDonationSlots;
