import React, { ReactNode, ReactElement } from "react";
import "./style.scss";
export interface IInputWrapperProps {
   children?: ReactNode[] | ReactNode;
   minWidth?: number;
}

export function InputWrapper({
   children,
   minWidth,
}: IInputWrapperProps): ReactElement {
   const style = { minWidth };
   return (
      <div className={"input-wrapper"} style={{ ...style }}>
         {children}
      </div>
   );
}
