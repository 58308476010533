import { AbstractBexWISEService } from "../bexWISEResolutionService/AbstractBexWISEService";
import { ICenterData, ICenterDataService } from "./ICenterDataService";

export class CenterDataService
   extends AbstractBexWISEService
   implements ICenterDataService
{
   public getCenter(): Promise<ICenterData> {
      return this.getBex<ICenterData>(`center`);
   }
}
