import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { PRIMARY_COLORS } from "../../../../consts/colors";

export const DonorModal = styled(Modal)`
   .modal-body-css {
      .header-css {
         display: flex;
         gap: 20px;
         align-items: center;
         padding: 10px 20px 25px 5px;

         border-bottom: 2px solid ${PRIMARY_COLORS.COOL_GREY};
         .logo-css {
            width: 80px;
            height: 35px;
         }
         .logo-section-css {
            display: flex;
            flex-direction: column;
            gap: 0px;
         }
         .bci-css {
            font-size: 16px !important;
            color: ${PRIMARY_COLORS.DAVY_GREY};
            font-weight: bold;
         }
         .di-css {
            font-size: 12px !important;
            color: ${PRIMARY_COLORS.DAVY_GREY};
            font-weight: bold;
         }
      }
      .content-css {
         display: flex;
         flex-direction: column;
         height: 80%;
         justify-content: space-between;
      }
      .qr-section-css {
         display: flex;
         flex-direction: column;
         align-items: center;
         padding: 30px;
         gap: 10px;
         .donor-id-css {
            font-size: 14px;
            font-weight: bold;
         }
      }
      .content-bottom-section {
         display: flex;
         justify-content: space-between;
         align-items: flex-end;
      }
      .content-name-section {
         display: flex;
         flex-direction: column;
         align-items: center;
         gap: 5px;
         padding-left: 30px;
         .name-css {
            font-size: 14px;
            font-weight: bold;
            color: ${PRIMARY_COLORS.DAVY_GREY};
         }
         .blood-type-css {
            font-size: 45px;
            font-weight: bold;
            color: ${PRIMARY_COLORS.DAVY_GREY};
         }
      }
      .btn-css {
         text-decoration: none;
         font-weight: bold;
         font-size: 14px;
         color: ${PRIMARY_COLORS.DAVY_GREY};
      }
   }
`;
