import { LocationTypeCode } from "../../../types/LocationTypeCode";
import { LocationOption } from "../../../app/consts";
import { ILocation } from "../../../types/ILocation";

export function locationFilter(
   location: ILocation,
   locationValue: LocationOption,
): boolean {
   const locationCode: LocationTypeCode = location.locationType.code;
   switch (locationValue) {
      case LocationOption.MOBILE_DRIVE:
         return (
            locationCode === "M" ||
            locationCode === "I" ||
            locationCode === "C" ||
            locationCode === "N"
         );
      case LocationOption.DONATION_CENTER:
         return locationCode === "F";
      case LocationOption.ALL:
         return true;
      default:
         console.error("Invalid location filter type");
         return false;
   }
}
