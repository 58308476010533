import { IBexWISEResolutionService } from "./IBexWISEResolutionService";
import { IAWSResponse } from "../../IAWSResponse";
import axios, { AxiosRequestConfig } from "axios";
import { IAuthorizationCodeProvider } from "../../IAuthorizationCodeProvider";

// This is the base class of all services which reply on the bexWISE base URL
export abstract class AbstractBexWISEService {
   constructor(
      private bexWISEResolutionService: IBexWISEResolutionService,
      private authorizationCodeProvider: IAuthorizationCodeProvider,
   ) {}

   /**
    * Build query string of name value pairs if query map is provided, otherwise return null
    * key=val1&key2=val2
    * @param query name value map
    * @private
    */
   private buildQueryString(query?: {
      [key: string]: string | undefined;
   }): string | null {
      if (query) {
         let queryString: string = "";
         Object.keys(query).forEach((key: string) => {
            const value: string | undefined = query[key];
            // only append if value is provided
            if (value) {
               queryString += `${key}=${query[key]}&`;
            }
         });
         return queryString.slice(0, -1);
      } else {
         return null;
      }
   }

   protected async getBex<ResponseType>(
      path: string,
      query?: { [key: string]: string | undefined },
   ): Promise<ResponseType> {
      const requestConfig: AxiosRequestConfig =
         await this.generateAxiosRequestConfig();

      // Build query string
      let queryString: string | null = this.buildQueryString(query);
      return this.bexWISEResolutionService
         .resolveBexWiseAPIBaseURL()
         .then((baseURL: string) => {
            const requestString: string = queryString
               ? `${baseURL}/${path}?${queryString}`
               : `${baseURL}/${path}`;
            return axios
               .get<any, IAWSResponse<ResponseType>>(
                  requestString,
                  requestConfig,
               )
               .then((response: IAWSResponse<ResponseType>) => {
                  return response.data;
               })
               .catch(error => {
                  throw error;
               });
         });
   }

   protected async postBex<RequestType, ResponseType>(
      path: string,
      data: RequestType,
   ): Promise<ResponseType> {
      const requestConfig: AxiosRequestConfig =
         await this.generateAxiosRequestConfig();
      return this.bexWISEResolutionService
         .resolveBexWiseAPIBaseURL()
         .then((baseURL: string) => {
            return axios
               .post<RequestType, IAWSResponse<ResponseType>>(
                  `${baseURL}/${path}`,
                  data,
                  requestConfig,
               )
               .then((response: IAWSResponse<ResponseType>) => {
                  return response.data;
               })
               .catch(error => {
                  throw error;
               });
         });
   }

   protected async putBex<RequestType, ResponseType>(
      path: string,
      data: RequestType,
   ): Promise<ResponseType> {
      const requestConfig: AxiosRequestConfig =
         await this.generateAxiosRequestConfig();
      return this.bexWISEResolutionService
         .resolveBexWiseAPIBaseURL()
         .then((baseURL: string) => {
            return axios
               .put<RequestType, IAWSResponse<ResponseType>>(
                  `${baseURL}/${path}`,
                  data,
                  requestConfig,
               )
               .then((response: IAWSResponse<ResponseType>) => {
                  return response.data;
               })
               .catch(error => {
                  throw error;
               });
         });
   }

   protected async deleteBex<ResponseType>(
      path: string,
   ): Promise<ResponseType> {
      const requestConfig: AxiosRequestConfig =
         await this.generateAxiosRequestConfig();
      return this.bexWISEResolutionService
         .resolveBexWiseAPIBaseURL()
         .then((baseURL: string) => {
            return axios
               .delete<void, IAWSResponse<ResponseType>>(
                  `${baseURL}/${path}`,
                  requestConfig,
               )
               .then((response: IAWSResponse<ResponseType>) => {
                  return response.data;
               })
               .catch(error => {
                  throw error;
               });
         });
   }

   protected async getBlobBex(
      path: string,
      query?: { [key: string]: string | undefined },
   ): Promise<Blob> {
      // Build query string
      const requestConfig: AxiosRequestConfig =
         await this.generateAxiosRequestConfig();
      let queryString: string | null = this.buildQueryString(query);
      return this.bexWISEResolutionService
         .resolveBexWiseAPIBaseURL()
         .then((baseURL: string) => {
            const requestString: string = queryString
               ? `${baseURL}/${path}?${queryString}`
               : `${baseURL}/${path}`;
            return axios
               .get<any, IAWSResponse<Blob>>(requestString, {
                  ...requestConfig,
                  responseType: "blob",
               })
               .then((response: IAWSResponse<Blob>) => {
                  return response.data;
               })
               .catch(error => {
                  throw error;
               });
         });
   }

   private async generateAxiosRequestConfig(): Promise<AxiosRequestConfig> {
      const authCode: string | undefined =
         await this.authorizationCodeProvider.getAuthorizationCode();
      if (authCode) {
         return {
            headers: {
               Authorization: `Bearer ${authCode}`,
            },
         };
      } else {
         return {};
      }
   }
}
