import React from "react";
import { StyledSlot } from "./styled";
import { format } from "date-fns";
import { getStrictFormattedAddress } from "utils/getStrictFormattedAddress";
import { Image } from "react-bootstrap";
import { PRIMARY_COLORS } from "app/consts/colors";
import donationTitleIcon from "assets/images/donationTitleIcon.png";
import { useMediaQuery } from "react-responsive";
import { IDonorDonationDTO } from "services/bexWISE/donorDataService/IDonorDataService";

interface MyDonationMobileSlotProps {
   donation: IDonorDonationDTO;
}

const MyDonationMobileSlot: React.FC<MyDonationMobileSlotProps> = ({
   donation,
}) => {
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });

   const Month: string = format(new Date(donation?.date), "MMM");

   const Day: string = format(new Date(donation?.date), "dd");

   const checkStatusColor = (): string | undefined => {
      switch (donation?.outcome) {
         case "Successful":
            return PRIMARY_COLORS.IRISH_GREEN;

         case "Failed":
            return PRIMARY_COLORS.JASPER;

         case "Deferred":
            return "darkorange";

         default:
            return;
      }
   };

   return (
      <>
         <StyledSlot statusColor={checkStatusColor()}>
            <div className="wrapper">
               <div className="date">
                  <p className={"month"}>{Month}</p>
                  <p className={"day"}>{Day}</p>
               </div>

               <div className="details-section">
                  <p className="title text-truncate w-100">
                     <Image src={donationTitleIcon} width="24px" />
                     <span>{donation?.donationProcedureDescription}</span>
                  </p>
                  {/* Include location name (SCRDEP-63) */}
                  <p className="sub-title text-truncate w-100">
                     {donation?.location.name} <br />
                     {getStrictFormattedAddress(donation?.location)}
                  </p>

                  <div className="bottom-section">
                     <div className="w-50">
                        <p
                           className={`sub-title text-truncate ${isMobile && "w-100"
                              }`}
                        >
                           Type:
                           <b> Allogeneic</b>
                        </p>
                        <p
                           className={`sub-title text-truncate ${isMobile && "w-100"
                              }`}
                        >
                           Credit To: {donation?.creditToLocation?.name}
                        </p>
                     </div>
                     <p className="status">{donation?.outcome}</p>
                  </div>
               </div>
            </div>
         </StyledSlot>
      </>
   );
};

export default MyDonationMobileSlot;
