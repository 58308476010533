import React, { ReactElement } from "react";
import { ILocationType } from "../../../types/ILocationType";
import { Image } from "react-bootstrap";
import { getIconForLocationType } from "./getIconForLocationType";

export interface ILocationImageIconProps {
   locationType: ILocationType;
}

// Shows either the bus icon or home icon depending on the location type
export function LocationTypeImageIcon({
   locationType,
}: ILocationImageIconProps): ReactElement {
   return <Image src={getIconForLocationType(locationType)} />;
}
