import React, { useContext, useState } from "react";
import { addDays } from "date-fns";
import { Button, Check } from "./styled";
import RangeDatePicker from "./RangeDatePicker";
import RangeDatePickerHeader from "./RangeDatePickerHeader";
import { DateKind } from "./DateKind";
import { DateCalendarType } from "./DateCalendarType";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../../../../OpportunitySearchComponent/context/OpportunitySearchContext";
import {
   getEndOfDay,
   getStartOfDay,
} from "../../../../../../../utils/dateUtil";
import * as _ from "lodash";

interface DatePickerProps { }

interface IDateRange {
   startDate: Date;
   endDate: Date;
}

function getNext14Days(): IDateRange {
   // const startOfTomorrow: Date = addDays(startOfToday, 1);
   // Changed the startDate today to startOfToday and endDate to include the current day in the 14 days range (AEP-52)
   const startOfToday: Date = getStartOfDay();
   const endOf14Days: Date = addDays(startOfToday, 14);
   return { startDate: startOfToday, endDate: endOf14Days };
}

export const DatePicker: React.FC<DatePickerProps> = () => {
   const { filters, updateFilter } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );
   const [date, setDate] = useState<DateCalendarType>(undefined);

   const handleChange = (value: DateCalendarType | Date) => {
      const dateIsRange: boolean = Array.isArray(value);
      if (dateIsRange) {
         setDate(value);
         if (value && _.isDate(value[0]) && _.isDate(value[1])) {
            updateFilter({
               dateKind: DateKind.Custom_Date,
               startDate: getStartOfDay(new Date(value[0])),
               endDate: getEndOfDay(new Date(value[1])),
            });
         } else {
            console.error("Unexpected date types");
         }
      } else {
         if (value && _.isDate(value)) {
            updateFilter({
               dateKind: DateKind.For_Today,
               startDate: getStartOfDay(new Date(value)),
               endDate: getEndOfDay(new Date(value)),
            });
         } else {
            console.error("Invalid value type");
         }
      }
   };

   const getTodayDate = () => {
      setDate(undefined);
      updateFilter({
         dateKind: DateKind.For_Today,
         startDate: getStartOfDay(),
         endDate: getEndOfDay(),
      });
   };

   const getNextFourteenDates = () => {
      const { startDate, endDate } = getNext14Days();
      setDate([startDate, endDate]);
      updateFilter({
         dateKind: DateKind.Next_14_Days,
         startDate,
         endDate,
      });
   };

   const getCustomDate = () => {
      const customSelectDate = addDays(new Date(), 14);
      const tomorrow = addDays(new Date(), 1);
      setDate([tomorrow, customSelectDate]);
      updateFilter({
         dateKind: DateKind.Custom_Date,
         startDate: getStartOfDay(tomorrow),
         endDate: getEndOfDay(customSelectDate),
      });
   };
   const isTodayDateSelected = filters.dateKind === DateKind.For_Today;
   const isNext14Selected = filters.dateKind === DateKind.Next_14_Days;
   const isCustomDateSelected = filters.dateKind === DateKind.Custom_Date;

   return (
      <>
         <Button
            selected={isTodayDateSelected}
            type="button"
            className="w-100"
            onClick={getTodayDate}
         >
            Today
            {isTodayDateSelected && <Check fill="red" />}
         </Button>

         <Button
            selected={isNext14Selected}
            type="button"
            className="w-100"
            onClick={getNextFourteenDates}
         >
            Next 14 Days
            {isNext14Selected && <Check fill="red" />}
         </Button>

         <RangeDatePickerHeader
            isCustomDateSelected={isCustomDateSelected}
            getCustomDates={getCustomDate}
         />

         {isCustomDateSelected && (
            <RangeDatePicker
               isCustomDateSelected={isCustomDateSelected}
               date={date}
               handleChange={handleChange}
            />
         )}
      </>
   );
};
