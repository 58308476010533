import styled from "styled-components";
import Footer from "../../components/Footer";
import { PRIMARY_COLORS } from "../../consts/colors";

export const PreferencesContainer = styled.div`
   width: 100%;
   &.mobile-container-css {
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 0px;
      margin-right: 0px;
   }
   .preferences-content-section {
      &.mobile-css {
         .preferences-right-section {
            padding-left: 0px;
            padding-right: 0px;
         }
         .switch-header-content {
            padding: 15px 0px 0px 30px;
         }
      }
      .preferences-left-section {
         display: flex;
         flex-direction: column;
         justify-content: space-evenly;
         align-items: center;

         .preferences-img-css {
            width: 215px;
         }

         .preferences-img-content {
            font-weight: bold;
            font-size: 12px;
            text-align: center;
         }
      }
      .preferences-right-section {
         display: flex;
         flex-direction: column;
         gap: 15px;
         font-size: 12px;
         .unsubscribe-hr-css {
            margin-top: 30px;
            margin-bottom: 20px;
            border-top: 2px solid ${PRIMARY_COLORS.COOL_GREY};
         }
         .preferences-img-mobile-content {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            margin-top: 40px;
            font-size: 14px;
            text-align: center;
         }
      }
   }
`;

export const PreferencesFooter = styled(Footer)`
   z-index: 99;
   position: fixed;
   bottom: 0;
   background-color: white;
`;
