import styled from "styled-components";
import { PRIMARY_COLORS } from "../../../../consts/colors";

export const DonorCardDiv = styled.div`
   border-radius: 13px;
   height: 100%;
   display: flex;
   flex-direction: column;

   background-color: white !important;
   box-shadow: 10px 3px 15px 0px rgb(0 0 0 / 28%);
   .header-css {
      display: flex;
      gap: 20px;
      align-items: center;
      padding: 10px 20px 5px 20px;
      justify-content: space-between;

      border-bottom: 1px solid ${PRIMARY_COLORS.GREY_CLOUD};
      .logo-css {
         width: 60px;
         height: 25px;
      }
      .logo-section-css {
         display: flex;
         flex-direction: column;
         gap: 0px;
      }
      .bci-css {
         font-size: 15px !important;
         font-weight: bold;
         color: ${PRIMARY_COLORS.DAVY_GREY};
      }
      .di-css {
         font-size: 11px !important;
         font-weight: bold;
         color: ${PRIMARY_COLORS.DAVY_GREY};
      }
   }
   .content-css {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      padding: 10px 20px 8px 22px;
      .content-name-section {
         display: flex;
         flex-direction: column;
         align-items: center;
         .name-css {
            font-size: 12px;
            font-weight: bold;
            color: ${PRIMARY_COLORS.DAVY_GREY};
         }
         .blood-type-css {
            font-size: 42px;
            font-weight: bold;
            color: ${PRIMARY_COLORS.DAVY_GREY};
         }
      }
      .content-qr-code-section {
         display: flex;
         flex-direction: column;
         align-items: center;
         .donor-id-css {
            font-size: 10px;
            font-weight: bold;
         }
      }
   }
`;
