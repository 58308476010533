import { IBloodDriveDataService } from "./IBloodDriveDataService";
import { BloodDriveDataService } from "./BloodDriveDataService";
import { getBexWISEResolutionServiceInstance } from "../bexWISEResolutionService/getBexWISEResolutionService";
import { getCognitoService } from "../../cognitoService/getCognitoService";
import { ICognitoSignUpParams } from "../../cognitoService/ICognitoSignUpParams";

let instance: IBloodDriveDataService | null = null;

export function getBloodDriveDataServiceInstance(): IBloodDriveDataService {
   if (instance === null) {
      instance = new BloodDriveDataService(
         getBexWISEResolutionServiceInstance(),
         getCognitoService<ICognitoSignUpParams>(),
      );
   }
   return instance;
}
