import { IAppointment } from "types/IAppointment";
import { format } from "date-fns";

export const isCurrentDateEqualToPreviousDate = (
   appointmentTime: string | Date,
   index: number,
   appointmentDetails: IAppointment[],
) => {
   const currentDate: string = format(new Date(appointmentTime), "yyyy");
   const prevDate: string | false =
      index !== 0 &&
      format(
         new Date(appointmentDetails[index - 1].appointmentDateTime),
         "yyyy",
      );
   return currentDate === prevDate;
};
