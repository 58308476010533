import React from "react";
import { Button, HelperText, Div } from "./styled";
import { ReactComponent as CheckIcon } from "../../../../../../../assets/svgs/check.svg";

interface RangeDatePickerHeaderProps {
   isCustomDateSelected: boolean;
   getCustomDates: () => void;
}
const RangeDatePickerHeader: React.FC<RangeDatePickerHeaderProps> = ({
   isCustomDateSelected,
   getCustomDates,
}) => {
   return (
      <>
         {isCustomDateSelected ? (
            <Div className="d-flex justify-content-between align-items-center w-100">
               <HelperText> - Choose My Dates</HelperText>
               {isCustomDateSelected && <CheckIcon fill="red" />}
            </Div>
         ) : (
            <Button type="button" onClick={getCustomDates} className="w-100">
               <span>+ Choose My Dates</span>
            </Button>
         )}
      </>
   );
};

export default RangeDatePickerHeader;
