import styled from "styled-components";
import { PRIMARY_COLORS } from "../../../../consts/colors";
import { ReactComponent as LockIcon } from "../../../../../assets/svgs/lock.svg";

export const ChangePwdSection = styled.div`
   background-color: white !important;
   box-shadow: 10px 3px 15px 0px rgb(0 0 0 / 28%);
   padding: 5px;
   display: flex;
   flex-grow: 1;
   align-items: center;
   height: 100%;
   .profile-img-css {
      width: 200px;
   }
   .change-password-button-container {
      flex-grow: 1;
      text-align: center;
   }
   .change-password-button {
      font-size: 10px;
      background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED} !important;
      border-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED} !important;
      align-items: center;
   }
`;

export const LockImg = styled(LockIcon)`
   width: 14px;
   height: 14px;
   fill: white;
   margin-left: 10px;
`;
