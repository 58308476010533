import React from "react";
import HorizontalLine from "./HorizontalLine";
import MyDonationDesktopSlot from "./MyDonationDesktopSlot";
import { isCurrentDateEqualToPreviousDate } from "../../utils/utils";
import { IDonorDonationDTO } from "services/bexWISE/donorDataService/IDonorDataService";

interface MyDonationDesktopProps {
   donation: IDonorDonationDTO;
   donations: IDonorDonationDTO[];
   index: number;
}

export const MyDonationDesktop: React.FC<MyDonationDesktopProps> = React.memo(
   ({ donation, donations, index }) => {
      return (
         <div className="d-flex">
            {Boolean(
               isCurrentDateEqualToPreviousDate(
                  donation.date,
                  index,
                  donations,
               ),
            ) ? (
               <HorizontalLine>
                  <div className="d-flex w-100 mt-3">
                     <MyDonationDesktopSlot donation={donation} />
                  </div>
               </HorizontalLine>
            ) : (
               <div className="d-flex w-100 mt-4">
                  <HorizontalLine date={donation.date}>
                     <MyDonationDesktopSlot donation={donation} />
                  </HorizontalLine>
               </div>
            )}
         </div>
      );
   },
);
