import { Card } from "react-bootstrap";
import styled from "styled-components";
import { PRIMARY_COLORS } from "../../../../consts/colors";

interface StyledCardProps {
   disabled: boolean;
   $selectedDriveId: string;
}

export const Wrapper = styled("div")`
   height: 600px;
   display: flex;
   flex-direction: column;
   gap: 20px;
   padding: 2px;
   overflow-y: auto;
   &::-webkit-scrollbar {
      display: none;
   }
`;

export const StyledCard = styled(Card)<StyledCardProps>`
   display: flex;
   padding: 16px;
   transition: all 0.3s;
   border: 1px solid #dadce0;
   background-color: ${(props: { disabled: boolean; $selectedDriveId: string; }) => (props.disabled ? "ghostwhite" : "unset")};
   opacity: ${(props: { disabled: boolean; $selectedDriveId: string }) => props.disabled ? "0.4" : "1"};
   pointer-events: ${(props: { disabled: boolean; $selectedDriveId: string; }) => (props.disabled ? "none" : "auto")};
   border-width: 1px;
   box-shadow: ${props => props.$selectedDriveId === props.id ? `0 0 0px 2px ${PRIMARY_COLORS.CRYSTAL_BLUE}` : "transparent"};
   
   &:hover, &:focus {
    -webkit-box-shadow: 0px 1px 2px 0px rgba(60,64,67,.30),0px 2px 6px 2px rgba(60,64,67,.15);
    box-shadow: 0px 1px 2px 0px rgba(60,64,67,.30),0px 2px 6px 2px rgba(60,64,67,.15)
}
`;

export const Header = styled("div")`
   display: flex;
   justify-content: space-between;
   align-items: center;
`;

export const HorizontalDivider = styled("div")`
   border-right: 2px solid gray;
   margin-right: 14px;
`;

export const Body = styled("div")`
   display: flex;
   justify-content: space-between;
   margin-top: 14px;
   align-items: center;

   svg {
      scale: 1.8;
      margin-right: 30px;
   }
`;

export const Title = styled.h5`
   font-size: 14px;
   font-weight: bold;
`;

export const SubTitle = styled.p`
   font-size: 13px!important;
`;

export const Footer = styled("div")`
   display: flex;
   align-items: center;
   margin-top: 10px;

   .font13 {
      font-size:13px!important;
   }
`;

export const SeparatorContainer = styled("div")`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 34px;
   color: ${PRIMARY_COLORS.CADMIUM_RED};
   border-radius: 8px;
   background: ${PRIMARY_COLORS.AQUA_HAZE};
   font-weight: 600;
   margin-bottom: 5px;
   border: 1px solid ${PRIMARY_COLORS.GREEN_WHITE};
   font-size: 15px;
`;

const getTextColor = total => {
   if (total <= 5) {
      return `${PRIMARY_COLORS.FIRE_ENGINE_RED}`;
   }
   if (total <= 10) {
      return `${PRIMARY_COLORS.IRISH_GREEN}`;
   }
   return `${PRIMARY_COLORS.DAVY_GREY}`;
};

export const Span = styled.span<{ total: number }>`
   color: ${props => getTextColor(props.total)};
   font-weight: 700;
`;
