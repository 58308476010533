import React from "react";
import ItsyLogo from "assets/images/itsy-logo.png";

import { LoaderWrapper, StyledSpinner } from "./styled";
import { Image } from "react-bootstrap";

const FullScreenLoader = () => {
   return (
      <LoaderWrapper>
         <StyledSpinner animation="border" />
         <Image src={ItsyLogo} height="60px" width="160px" />
      </LoaderWrapper>
   );
};

export default FullScreenLoader;
