import React from "react";
import { isCurrentDateEqualToPreviousDate } from "utils/isCurrentDateEqualPreviousDate";
import HorizontalLine from "../HorizontalLine";
import FutureAppointmentDesktopSlot from "./FutureAppointmentDesktopSlot";
import { IAppointment } from "types/IAppointment";

interface FutureAppointmentDesktopProps {
   appointment: IAppointment;
   appointments: IAppointment[];
   index: number;
   setTriggerAppointmentPageCall: (_: boolean) => void;
}
export const FutureAppointmentDesktop: React.FC<FutureAppointmentDesktopProps> =
   React.memo(
      ({ appointment, appointments, index, setTriggerAppointmentPageCall }) => {
         return (
            <div className="d-flex">
               {Boolean(
                  isCurrentDateEqualToPreviousDate(
                     appointment.appointmentDateTime,
                     index,
                     appointments,
                  ),
               ) ? (
                  <HorizontalLine>
                     <div className="d-flex w-100 mt-3">
                        <FutureAppointmentDesktopSlot
                           appointment={appointment}
                           setTriggerAppointmentPageCall={
                              setTriggerAppointmentPageCall
                           }
                        />
                     </div>
                  </HorizontalLine>
               ) : (
                  <div className="d-flex w-100 mt-4">
                     <HorizontalLine
                        appointmentTime={appointment.appointmentDateTime}
                     >
                        <FutureAppointmentDesktopSlot
                           appointment={appointment}
                           setTriggerAppointmentPageCall={
                              setTriggerAppointmentPageCall
                           }
                        />
                     </HorizontalLine>
                  </div>
               )}
            </div>
         );
      },
   );
