import styled from "styled-components";
import { PRIMARY_COLORS } from "app/consts/colors";

export const StyledSlot = styled.div<{ statusColor: string | undefined }>`
   width: 100%;
   background-color: ${PRIMARY_COLORS.WHITE};
   margin-bottom: 10px;

   .wrapper {
      width: 100%;
      display: flex;

      .date {
         width: 90px;
         background-color: ${PRIMARY_COLORS.AQUA_HAZE};
         border-radius: 3px;
         padding: 4px;
         border: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;

         p {
            font-size: 20px !important;
            font-weight: bold;
         }

         p.month {
            text-transform: uppercase;
            font-size: 10pt !important;
         }
         p.day {
            font-size: 16pt !important;
         }
      }

      .details-section {
         width: calc(100% - 90px);
         border-radius: 5px;
         border: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
         padding: 10px 20px;
         border-top: 5px solid
            ${props => (props.statusColor ? props.statusColor : "black")};

         .title {
            display: flex;
            align-items: center;
            gap: 8px;

            img {
               width: 20px;
               height: auto;
            }

            span {
               font-size: 14px !important;
               font-weight: bold;
            }
         }

         .sub-title {
            font-size: 12px !important;
            margin: 10px 0px;
         }
         

         .bottom-section {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .sub-title {
               padding: 0;
               margin: 0;
               font-size: 13px !important;
            }
            .status {
               font-weight: bold;
               color: ${props => props.statusColor};
            }
         }
      }
   }
`;
