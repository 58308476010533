import React from "react";
import { Stack } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CommonHeader from "../../../../components/CommonHeader";
import Row from "react-bootstrap/Row";
import LabelContentComponent from "../LabelContentComponent";
import {
   LockImg,
   MobileDemoGraphicContentDiv,
   MobilePersonalContentDiv,
   PersonImg,
} from "./styled";
import { US_STATES } from "../../../../consts/usStates";
import DonorCardModal from "../DonorCardModal";
import DonationEligibilityTable from "../DonationEligibilityTable";
import { IDonorDTO } from "../../../../../types/IDonorDTO";
import { IDonorEligibilityDate } from "../../../../../services/bexWISE/donorDataService/IDonorDataService";
import { Section } from "../../../../components/Section/Section";

interface DisplayDataInterface {
   label: string;
   value: string;
   id: string;
}

export interface ProfileMobileViewProps {
   isMobile: boolean;
   profileData: IDonorDTO;
   eligibilityDates: IDonorEligibilityDate[];
   setModalShow: Function;
   fullName: string;
   dateDisplay: string;
}
const ProfileMobileView: React.FC<ProfileMobileViewProps> = ({
   isMobile,
   profileData,
   eligibilityDates,
   setModalShow,
   fullName,
   dateDisplay,
}) => {
   const [showDonorModal, setDonorModalShow] = React.useState<boolean>(false);
   const displayPersonalData: DisplayDataInterface[] = [
      {
         label: "First Name Last Name:",
         value: fullName,
         id: "fullName",
      },
      {
         label: "DOB:",
         value: dateDisplay,
         id: "dob",
      },
      {
         label: "Sex:",
         value: profileData?.sex?.description,
         id: "sex",
      },
      {
         label: "Blood Type:",
         // SCREDP-75: Only show the blood type symbol if the blood type is not null
         value: profileData?.bloodType?.symbol,
         id: "bloodType",
      },
      {
         label: "CMV Status:",
         // SCREDP-75: Show the correct CMV value in mobile view
         value: profileData?.cmvNegative,
         id: "cmvStatus",
      },
      {
         label: "Race:",
         value: profileData?.race?.description,
         id: "race",
      },
   ];
   const displayDemographicData: DisplayDataInterface[] = [
      {
         label: "Street Address(Line 1)",
         value: profileData.address,
         id: "addressOne",
      },
      {
         label: "Street Address(Line 2)",
         value: profileData?.address2 || "",
         id: "addressTwo",
      },
      {
         label: "City",
         value: profileData.city,
         id: "city",
      },
      {
         label: "State",
         value:
            US_STATES.find(
               stateVal => stateVal.abbreviation === profileData.state,
            )?.name || profileData.state,
         id: "state",
      },
      {
         label: "Zip Code",
         value: profileData.zipCode,
         id: "zipCode",
      },
      {
         label: "Home Phone",
         value: profileData.homePhone,
         id: "homePhone",
      },
      {
         label: "Cell Phone",
         value: profileData.cellPhone,
         id: "cellPhone",
      },
      {
         label: "Business Phone",
         value: profileData.businessPhone,
         id: "businessPhone",
      },
      {
         label: "Home Email",
         value: profileData.email,
         id: "homeEmail",
      },
      {
         label: "Work Email",
         value: profileData.workEmail,
         id: "workEmail",
      },
   ];
   return (
      <Stack gap={3}>
         <Section className="personal-card-css">
            <CommonHeader title="PERSONAL INFORMATION" />
            <MobilePersonalContentDiv>
               <Button
                  className="button-css"
                  onClick={() => setDonorModalShow(true)}
               >
                  Show Donor Identification <PersonImg />
               </Button>
               <hr className="line-css" />
               {displayPersonalData.map(eachSection => (
                  <React.Fragment key={eachSection.id}>
                     <Row className="row-css">
                        <LabelContentComponent
                           label={eachSection.label}
                           value={eachSection.value}
                        />
                     </Row>
                     <hr className="line-css" />
                  </React.Fragment>
               ))}
               <Button
                  className="button-css"
                  onClick={() => setModalShow(true)}
               >
                  Change Password <LockImg />
               </Button>
            </MobilePersonalContentDiv>
         </Section>
         <DonationEligibilityTable
            eligibilityDates={eligibilityDates}
            isMobile={isMobile}
         />
         <Section className="personal-card-css">
            <CommonHeader title="DEMOGRAPHIC INFORMATION" />
            <MobileDemoGraphicContentDiv>
               {displayDemographicData.map(eachSection => (
                  <React.Fragment key={eachSection.id}>
                     <Row className="row-css">
                        <LabelContentComponent
                           label={eachSection.label}
                           value={eachSection.value}
                        />
                     </Row>
                     <hr className="line-css" />
                  </React.Fragment>
               ))}
            </MobileDemoGraphicContentDiv>
         </Section>
         <DonorCardModal
            showDonorModal={showDonorModal}
            setDonorModalShow={setDonorModalShow}
            fullName={fullName}
            // SCREDP-75: Only show the blood type symbol if the blood type is not null
            bloodType={profileData?.bloodType?.symbol}
         />
      </Stack>
   );
};

export default ProfileMobileView;
