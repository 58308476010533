import React from "react";
import PhysicalExamGraphImg from "../../../../../../assets/images/physicalexam-graph.png";
import Image from "react-bootstrap/Image";
import { Col } from "react-bootstrap";
import { MeasureRow } from "./styled";
import {
   IDonorDonationDTO,
   IPhysicalExamData,
} from "../../../../../../services/bexWISE/donorDataService/IDonorDataService";

interface MeasureComponentInterface {
   label: string;
   val: string;
   selectedDate: string;
   visibleData: IDonorDonationDTO[];
}
const MeasureComponent: React.FC<MeasureComponentInterface> = ({
   label,
   val,
   selectedDate,
   visibleData,
}) => {
   const displayVal = (
      eachDonation: IDonorDonationDTO,
      val: string,
   ): string => {
      const physicalExamResult: IPhysicalExamData = eachDonation.physicalExam;
      if (val === "bloodPressure") {
         return `${physicalExamResult.firstReading.bloodPressureSystolic}/${physicalExamResult.firstReading.bloodPressureDiastolic}`;
      }
      return physicalExamResult[val] || physicalExamResult.firstReading[val];
   };

   const DisplayItems = ({ eachDonation, val }): JSX.Element => {
      const isSelected: boolean = eachDonation.date === selectedDate;
      return (
         <div className={isSelected ? "selected-result" : "unselected-result"}>
            <div className={isSelected ? "" : "each-result-section"}>
               {displayVal(eachDonation, val)}
            </div>
         </div>
      );
   };

   return (
      <MeasureRow>
         <Col lg={3} className="label-section-css">
            <Image
               src={PhysicalExamGraphImg}
               fluid
               alt="graph-physical-exam-page-img"
               className="graph-img-css"
            />
            <span className="label-span-css">{label}</span>
         </Col>
         <Col lg={9} className="value-section-css">
            <div className="arrow-section"></div>
            {visibleData.map(eachDonation => (
               <React.Fragment key={eachDonation.date}>
                  <DisplayItems eachDonation={eachDonation} val={val} />
               </React.Fragment>
            ))}
            <div className="arrow-section"></div>
         </Col>
      </MeasureRow>
   );
};

export default MeasureComponent;
