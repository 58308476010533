import { createEvent } from "ics";
import { IEventInterface } from "./IEventInterface";

export async function handleCalendarDownload(event: IEventInterface) {
   const filename: string = "AppointmentConfirmation.ics";
   const file: Blob = await new Promise((resolve, reject) => {
      createEvent(event, (error, value) => {
         if (error) {
            reject(error);
         }
         resolve(new File([value], filename, { type: "plain/text" }));
      });
   });
   if (file) {
      const url: string = URL.createObjectURL(file);
      const anchor: HTMLAnchorElement = document.createElement("a");
      anchor.href = url;
      anchor.download = filename;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      URL.revokeObjectURL(url);
   }
}
