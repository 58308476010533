import React from "react";
import { Col, Row } from "react-bootstrap";
import { HistoricalResultsDropdown } from "./HistoricalResultsDropdown";
import {
   ArrowLeftImg,
   ArrowRightImg,
   ChevronIcon,
   HistoricalDropdownRow,
} from "./styled";
import { IDonorDonationDTO } from "../../../../../../../services/bexWISE/donorDataService/IDonorDataService";
import { displayDate } from "../../../../utils/utils";

interface HistoricalResultsDropdownSectionProps {
   yearsValues: number[];
   selectedYear: number;
   setSelectedYear: Function;
   displayDonorData: IDonorDonationDTO[];
   selectedDate: string;
   setSelectedDate: Function;
   visibleData: IDonorDonationDTO[];
   setStartIndex: Function;
   startIndex: number;
}
const HistoricalResultsDropdownSection: React.FC<
   HistoricalResultsDropdownSectionProps
> = ({
   yearsValues,
   selectedYear,
   setSelectedYear,
   displayDonorData,
   selectedDate,
   setSelectedDate,
   visibleData,
   setStartIndex,
   startIndex,
}) => {
   const DisplayItems = ({ eachDonation }): JSX.Element => {
      const isSelected: boolean = eachDonation.date === selectedDate;
      return (
         <div
            className={isSelected ? "selected-result" : "unselected-result"}
            onClick={() => setSelectedDate(eachDonation.date)}
         >
            {isSelected && <ChevronIcon />}
            <div className={isSelected ? "" : "each-result-section"}>
               {displayDate(eachDonation.date, false)}
            </div>
         </div>
      );
   };
   const handleDropDownChange = (val: number): void => {
      setSelectedYear(val);
      setStartIndex(0);
   };

   return (
      <HistoricalDropdownRow as={Row}>
         <Col lg={3} className="year-dropdown-section-css">
            <span className="label-css">Historical Results</span>
            <HistoricalResultsDropdown
               list={yearsValues}
               name={"year dropdown"}
               value={selectedYear}
               handleChange={handleDropDownChange}
            />
         </Col>
         <Col lg={9} className="value-section-css">
            {startIndex !== 0 ? (
               <div
                  className="arrow-section"
                  onClick={() => setStartIndex(startIndex - 9)}
               >
                  <ArrowLeftImg />
               </div>
            ) : (
               <div className="no-arrow-section"></div>
            )}
            {visibleData.map(eachDonation => (
               <React.Fragment key={eachDonation.date}>
                  <DisplayItems eachDonation={eachDonation} />
               </React.Fragment>
            ))}
            {displayDonorData.length > 9 && visibleData.length === 9 && (
               <div
                  className="arrow-section"
                  onClick={() => setStartIndex(startIndex + 9)}
               >
                  <ArrowRightImg />
               </div>
            )}
         </Col>
      </HistoricalDropdownRow>
   );
};

export default HistoricalResultsDropdownSection;
