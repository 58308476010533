import React from "react";
import { FormSelect as FormSelectOptions } from "./styled";

export interface FormSelectProps {
   list: number[];
   name: string;
   value: number;
   handleChange: Function;
}

export const HistoricalResultsDropdown: React.FC<FormSelectProps> = ({
   list,
   name,
   value,
   handleChange,
}) => {
   return (
      <>
         <FormSelectOptions
            name={name}
            value={value}
            onChange={e => handleChange(e.target.value)}
         >
            {list.map(item => {
               return (
                  <option key={item} value={item}>
                     {item}
                  </option>
               );
            })}
         </FormSelectOptions>
      </>
   );
};
