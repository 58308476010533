import React, { useContext, useEffect, useState } from "react";
import { PageComponent } from "../../components/PageComponent";
import { useMediaQuery } from "react-responsive";
import { UserContext } from "../../contexts/user/userContext";
import { ProfileContainer } from "./styled";
import ProfileMobileView from "./components/ProfileMobileView";
import ProfileDesktopView from "./components/ProfileDesktopView";
import SignInModal from "../../components/AuthenticationModals/SignInModal";
import { ModalPageNames } from "../../consts";

function formatDate(date: Date): string {
   const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      year: "numeric",
   };

   // Correctly parse the Date value for display (AEP-56)
   return date.toLocaleDateString("en-US", options);
}

const ProfilePage = () => {
   const isMobile: boolean = useMediaQuery({ maxWidth: 1000 });
   const [modalShow, setModalShow] = React.useState<boolean>(false);
   const [fullName, setFullName] = useState<string>("");
   const [dateDisplay, setDateDisplay] = useState<string>("");
   const { profileData, eligibilityDates } = useContext(UserContext);

   useEffect(() => {
      if (profileData?.firstName && profileData?.lastName) {
         setFullName(`${profileData.firstName} ${profileData.lastName}`);
      }
      if (profileData?.birthDate) {
         // Interpret the birthDate value as a local date (AEP-56)
         setDateDisplay(formatDate(new Date(profileData.birthDate + "T00:00")));
      }
   }, [profileData]);

   return (
      <PageComponent
         name={"Profile Page"}
         title={"Profile Page"}
         description={"Profile Page"}
      >
         <>
            {profileData &&
               eligibilityDates &&
               Array.isArray(eligibilityDates) && (
                  <ProfileContainer className={isMobile ? "mobile-css mt-3" : "mt-3"}>
                     {isMobile ? (
                        <ProfileMobileView
                           isMobile={isMobile}
                           profileData={profileData}
                           eligibilityDates={eligibilityDates}
                           setModalShow={setModalShow}
                           fullName={fullName}
                           dateDisplay={dateDisplay}
                        />
                     ) : (
                        <ProfileDesktopView
                           isMobile={isMobile}
                           profileData={profileData}
                           eligibilityDates={eligibilityDates}
                           modalShow={modalShow}
                           setModalShow={setModalShow}
                           fullName={fullName}
                           dateDisplay={dateDisplay}
                        />
                     )}
                     <SignInModal
                        show={modalShow}
                        setModalShow={setModalShow}
                        pageNameSent={ModalPageNames.CHANGE_PASSWORD}
                     />
                  </ProfileContainer>
               )}
         </>
      </PageComponent>
   );
};

export default ProfilePage;
