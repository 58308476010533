import React from "react";

import { ReactComponent as MenuIcon } from "assets/svgs/menu.svg";
import { ReactComponent as FilterIcon } from "assets/svgs/filter.svg";

import { ButtonContainer, MapButtons } from "./styled";
import { MobileLayoutSteps } from "app/consts";
import Map from "app/components/OpportunitySearchComponent/components/OpportunityMap/Map";

interface MobileMapView {
   setLayout: (arg: MobileLayoutSteps) => void;
}
const MobileMapView: React.FC<MobileMapView> = ({ setLayout }) => {
   return (
      <>
         <Map />

         <ButtonContainer>
            <MapButtons
               onClick={() =>
                  setLayout(MobileLayoutSteps.MOBILE_LIST_VIEW_WITH_FILTERS)
               }
            >
               <MenuIcon />
               View List
            </MapButtons>
            <MapButtons
               onClick={() =>
                  setLayout(MobileLayoutSteps.MOBILE_MAP_VIEW_WITH_FILTERS)
               }
            >
               <FilterIcon />
               Filters
            </MapButtons>
         </ButtonContainer>
      </>
   );
};

export default MobileMapView;
