import React from "react";
import { ReactComponent as ArrowIcon } from "../../../../../assets/svgs/forward-arrow.svg";
import { Body, SubTitle, Title } from "./styled";
import { IBloodDrive } from "types/IBloodDrive";

interface CardBodyProps {
   details: IBloodDrive;
}

const CardBody: React.FC<CardBodyProps> = ({ details }) => {
   const isAccountNameSameAsLocationName = details?.location?.name === details?.account?.accountName;
   return (
      <Body>
         <div>
            <Title>{details.account.accountName}</Title>
            <SubTitle>
               {/* If the account name and the location are the same, only show the account name (SCREDP-63) */}
               {!isAccountNameSameAsLocationName && (<>
                  {details?.location?.name || ""}<br />
               </>)}
               {details?.location?.addressOne || ""}
               {details?.location?.addressTwo &&
                  <>
                     {/* Removed "," and "$" symbol (SCREDP-96) */}
                     <br /> {details?.location?.addressTwo}
                  </>
               }
               <br />
               {details?.location?.city || ""}, {details?.location?.state || ""}{" "}
               {details?.location?.zipCode || ""}
            </SubTitle>
         </div>
         <ArrowIcon fill="gray" />
      </Body>
   );
};

export default CardBody;
