import { createContext } from "react";

export interface ILayoutContext {
   isNavBarExpanded: boolean;
   setIsNavBarExpanded: (expanded: boolean) => void;
   isSignedIn: boolean;
   isMobile: boolean;
}

const defaultLayoutContext: ILayoutContext = {
   isNavBarExpanded: false,
   setIsNavBarExpanded: (expanded: boolean) => {
      return null;
   },
   isSignedIn: false,
   isMobile: false,
};

export const LayoutContext =
   createContext<ILayoutContext>(defaultLayoutContext);
