import React from "react";
import { PageComponent } from "../../components/PageComponent";
import { MY_APPOINTMENTS_PAGE_TITLE } from "./consts";
import FutureAppointments from "./components/FutureAppointments";
import PastAppointmentsSection from "./components/PastAppointmentsSection";
import ScheduleDonation from "./components/ScheduleDonation";
import {
   MyAppointmentsPageContext,
   MyAppointmentsPageContextProvider,
} from "./context/MyAppointmentsPageContext";
import { FilteredPastAppointmentsContextProvider } from "./components/PastAppointmentsSection/context/FilteredPastAppointmentsContext";
import { SectionContainer } from "../../components/Section/SectionContainer";

const MyAppointmentPage = () => {
   return (
      <PageComponent
         name={"My Appointments Page"}
         title={MY_APPOINTMENTS_PAGE_TITLE}
         description={"My Appointments Page"}
      >
         <SectionContainer>
            <MyAppointmentsPageContextProvider>
               <FutureAppointments />
               <FilteredPastAppointmentsContextProvider>
                  <PastAppointmentsSection />
               </FilteredPastAppointmentsContextProvider>
               <MyAppointmentsPageContext.Consumer>
                  {context =>
                     context.pastAppointments?.length === 0 && (
                        <ScheduleDonation />
                     )
                  }
               </MyAppointmentsPageContext.Consumer>
            </MyAppointmentsPageContextProvider>
         </SectionContainer>
      </PageComponent>
   );
};

export default MyAppointmentPage;
