import React from "react";
import { IDonorDonationDTO } from "../../../../../services/bexWISE/donorDataService/IDonorDataService";
import { IDonorDTO } from "../../../../../types/IDonorDTO";
import ProfileSection from "./ProfileSection";
import HistoricalResultsSection from "./HistoricalResultsSection";
import { Col, Row, Stack } from "react-bootstrap";
import LocationSection from "./LocationSection";

interface PhysicalExamDesktopProps {
   profileData: IDonorDTO;
   displayDonorData: IDonorDonationDTO[];
   yearsValues: number[];
   selectedYear: number;
   setSelectedYear: Function;
   selectedDate: string;
   setSelectedDate: Function;
   lastDonation: IDonorDonationDTO;
}

const PhysicalExamsDesktopView: React.FC<PhysicalExamDesktopProps> = ({
   profileData,
   displayDonorData,
   yearsValues,
   selectedYear,
   setSelectedYear,
   selectedDate,
   setSelectedDate,
   lastDonation,
}) => {
   return (
      <div>
         <Stack gap={2}>
            <Row>
               <Col sm={9}>
                  <ProfileSection
                     profileData={profileData}
                     weight={lastDonation.physicalExam.weight}
                     height={lastDonation.physicalExam.height}
                  />
               </Col>
               <Col sm={3}>
                  <LocationSection lastDonorData={lastDonation} />
               </Col>
            </Row>

            <HistoricalResultsSection
               yearsValues={yearsValues}
               selectedYear={selectedYear}
               setSelectedYear={setSelectedYear}
               selectedDate={selectedDate}
               setSelectedDate={setSelectedDate}
               displayDonorData={displayDonorData}
            />
         </Stack>
      </div>
   );
};

export default PhysicalExamsDesktopView;
