import React, {
   createContext,
   ReactElement,
   useContext,
   useEffect,
   useState,
} from "react";
import { IAppointment } from "../../../../types/IAppointment";
import { AuthContext } from "../../../contexts/auth/authContext";
import { getAppointmentDataServiceInstance } from "../../../../services/bexWISE/appointmentDataService/getAppointmentDataServiceInstance";
import _ from "lodash";

export interface IAppointmentTypesInterface {
   label: string;
   value: string;
}

export interface IMyAppointmentsPageContext {
   appointments: IAppointment[] | null;
   pastAppointments: IAppointment[] | null;
   futureAppointments: IAppointment[] | null;
   triggerUpdate: () => void;
   uniqueAppointmentTypes: IAppointmentTypesInterface[] | null;
}

const defaultMyAppointmentsPageContext: IMyAppointmentsPageContext = {
   appointments: null,
   pastAppointments: null,
   futureAppointments: null,
   uniqueAppointmentTypes: null,
   triggerUpdate: () => {
      console.error("Update called before context was initialized");
   },
};

export const MyAppointmentsPageContext =
   createContext<IMyAppointmentsPageContext>(defaultMyAppointmentsPageContext);

export interface IMyAppointmentsPageContextProviderProps {
   children: React.ReactElement | ReactElement[];
}

function isPastAppointment(appointment: IAppointment): boolean {
   return (
      new Date(appointment.appointmentDateTime).getTime() <=
         new Date().getTime() || appointment.outcome === "Canceled"
   );
}

function isFutureAppointment(appointment: IAppointment): boolean {
   return (
      new Date(appointment.appointmentDateTime).getTime() >
         new Date().getTime() && appointment.outcome !== "Canceled"
   );
}

export function MyAppointmentsPageContextProvider(
   props: IMyAppointmentsPageContextProviderProps,
): ReactElement {
   const [context, setContext] = useState<IMyAppointmentsPageContext>(
      defaultMyAppointmentsPageContext,
   );
   const { userDonorId } = useContext(AuthContext);

   const updateAppointments = (donorId: string): void => {
      getAppointmentDataServiceInstance()
         .getDonorAppointments(donorId)
         .then((fetchedAppointments: IAppointment[]) => {
            setContext({
               appointments: fetchedAppointments,
               pastAppointments: fetchedAppointments.filter(isPastAppointment),
               futureAppointments:
                  fetchedAppointments.filter(isFutureAppointment),
               uniqueAppointmentTypes: _(fetchedAppointments)
                  .uniqBy("appointmentType")
                  .map(
                     ({
                        appointmentType,
                        appointmentTypeDescription,
                     }: IAppointment) => ({
                        label: appointmentType,
                        value: appointmentTypeDescription,
                     }),
                  )
                  .value(),
               triggerUpdate: () => updateAppointments(donorId),
            });
         });
   };

   useEffect(() => {
      if (userDonorId) {
         updateAppointments(userDonorId);
      }
   }, [userDonorId]);

   return (
      <MyAppointmentsPageContext.Provider value={context}>
         {props.children}
      </MyAppointmentsPageContext.Provider>
   );
}
