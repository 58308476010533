import { IDonorDataService } from "./IDonorDataService";
import { DonorDataService } from "./DonorDataService";
import { getBexWISEResolutionServiceInstance } from "../bexWISEResolutionService/getBexWISEResolutionService";
import { getCognitoService } from "../../cognitoService/getCognitoService";

let instance: IDonorDataService | null = null;

export function getDonorDataServiceInstance(): IDonorDataService {
   if (instance === null) {
      instance = new DonorDataService(
         getBexWISEResolutionServiceInstance(),
         getCognitoService(),
      );
   }
   return instance;
}
