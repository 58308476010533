import React, { ReactElement, useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ModalPageNames } from "../../../consts";
import { SignNewAptContainer } from "./styled";
import { EnvironmentContext } from "../../../contexts/environment/environmentContext";

export interface ISignInOrNewAppointmentModalBodyProps {
   isMobile: boolean;
   setPageName: (pageName: ModalPageNames) => void;
}

export function SignInOrNewAppointmentModalBody({
   isMobile,
   setPageName,
}: ISignInOrNewAppointmentModalBodyProps): ReactElement {
   const { centerData } = useContext(EnvironmentContext);

   return (
      <SignNewAptContainer className={isMobile ? "mobile-css" : "desktop-css"}>
         <Row className="mb-4 section-css section-mobile-css">
            <Col md={6} className="text-section-css">
               I am an existing donor!
            </Col>
            <Col md={6}>
               <Button
                  variant="danger"
                  size="sm"
                  onClick={() => setPageName(ModalPageNames.SIGN_IN)}
                  className="button-padding"
               >
                  Sign In / Create an Account
               </Button>
            </Col>
         </Row>
         <Row className="mb-4 section-css">
            <Col sm={5} xs={5}>
               <hr></hr>
            </Col>
            <Col sm={2} xs={2} className="text-alignment">
               or
            </Col>
            <Col sm={5} xs={5}>
               <hr></hr>
            </Col>
         </Row>
         <Row className="mb-3 section-css">
            <Col className="text-section-css" md={6}>
               I am a new blood donor at
               <br />
               {/* Changed bank name to Center's name (AEP-67) */}
               <b>{centerData?.name}</b>
            </Col>
            <Col md={6}>
               <Button
                  variant="danger"
                  size="sm"
                  onClick={() =>
                     setPageName(ModalPageNames.REQUEST_APPOINTMENT)
                  }
                  className="button-padding"
               >
                  Request Appointment
               </Button>
            </Col>
         </Row>
      </SignNewAptContainer>
   );
}
