import styled from "styled-components";
import { PRIMARY_COLORS } from "../../../../consts/colors";
import { ReactComponent as ClipBoardImg } from "../../../../../assets/svgs/clipboard.svg";

export const PhysicalMobileDiv = styled.div`
   .profile-section {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-left: 20px;
      margin: 20px 0px;
      
      .middle-section {
         display: flex;
         flex-direction: column;
         font-weight: bold;

         .name-section {
            font-size: 14px;
         }

         .details-section {
            display: flex;
            font-size: 12px;
            gap: 20px;
         }
      }

      .btn-section {
         display: flex;
         justify-content: center;

         .btn-css {
            span {
               font-size: 12px !important;
            }

            padding: 6px 20px;
            background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED} !important;
            border-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED} !important;
            border-radius: 10px;
         }
      }
   }

   .location-section {
      .header-section {
         display: flex;
         align-items: center;
         gap: 20px;
         padding-left: 20px;
         .header-css {
            font-weight: bold;
            font-size: 14px;
         }
      }
      .content-css {
         font-size: 12px;
         padding-left: 20px;
      }
   }
   .dropdown-section-css {
      padding: 10px 10px 20px 20px;
      align-items: center;
      .label-css {
         font-size: 14px;
         font-weight: bold;
      }
   }
`;

export const ClipBoardIcon = styled(ClipBoardImg)`
   width: 30px;
   height: 30px;
   fill: gray;
`;
