import styled from "styled-components";
import { PRIMARY_COLORS } from "../../../../../consts/colors";

export const StyledSlot = styled.div`
   width: 100%;
   background-color: ${PRIMARY_COLORS.WHITE};
   margin-bottom: 10px;

   &:hover {
      .date {
         background-color: ${PRIMARY_COLORS.LINK_WATER} !important;
      }
      .wrapper,
      .actions {
         background-color: ${PRIMARY_COLORS.LILY_WHITE};
      }
   }

   .wrapper {
      width: 100%;
      display: flex;

      .date {
         width: 90px;
         background-color: ${PRIMARY_COLORS.AQUA_HAZE};
         border-radius: 3px;
         padding: 4px;
         border: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;

         p {
            font-size: 20px !important;
            font-weight: bold;
         }
          p.month {
            text-transform: uppercase;
            font-size: 10pt !important;
         }
         p.day {
            font-size: 16pt !important;
         }
      }

      .details-section {
         width: calc(100% - 90px);
         border-radius: 5px;
         border: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
         padding: 10px 20px;

         .title {
            display: flex;
            align-items: center;
            gap: 8px;

            img {
               width: 20px;
               height: auto;
            }

            span {
               font-size: 14px !important;
               font-weight: bold;
            }
            p{
               font-size: 13px !important;
               font-weight: bold;
            }
         }

         .sub-title {
            font-size: 13px !important;
            margin: 10px 0px;
            span{
               font-size: 12px !important;
            }
         }
       

         .bottom-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            img {
               width: 35px;
               height: auto;
               cursor: pointer;
               align-self: flex-end;
               position: relative;
            }
            p{
               font-size: 13px !important;
            }
         }
      }
   }
`;

export const StyledActions = styled.div<any>`
   width: 100% !important;
   border-radius: 3px;
   width: 340px;
   /* padding: 20px; */
   border: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
   display: flex;
   align-items: center;
   justify-content: space-around;
   gap: 20px;
   padding: ${props => (props.isMobile ? "10px" : "20px")};
   &:hover,
   &:hover {
      background-color: ${PRIMARY_COLORS.LILY_WHITE};
   }

   button {
      font-size: 13px;
      background-color: white;
      border-radius: 6px;
      padding: 7px 12px;
      border: 0.5px solid lightgrey;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #6c757d;
      font-weight: 500;
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
                  0 4px 8px 3px rgba(60, 64, 67, 0.15);
      img {
         width: 40px;
         height: auto;
      }
   }
`;
