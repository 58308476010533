import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { PRIMARY_COLORS } from "../../../../../consts/colors";

export const MapButtons = styled(Button)`
   color: ${PRIMARY_COLORS.PALE_SKY};
   font-weight: 700;
   border-radius: 12px;
   svg {
      padding-right: 5px;
      scale: 1.2;
      stroke: ${PRIMARY_COLORS.PALE_SKY};
   }
   &:hover {
      background-color: ${PRIMARY_COLORS.SOAP_STONE};
      border-color: 1px ${PRIMARY_COLORS.BRANDEIS_BLUE};
      color: ${PRIMARY_COLORS.PALE_SKY};
   }
`;
export const ButtonContainer = styled("div")`
   position: sticky;
   z-index: 2;
   right: 0;
   bottom: calc(20vh - 115px);
   width: 100%;
   display: flex;
   justify-content: space-between;
   padding: 10px 20px;

   button {
      padding: 3px 24px;
      border-radius: 8px;
      border: 0;
      font-size: 13px;
      background: ${PRIMARY_COLORS.SOAP_STONE};
      box-shadow: 0 1px 3px 0 rgba(60,64,67,.3), 0 4px 8px 3px rgba(60,64,67,.15);
   }
`;
