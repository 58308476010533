import { AbstractDonorDataService } from "./AbstractDonorDataService";
import { IDonorDTO } from "../../../types/IDonorDTO";
import {
   IDonorAppointmentDTO,
   IDonorContactPreference,
   IDonorDataService,
   IDonorDonationDTO,
   IDonorEligibilityDate,
   IDonorQRCodeInterface,
   IDonorValidationRequest,
} from "./IDonorDataService";

export class DonorDataService
   extends AbstractDonorDataService
   implements IDonorDataService
{
   public getDonor(donorId: string): Promise<IDonorDTO> {
      return this.getBex<IDonorDTO>(`donors/${donorId}`);
   }

   public validateDonor(
      validationRequest: IDonorValidationRequest,
   ): Promise<boolean> {
      return this.postBex<IDonorValidationRequest, boolean>(
         "donors/validate",
         validationRequest,
      );
   }

   public getDonorAppointment(
      donorId: string,
      appointmentId: string,
   ): Promise<IDonorAppointmentDTO> {
      return this.getBex<IDonorAppointmentDTO>(
         `donors/${donorId}/appointments/${appointmentId}`,
      );
   }

   public getDonorContactPreferences(
      donorId: string,
   ): Promise<IDonorContactPreference[]> {
      return this.getBex<IDonorContactPreference[]>(
         `donors/${donorId}/contactPreferences`,
      );
   }

   public setDonorContactPreferences(
      donorId: string,
      preferences: IDonorContactPreference[],
   ): Promise<void> {
      return this.postBex<IDonorContactPreference[], any>(
         `donors/${donorId}/contactPreferences`,
         preferences,
      );
   }

   public getDonorDonations(donorId: string): Promise<IDonorDonationDTO[]> {
      return this.getBex<IDonorDonationDTO[]>(`donors/${donorId}/donations`);
   }

   public getDonorEligibilityDates(
      donorId: string,
   ): Promise<IDonorEligibilityDate[]> {
      return this.getBex<IDonorEligibilityDate[]>(
         `donors/${donorId}/eligibilityDates`,
      );
   }

   public getDonorIdCard(
      donorId: string,
      qrcodeParameters: IDonorQRCodeInterface,
   ): Promise<Blob> {
      return this.getBlobBex(
         `donors/${donorId}/getDonorIDBarcode?height=${qrcodeParameters.height}&width=${qrcodeParameters.width}&barcodeType=${qrcodeParameters.type}`,
      );
   }

   public getDonorAppointments(
      donorId: string,
   ): Promise<IDonorAppointmentDTO[]> {
      return this.getBex<IDonorAppointmentDTO[]>(
         `donors/${donorId}/appointments`,
      );
   }
}
