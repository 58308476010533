import React from "react";
import { FormSelect as FormSelectOptions } from "./styled";
import { AppointmentTypesDropdownLabels } from "../../../../consts";

interface Options {
   appointmentTypeDescription: string;
   appointmentType: string;
}

export interface FormSelectProps {
   list: Options[];
   name: string;
   value: string;
   handleChange: Function;
}

export const AppointmentsDropdown: React.FC<FormSelectProps> = ({
   list,
   name,
   value,
   handleChange,
}) => {
   return (
      <>
         <FormSelectOptions
            name={name}
            value={value}
            onChange={e => handleChange(e.target.value)}
         >
            {list.map(item => {
               return (
                  <option
                     key={item.appointmentType}
                     value={item.appointmentType}
                  >
                     {AppointmentTypesDropdownLabels[item.appointmentType] ||
                        item.appointmentTypeDescription}
                  </option>
               );
            })}
         </FormSelectOptions>
      </>
   );
};
