import styled from "styled-components";
import { Card } from "react-bootstrap";
import { ReactComponent as GeoLocationIcon } from "../../../../../assets/svgs/geo-alt-fill.svg";
import { ReactComponent as CalendarSolidIcon } from "../../../../../assets/svgs/calendar_month_solid.svg";
import { ReactComponent as DropIcon } from "../../../../../assets/svgs/drop.svg";
import { PRIMARY_COLORS } from "../../../../consts/colors";

export const StyledConfirmAppointmentCard = styled(Card)`
   display: flex;
   padding: 15px 20px;
   transition: all 0.3s;
   border-color: ${PRIMARY_COLORS.NEBULA};
   border-width: 1px;
`;

export const AppointmentCardHeaderDiv = styled.div`
   display: flex;
   gap: 10px;
`;

export const StyledGeoLocation = styled(GeoLocationIcon)`
   fill: gray;
`;

export const StyledCalendarSolid = styled(CalendarSolidIcon)`
   fill: gray;
   width: 10px;
   height: 10px;
`;

export const StyledDrop = styled(DropIcon)`
   fill: gray;
   width: 7px;
   height: 7px;
`;

export const Title = styled.h5`
   font-size: 14px;
   font-weight: bold;
`;

export const Body = styled("div")`
   display: flex;
   justify-content: space-between;
   margin-top: 14px;
   align-items: flex-start;
   font-size: 13px;
   svg {
      scale: 1.8;
      margin-right: 15px;
   }
   .continue-btn-div {
      display: flex;
      justify-content: flex-end;
      .continue-confirm-btn {
         font-size: 13px;
         box-shadow: 0 1px 3px 0 rgba(60,64,67,.3), 0 4px 8px 3px rgba(60,64,67,.15);
      }
   }
   .location-mobile-css {
      margin-left: 5px;
      margin-bottom: 20px;
   }
   .align-items-center-css {
      display: flex;
      align-items: center;
      font-size: 13px;
      .margin-left-css {
         margin-left: 5px;
         font-size: 13px!important;
      }
   }
`;

export const Header = styled("div")`
   display: flex;
   justify-content: space-between;
   align-items: center;
`;
