import React, { ReactNode, ReactElement } from "react";

export interface IFilterInputWrapperProps {
   children?: ReactNode[] | ReactNode;
   grow?: boolean;
}

export function FilterControlWrapper({
   grow = true,
   children,
}: IFilterInputWrapperProps): ReactElement {
   return (
      <div className={`filter-control-wrapper ${grow && "grow"}`}>
         {children}
      </div>
   );
}
