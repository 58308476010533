import React from "react";
import { Modal } from "react-bootstrap";
import { CommonModalHeader } from "../styled";
import { useMediaQuery } from "react-responsive";
import { SignInOrNewAppointmentModalBody } from "./SignInOrNewAppointmentModalBody";

function SignInOrNewAppointment({ setPageName }) {
   const isMobile = useMediaQuery({ maxWidth: 767 });
   return (
      <>
         <Modal.Header closeButton></Modal.Header>
         <CommonModalHeader
            className={isMobile ? "mobile-header-css" : "desktop-header-css"}
         >
            <h5>
               <b>Continue</b>
            </h5>
         </CommonModalHeader>
         <Modal.Body>
            <SignInOrNewAppointmentModalBody
               isMobile={isMobile}
               setPageName={setPageName}
            />
         </Modal.Body>
      </>
   );
}

export default SignInOrNewAppointment;
