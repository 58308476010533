import styled from "styled-components";
import { PRIMARY_COLORS } from "../../../../consts/colors";
import { ReactComponent as LockIcon } from "../../../../../assets/svgs/lock.svg";
import { ReactComponent as PersonFillIcon } from "../../../../../assets/svgs/person-fill.svg";
export const MobilePersonalContentDiv = styled.div`
   .button-css {
      font-size: 12px;
      width: 80%;
      background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED} !important;
      border-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED} !important;
      align-items: center;
      padding: 5px 15px 5px 35px;
      margin: 8% 10% 10% 10%;
   }
   .row-css {
      margin-top: 8px;
      margin-bottom: 8px;
   }
   .line-css {
      color: ${PRIMARY_COLORS.NEBULA};
      border-width: 2px;
      opacity: 0.5;
      margin: 0;
   }
`;

export const MobileDemoGraphicContentDiv = styled.div`
   .row-css {
      margin-top: 8px;
      margin-bottom: 8px;
   }
   .line-css {
      color: ${PRIMARY_COLORS.NEBULA};
      border-width: 2px;
      opacity: 0.5;
      margin: 0;
   }
`;

export const LockImg = styled(LockIcon)`
   width: 16px;
   height: 16px;
   fill: white;
   margin-left: 25px;
`;

export const PersonImg = styled(PersonFillIcon)`
   width: 16px;
   height: 16px;
   fill: white;
   margin-left: 25px;
`;
