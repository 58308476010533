import React from "react";
import { IDonorDonationDTO } from "../../../../../services/bexWISE/donorDataService/IDonorDataService";
import { IDonorDTO } from "../../../../../types/IDonorDTO";
import { getAge } from "../PhysicalExamsDesktopView/ProfileSection";
import { ClipBoardIcon, PhysicalMobileDiv } from "./styled";
import { Col, Row } from "react-bootstrap";
import { HistoricalResultsDropdown } from "../PhysicalExamsDesktopView/HistoricalResultsSection/HistoricalResultsDropdownSection/HistoricalResultsDropdown";
import CommonHeader from "../../../../components/CommonHeader";
import {
   BloodMeasuresDataLabel,
   PhysicalMeasuresLabelData,
} from "../PhysicalExamsDesktopView/HistoricalResultsSection";
import MeasuresMobileView from "./MeasuresMobileView";
import { MonthDayDropDown } from "./MonthDayDropDown";
import { Section } from "../../../../components/Section/Section";
import { displayDate } from "../../utils/utils";

interface PhysicalExamMobileProps {
   profileData: IDonorDTO;
   displayDonorData: IDonorDonationDTO[];
   yearsValues: number[];
   selectedYear: number;
   setSelectedYear: Function;
   selectedDate: string;
   setSelectedDate: Function;
   lastDonation: IDonorDonationDTO;
}

const PhysicalExamsMobileView: React.FC<PhysicalExamMobileProps> = ({
   profileData,
   displayDonorData,
   yearsValues,
   selectedYear,
   setSelectedYear,
   selectedDate,
   setSelectedDate,
   lastDonation,
}) => {
   const visibleData = (): IDonorDonationDTO =>
      displayDonorData.find(
         eachDonation => eachDonation.date === selectedDate,
      ) || displayDonorData[0];

   return (
      <PhysicalMobileDiv>
         <div className="profile-section">
            <div className="middle-section">
               <div className="name-section">
                  {profileData.firstName?.toUpperCase()}{" "}
                  {profileData.lastName?.toUpperCase()}
               </div>
               <div className="details-section">
                  <div>{getAge(profileData.birthDate)} years old</div>
                  <div>|</div>
                  <div>{profileData.sex.description}</div>
                  <div>|</div>
                  <div>{lastDonation.physicalExam.height}</div>
                  <div>|</div>
                  <div>{lastDonation.physicalExam.weight} lbs</div>
               </div>
            </div>
         </div>
         <hr />
         <div className="location-section">
            <div className="mb-3 header-section">
               <ClipBoardIcon />
               <div className="header-css">Last Physical Exam</div>
            </div>
            <div className="content-css">
               <div>
                  Date Collected: <b>{displayDate(lastDonation?.date, true)}</b>
               </div>
               <div>Location: {lastDonation.location?.name}</div>
               <div>
                  {lastDonation.location?.addressOne},
                  {lastDonation.location?.city},{lastDonation.location?.state}{" "}
                  {lastDonation.location?.zipCode}
               </div>
            </div>
         </div>
         <hr />
         <Row className="dropdown-section-css">
            <Col xs={5}>
               <span className="label-css">Historical Results</span>
            </Col>
            <Col>
               <HistoricalResultsDropdown
                  list={yearsValues}
                  name={"year dropdown"}
                  value={selectedYear}
                  handleChange={setSelectedYear}
               />
            </Col>
            <Col>
               <MonthDayDropDown
                  list={displayDonorData}
                  name={"Month Day dropdown"}
                  value={selectedDate}
                  handleChange={setSelectedDate}
               />
            </Col>
         </Row>
         <Section className="physical-measures-css">
            <CommonHeader title="PHYSICAL MEASURES" />
            {PhysicalMeasuresLabelData.map(eachMeasure => (
               <React.Fragment key={eachMeasure.val}>
                  <MeasuresMobileView
                     label={eachMeasure.label}
                     val={eachMeasure.val}
                     visibleData={visibleData()}
                  />
               </React.Fragment>
            ))}
         </Section>
         <Section className="blood-measures-css">
            <CommonHeader title="BLOOD MEASURES" />
            {BloodMeasuresDataLabel.map(eachMeasure => (
               <React.Fragment key={eachMeasure.val}>
                  <MeasuresMobileView
                     label={eachMeasure.label}
                     val={eachMeasure.val}
                     visibleData={visibleData()}
                  />
               </React.Fragment>
            ))}
         </Section>
      </PhysicalMobileDiv>
   );
};

export default PhysicalExamsMobileView;
