import { useState, useEffect } from "react";

import * as yup from "yup";

const passwordValidation = () => {
   return yup.string().min(8).required();
};

const nameValidation = () => {
   return yup.string().min(1).required();
   //.matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ");
};

const phoneNumberValidation = () => {
   return yup
      .string()
      .required()
      .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
};

export const useValidEmail = (initialValue: string) => {
   const [email, setEmail] = useState(initialValue);
   const [emailIsValid, setEmailIsValid] = useState(true);

   useEffect(() => {
      const emailSchema = yup.object().shape({
         email: yup.string().email().required(),
      });

      if (email.length === 0) {
         setEmailIsValid(true);
         return;
      }

      const isValid: boolean = emailSchema.isValidSync({ email });

      setEmailIsValid(isValid);
   }, [email]);

   return { email, setEmail, emailIsValid };
};

export const useValidPassword = (initialValue: string) => {
   const [password, setPassword] = useState(initialValue);
   const [passwordIsValid, setPasswordIsValid] = useState(true);

   useEffect(() => {
      const passwordSchema = yup.object().shape({
         password: passwordValidation(),
      });

      if (password.length === 0) {
         setPasswordIsValid(true);
         return;
      }

      const isValid: boolean = passwordSchema.isValidSync({ password });

      setPasswordIsValid(isValid);
   }, [password]);

   return { password, setPassword, passwordIsValid };
};

export const useValidRePassword = (initialValue: string) => {
   const [rePassword, setRePassword] = useState(initialValue);
   const [rePasswordIsValid, setRePasswordIsValid] = useState(true);

   useEffect(() => {
      const passwordSchema = yup.object().shape({
         rePassword: passwordValidation(),
      });

      if (rePassword.length === 0) {
         setRePasswordIsValid(true);
         return;
      }

      const isValid: boolean = passwordSchema.isValidSync({ rePassword });

      setRePasswordIsValid(isValid);
   }, [rePassword]);

   return { rePassword, setRePassword, rePasswordIsValid };
};

export const useValidUsername = (initialValue: string) => {
   const [username, setUsername] = useState(initialValue);
   const [usernameIsValid, setUsernameIsValid] = useState(true);

   useEffect(() => {
      const usernameSchema = yup.object().shape({
         username: yup.string().min(8).required(),
      });

      if (username.length === 0) {
         setUsernameIsValid(true);
         return;
      }

      const isValid: boolean = usernameSchema.isValidSync({ username });

      setUsernameIsValid(isValid);
   }, [username]);

   return { username, setUsername, usernameIsValid };
};

export const useValidCode = (initialValue: string) => {
   const [code, setCode] = useState(initialValue);
   const [codeIsValid, setCodeIsValid] = useState(true);

   useEffect(() => {
      const codeSchema = yup.object().shape({
         code: yup.string().required(),
      });

      if (code.length === 0) {
         setCodeIsValid(true);
         return;
      }

      const isValid: boolean = codeSchema.isValidSync({ code });

      setCodeIsValid(isValid);
   }, [code]);

   return { code, setCode, codeIsValid };
};

export const useValidPhoneNumber = (initialValue: string) => {
   const [phoneNumber, setPhoneNumber] = useState(initialValue);
   const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(true);

   useEffect(() => {
      const codeSchema = yup.object().shape({
         phoneNumber: phoneNumberValidation(),
      });

      if (phoneNumber.length === 0) {
         setPhoneNumberIsValid(true);
         return;
      }

      const isValid: boolean = codeSchema.isValidSync({ phoneNumber });

      setPhoneNumberIsValid(isValid);
   }, [phoneNumber]);

   return { phoneNumber, setPhoneNumber, phoneNumberIsValid };
};

export const useValidFirstName = (initialValue: string) => {
   const [firstName, setFirstName] = useState(initialValue);
   const [firstNameIsValid, setFirstNameIsValid] = useState(true);

   useEffect(() => {
      const codeSchema = yup.object().shape({
         firstName: nameValidation(),
      });

      if (firstName.length === 0) {
         setFirstNameIsValid(true);
         return;
      }

      const isValid: boolean = codeSchema.isValidSync({ firstName });

      setFirstNameIsValid(isValid);
   }, [firstName]);

   return { firstName, setFirstName, firstNameIsValid };
};

export const useValidLastName = (initialValue: string) => {
   const [lastName, setLastName] = useState(initialValue);
   const [lastNameIsValid, setLastNameIsValid] = useState(true);

   useEffect(() => {
      const codeSchema = yup.object().shape({
         lastName: nameValidation(),
      });

      if (lastName.length === 0) {
         setLastNameIsValid(true);
         return;
      }

      const isValid: boolean = codeSchema.isValidSync({ lastName });

      setLastNameIsValid(isValid);
   }, [lastName]);

   return { lastName, setLastName, lastNameIsValid };
};

export const useValidDonorId = (initialValue: string) => {
   const [donorId, setDonorId] = useState(initialValue);
   const [donorIdIsValid, setDonorIdIsValid] = useState(true);

   useEffect(() => {
      const codeSchema = yup.object().shape({
         donorId: yup.string(),
      });

      if (donorId.length === 0) {
         setDonorIdIsValid(true);
         return;
      }

      const isValid: boolean = codeSchema.isValidSync({ donorId });

      setDonorIdIsValid(isValid);
   }, [donorId]);

   return { donorId, setDonorId, donorIdIsValid };
};

export const useValidStreetAddress = (initialValue: string) => {
   const [streetAddress, setStreetAddress] = useState(initialValue);
   const [streetAddressIsValid, setStreetAddressIsValid] = useState(true);

   useEffect(() => {
      const codeSchema = yup.object().shape({
         streetAddress: yup.string().min(2).required(),
      });

      if (streetAddress.length === 0) {
         setStreetAddressIsValid(true);
         return;
      }

      const isValid: boolean = codeSchema.isValidSync({ streetAddress });

      setStreetAddressIsValid(isValid);
   }, [streetAddress]);

   return { streetAddress, setStreetAddress, streetAddressIsValid };
};

export const useValidCity = (initialValue: string) => {
   const [city, setCity] = useState(initialValue);
   const [cityIsValid, setCityIsValid] = useState(true);

   useEffect(() => {
      const codeSchema = yup.object().shape({
         city: yup.string().min(2).required(),
      });

      if (city.length === 0) {
         setCityIsValid(true);
         return;
      }

      const isValid: boolean = codeSchema.isValidSync({ city });

      setCityIsValid(isValid);
   }, [city]);

   return { city, setCity, cityIsValid };
};

export const useValidZipCode = (initialValue: string) => {
   const [zipCode, setZipCode] = useState(initialValue);
   const [zipcodeIsValid, setZipcodeIsValid] = useState(true);

   useEffect(() => {
      const codeSchema = yup.object().shape({
         zipCode: yup.string().min(2).required(),
      });

      if (zipCode.length === 0) {
         setZipcodeIsValid(true);
         return;
      }

      const isValid: boolean = codeSchema.isValidSync({ zipCode });

      setZipcodeIsValid(isValid);
   }, [zipCode]);

   return { zipCode, setZipCode, zipcodeIsValid };
};
