import React, { ReactElement } from "react";
import { Image } from "react-bootstrap";
import MoreFilterIcon from "../../../assets/images/more-filters.png";
import { Div } from "./mobileMoreFiltersButtonStyle";

export interface IMobileMoreFiltersButtonProps {
   onClick: () => void;
}

export function MobileMoreFiltersButton({
   onClick,
}: IMobileMoreFiltersButtonProps): ReactElement {
   return (
      <Div onClick={onClick} className="mt-3">
         <Image src={MoreFilterIcon} width="22" height="22" />
         <span>More Filters</span>
      </Div>
   );
}
