import React, { useContext, useEffect, useState } from "react";
import { PageComponent } from "../../components/PageComponent";
import { IDonorDonationDTO } from "../../../services/bexWISE/donorDataService/IDonorDataService";
import { getDonorDataServiceInstance } from "../../../services/bexWISE/donorDataService/getDonorDataServiceInstance";
import { UserContext } from "../../contexts/user/userContext";
import { useMediaQuery } from "react-responsive";
import { PhysicalExamsContainer } from "./styled";
import PhysicalExamsMobileView from "./components/PhysicalExamsMobileView";
import PhysicalExamsDesktopView from "./components/PhysicalExamsDesktopView";
import NoDonationsComponent from "./components/NoDonationsComponent";

function sortAndFilterDataByDates(
   donorDonationData: IDonorDonationDTO[],
   yearSelected: number,
): IDonorDonationDTO[] {
   const filteredDonationData: IDonorDonationDTO[] = donorDonationData.filter(
      each => each.date.includes(String(yearSelected)),
   );
   const sortedAndFilteredData: IDonorDonationDTO[] = filteredDonationData.sort(
      (a: IDonorDonationDTO, b: IDonorDonationDTO) =>
         new Date(b.date).getTime() - new Date(a.date).getTime(),
   );
   return sortedAndFilteredData;
}

const PhysicalExamsPage = (): JSX.Element => {
   const { userDonorId, profileData } = useContext(UserContext);
   const [donorDonationData, setDonorDonationData] = useState<
      IDonorDonationDTO[]
   >([]);
   const isMobile: boolean = useMediaQuery({ maxWidth: 1000 });
   const [yearsValues, setYearsValues] = useState<number[]>([]);
   const [selectedYear, setSelectedYear] = useState<number>(0);
   const [selectedDate, setSelectedDate] = useState<string>("");
   const [displayDonorData, setDisplayDonorData] = useState<
      IDonorDonationDTO[]
   >([]);
   const [lastDonation, setLastDonation] = useState<IDonorDonationDTO | null>(
      null,
   );

   useEffect(() => {
      if (userDonorId) {
         getDonorDataServiceInstance()
            .getDonorDonations(userDonorId)
            .then((response: IDonorDonationDTO[]) => {
               setDonorDonationData(response);
            });
      }
   }, [userDonorId]);

   useEffect(() => {
      if (donorDonationData && donorDonationData.length) {
         const years: number[] = [];
         for (let eachDonation of donorDonationData) {
            const eachYear: Date = new Date(eachDonation.date);
            if (!years.includes(eachYear.getFullYear())) {
               years.push(eachYear.getFullYear());
            }
         }
         const sortedYears: number[] = years.sort(
            (a: number, b: number) => b - a,
         );
         setYearsValues(sortedYears);
         setSelectedYear(sortedYears[0]);

         const recentDonation: IDonorDonationDTO = donorDonationData.reduce(
            (a: IDonorDonationDTO, b: IDonorDonationDTO) =>
               new Date(a.date).getTime() > new Date(b.date).getTime() ? a : b,
         );
         setLastDonation(recentDonation);
      }
   }, [donorDonationData]);

   useEffect(() => {
      if (selectedYear) {
         const displayData: IDonorDonationDTO[] = sortAndFilterDataByDates(
            donorDonationData,
            selectedYear,
         );
         setDisplayDonorData(displayData);
         setSelectedDate(displayData[0].date);
      }
   }, [selectedYear]);

   return (
      <PageComponent
         name={"Profile Page"}
         title={"Profile Page"}
         description={"Profile Page"}
      >
         <>
            {profileData && (
               <>
                  {displayDonorData.length > 0 && lastDonation ? (
                     <PhysicalExamsContainer
                        className={isMobile ? "mobile-css mt-3" : "mt-3"}
                     >
                        {isMobile ? (
                           <PhysicalExamsMobileView
                              profileData={profileData}
                              displayDonorData={displayDonorData}
                              yearsValues={yearsValues}
                              selectedYear={selectedYear}
                              setSelectedYear={setSelectedYear}
                              selectedDate={selectedDate}
                              setSelectedDate={setSelectedDate}
                              lastDonation={lastDonation}
                           />
                        ) : (
                           <PhysicalExamsDesktopView
                              profileData={profileData}
                              displayDonorData={displayDonorData}
                              yearsValues={yearsValues}
                              selectedYear={selectedYear}
                              setSelectedYear={setSelectedYear}
                              selectedDate={selectedDate}
                              setSelectedDate={setSelectedDate}
                              lastDonation={lastDonation}
                           />
                        )}
                     </PhysicalExamsContainer>
                  ) : (
                     <NoDonationsComponent
                        isMobile={isMobile}
                        profileData={profileData}
                     />
                  )}
               </>
            )}
         </>
      </PageComponent>
   );
};

export default PhysicalExamsPage;
export { displayDate } from "./utils/utils";
