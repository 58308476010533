import React, { useContext } from "react";
import {
   donorStatusOptions,
   locationDropDownOptions,
   sortByOptions,
} from "../../../../../../consts";
import { DateRangePicker } from "../../../../../../components/Form/Inputs/Generic/DateRangePicker/DateRangePicker";
import { FilterInputForm } from "../../../../../../components/Form/Filter/FilterInputForm";
import { FilterInputFormRow } from "../../../../../../components/Form/Filter/FilterInputFormRow";
import { SingleSelect } from "../../../../../../components/Form/Inputs/Generic/SingleSelect/SingleSelect";
import { ReactComponent as DropIcon } from "../../../../../../../assets/svgs/drop.svg";
import { ReactComponent as CheckmarkIcon } from "../../../../../../../assets/svgs/check2-circle.svg";
import { MultipleSelect } from "../../../../../../components/Form/Inputs/Generic/MultipleSelect/MultipleSelect";
import { usePastAppointmentsPlaceholders } from "./usePastAppointmentsPlaceholders";
import { FilteredPastAppointmentsContext } from "../../context/FilteredPastAppointmentsContext";
import { MyAppointmentsPageContext } from "../../../../context/MyAppointmentsPageContext";

export const PastAppointmentFiltersDesktop = () => {
   const { appointmentTypesPlaceholder, donorArrivalStatusPlaceholder } =
      usePastAppointmentsPlaceholders();
   const { filters, updateFilters } = useContext(
      FilteredPastAppointmentsContext,
   );
   const { uniqueAppointmentTypes } = useContext(MyAppointmentsPageContext);
   return (
      <FilterInputForm>
         <FilterInputFormRow>
            <SingleSelect
               values={locationDropDownOptions}
               selectedValue={
                  locationDropDownOptions.find(
                     option => option.value === filters.selectedLocationSource,
                  )!
               }
               onValueSelected={selectedValue =>
                  updateFilters({ selectedLocationSource: selectedValue.value })
               }
            />
            <MultipleSelect
               selectedValues={filters.selectedAppointmentTypes}
               values={
                  uniqueAppointmentTypes
                     ? uniqueAppointmentTypes.map(ap => ap.value)
                     : []
               }
               placeholder={appointmentTypesPlaceholder}
               onSelectedValuesChanges={newValue => {
                  updateFilters({ selectedAppointmentTypes: newValue });
               }}
               staticIconSrc={<DropIcon className={"input-label-image"} />}
            />
            <DateRangePicker
               startDate={filters.startDate}
               setStartDate={startDate => updateFilters({ startDate })}
               endDate={filters.endDate}
               setEndDate={endDate => updateFilters({ endDate })}
            />
            <MultipleSelect
               selectedValues={filters.selectedDonorStatuses}
               values={donorStatusOptions.map(option => option.value)}
               placeholder={donorArrivalStatusPlaceholder}
               onSelectedValuesChanges={selectedDonorStatuses => {
                  updateFilters({ selectedDonorStatuses });
               }}
               staticIconSrc={<CheckmarkIcon className={"input-label-image"} />}
            />
            <SingleSelect
               values={sortByOptions}
               selectedValue={
                  sortByOptions.find(
                     option => option.value === filters.sortBySelected,
                  )!
               }
               onValueSelected={sortBySelected => {
                  updateFilters({ sortBySelected: sortBySelected.value });
               }}
            />
         </FilterInputFormRow>
      </FilterInputForm>
   );
};
