import { IBloodDrive } from "types/IBloodDrive";
import { IAppointmentSummary } from "../services/bexWISE/bloodDriveDataService/IBloodDriveDataService";
import * as _ from "lodash";
export const getUniqueAppointmentTypes = (
   list: IBloodDrive[] | null,
): string[] => {
   return list !== null
      ? _.uniq(
           list
              .flatMap((drive: IBloodDrive) => drive.appointmentTypes)
              .map(
                 (appointmentType: IAppointmentSummary) =>
                    appointmentType.appointmentTypeDescription,
              ),
        )
      : [];
};
