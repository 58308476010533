import { IBexWISEResolutionService } from "./IBexWISEResolutionService";
import { BexWISEResolutionService } from "./BexWISEResolutionService";
import { getEnvironmentService } from "../../environmentService/getEnvironmentService";

let instance: IBexWISEResolutionService | null = null;
export function getBexWISEResolutionServiceInstance(): IBexWISEResolutionService {
   if (instance === null) {
      instance = new BexWISEResolutionService(getEnvironmentService());
   }
   return instance;
}
