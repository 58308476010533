import React from "react";
import { StyledLoader } from "./styled";

const Loader = () => {
   return (
      <div className="w-100 text-center my-2">
         <StyledLoader animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
         </StyledLoader>
      </div>
   );
};

export default Loader;
