import React, { useContext } from "react";
import {
   StyledAppointmentLocationCard,
   AppointmentCardHeaderDiv,
   StyledClockIcon,
   StyledGeoLocation,
   StyledTelephoneIcon,
   Footer,
   PhoneNumberSpan,
} from "./styled";
import { Body, Header, Title } from "./styled";
import { format } from "date-fns";
import Button from "react-bootstrap/Button";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { EnvironmentContext } from "../../../../contexts/environment/environmentContext";
import { LocationTypeImageIcon } from "app/components/LocationTypeImageIcon/LocationImageIcon";

function formatPhone(phone) {
   if (phone) {
      phone = phone.replace(/\D/g, "");
      if (phone.length === 10) {
         return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }
   }
   return null;
}

const AppointmentTime: React.FC<{
   startTime: Date;
   endTime: Date | undefined;
}> = ({ startTime, endTime }) => {
   if (!endTime) {
      return (
         <>{`${format(new Date(startTime), "p")} - No End Time Defined`}</>
      );
   }
   return (
      <>{`${format(new Date(startTime), "p")} - ${format(
         new Date(endTime!),
         "p",
      )}`}</>
   );
};

const AddressSection = ({ card }) => {
   const isAccountNameSameAsLocationName = card?.location?.name === card?.account?.accountName;
   return (
      <Col>
         {/* If the account name and the location are the same, only show the account name (SCREDP-63) */}
         {!isAccountNameSameAsLocationName && (<>
            {card?.location?.name || ""}<br />
         </>)}

         {card?.location?.addressOne}
         {card?.location?.addressTwo && (<>
            ,<br />{card?.location?.addressTwo}
         </>)}
         <br />
         {card?.location?.city || ""}, {card?.location?.state || ""}{" "}
         {card?.location?.zipCode || ""}

      </Col>
   );
};

const LocationClockSection = ({ card }) => {
   return (
      <>
         {card?.location?.distanceFromOriginText && (
            <div className="align-center-items">
               <>
                  <StyledGeoLocation />
                  {/* if there is a distance to show, replace "mi" to "miles". (SCREDP-77) */}
                  {card?.location?.distanceFromOriginText?.replace(
                     "mi",
                     "miles",
                  )}{" "}
                  away
               </>


            </div>
         )}
         <div className="align-center-items ">
            <StyledClockIcon />
            {card?.startTime ? (
               <AppointmentTime
                  startTime={card.startTime}
                  endTime={card?.endTime}
               />
            ) : (
               <>
                  {`${format(new Date(), "p")} -
                                 ${format(new Date(), "p")}`}
               </>
            )}
         </div>
      </>
   );
};

const PhoneNumberSection = ({ card }) => {
   return (
      <Col className="phone-number-section">
         <div className="align-center-items">
            <StyledTelephoneIcon fill="gray" />
            <PhoneNumberSpan>
               {formatPhone(card?.location?.phoneNumber)}
            </PhoneNumberSpan>
         </div>
      </Col>
   );
};

function AppointmentCard({ card }) {
   const navigate = useNavigate();
   const isMobile = useMediaQuery({ maxWidth: 767 });
   const { centerData } = useContext(EnvironmentContext);
   return (
      <StyledAppointmentLocationCard >
         <Header>
            <AppointmentCardHeaderDiv>
               {/* Show icon based on the location type */}
               {card?.location?.locationType && <LocationTypeImageIcon
                  locationType={card?.location?.locationType}
               />}
               <Title>{card?.account?.accountName}</Title>

            </AppointmentCardHeaderDiv>
            {isMobile && (
               <a className="visit-page-css" href={`${centerData?.contact}`} target="_blank" rel="noreferrer">
                  <b>Questions?</b> Contact us
               </a>
            )}
            {!isMobile && (<Button
               variant="danger"
               className="change-location-button"
               onClick={() => navigate("/")}
            >
               Change Location <i className="pi pi-search"></i>
            </Button>)}
         </Header>
         <Body className={isMobile ? "mobile-css" : "desktop-css"}>
            {isMobile ? (
               <>
                  <div className="location-clock-mobile-css">
                     <AddressSection card={card} />
                     <div>
                        <LocationClockSection card={card} />
                        <PhoneNumberSection card={card} />
                     </div>

                  </div>
               </>
            ) : (
               <>
                  <AddressSection card={card} />
                  <Col>
                     <LocationClockSection card={card} />
                  </Col>
                  <PhoneNumberSection card={card} />

               </>
            )}
         </Body>
         {!isMobile && (
            <Footer className={isMobile ? "mobile-css" : "desktop-css"}>

               {/* 'Visit Our Page' should open in new tab (AEP-48) */}
               <a className="visit-page-css" href={`${centerData?.contact}`} target="_blank" rel="noreferrer">
                  <b>Questions?</b> Contact us
               </a>
            </Footer>
         )}

      </StyledAppointmentLocationCard>
   );
}

export default AppointmentCard;
