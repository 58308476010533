import React from "react";
import ProfilePageImg from "../../../../../assets/images/profile-page-image.png";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { ChangePwdSection, LockImg } from "./styled";

interface ChangePwdInterface {
   modalShow: boolean;
   setModalShow: Function;
}
const ChangePasswordSection: React.FC<ChangePwdInterface> = ({
   modalShow,
   setModalShow,
}) => {
   return (
      <ChangePwdSection>
         <Image
            src={ProfilePageImg}
            fluid
            alt="profile-page-img"
            className="profile-img-css"
         />
         <div className={"change-password-button-container"}>
            <Button
               className="change-password-button"
               onClick={() => setModalShow(true)}
            >
               Change Password <LockImg />
            </Button>
         </div>
      </ChangePwdSection>
   );
};

export default ChangePasswordSection;
