import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { DonorModal } from "./styled";
import { AuthContext } from "../../../../contexts/auth/authContext";
import { EnvironmentContext } from "../../../../contexts/environment/environmentContext";
import { DonorCardImage } from "../DonorCardImage/DonorCardImage";

interface DonorCardModalProps {
   showDonorModal: boolean;
   setDonorModalShow: Function;
   fullName: string;
   bloodType: string;
}
const DonorCardModal: React.FC<DonorCardModalProps> = ({
   showDonorModal,
   setDonorModalShow,
   fullName,
   bloodType,
}) => {
   const { userDonorId } = useContext(AuthContext);
   const { centerData, logoSrc } = useContext(EnvironmentContext);

   const onClose = (): void => {
      setDonorModalShow(false);
   };

   return (
      <DonorModal
         show={showDonorModal}
         onHide={onClose}
         aria-labelledby="contained-donor-modal-title"
         backdrop="static"
         keyboard={false}
         fullscreen
      >
         <Modal.Body className="modal-body-css">
            <div className="header-css">
               {logoSrc && <img src={logoSrc} className="logo-css" alt="logo" />}
               <div className="logo-section-css">
                  <span className="bci-css">
                     {/* Changed bank name to Center's name (AEP-67) */}
                     {centerData?.name?.toUpperCase()}
                  </span>
                  <span className="di-css">DONOR IDENTIFICATION</span>
               </div>
            </div>
            <div className="content-css">
               <div className="qr-section-css">
                  <div>
                     <DonorCardImage />
                  </div>
                  <div className="donor-id-css">{userDonorId}</div>
               </div>
               <div className="content-bottom-section">
                  <div className="content-name-section">
                     <div className="name-css">{fullName.toUpperCase()}</div>
                     <div className="blood-type-css">{bloodType}</div>
                  </div>
                  <Button
                     variant="link"
                     className="btn-css"
                     onClick={() => onClose()}
                  >
                     Close
                  </Button>
               </div>
            </div>
         </Modal.Body>
      </DonorModal>
   );
};

export default DonorCardModal;
