import { format } from "date-fns";
import React from "react";
import { IAppointment } from "types/IAppointment";
import { isCurrentDateEqualToPreviousDate } from "utils/isCurrentDateEqualPreviousDate";
import FutureAppointmentMobileSlot from "./components/PastAppointmentMobileSlot";

interface PastAppointmentMobileProps {
   appointment: IAppointment;
   appointments: IAppointment[];
   index: number;
}

const PastAppointmentMobile: React.FC<PastAppointmentMobileProps> = React.memo(
   ({ appointment, appointments, index }) => {
      const formatYear = (appointmentTime: string | Date) => {
         return (
            <p className="my-2 fw-bold ">
               {format(new Date(appointmentTime), "yyyy")}
            </p>
         );
      };
      return (
         <>
            {!isCurrentDateEqualToPreviousDate(
               appointment?.appointmentDateTime,
               index,
               appointments,
            ) && formatYear(appointment?.appointmentDateTime)}

            <FutureAppointmentMobileSlot appointment={appointment} />
         </>
      );
   },
);

export default PastAppointmentMobile;
