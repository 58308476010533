import { ICognitoService } from "./ICognitoService";
import { ICognitoSignUpParams } from "./ICognitoSignUpParams";
import { CognitoService } from "./CognitoService";
import { getEnvironmentService } from "../environmentService/getEnvironmentService";

let instance: ICognitoService<ICognitoSignUpParams> | null = null;
export function getCognitoService<
   T extends ICognitoSignUpParams,
>(): ICognitoService<T> {
   if (instance === null) {
      instance = new CognitoService(getEnvironmentService());
   }
   return instance;
}
