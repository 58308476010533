import { ILocationType } from "../../../types/ILocationType";
import BusIcon from "../../../assets/images/bus.png";
import HomeIcon from "../../../assets/images/home-icon.png";
import Inside from "../../../assets/images/business-preferences.png";
import Combo from "../../../assets/images/combo.png";
// Given a location type, return the appropriate icon source
export function getIconForLocationType(locationType: ILocationType): string {
   switch (locationType.code) {
      case "M":
         return BusIcon;
      case "I":
         return Inside;
      case "F":
         return HomeIcon;
      case "C":
         return Combo;
      default:
         // "N Case - Never at site"
         return HomeIcon;
   }
}
