import React from "react";
import { ClipBoardIcon, LocationSectionDiv } from "./styled";
import { IDonorDonationDTO } from "../../../../../../services/bexWISE/donorDataService/IDonorDataService";
import { displayDate } from "../../../utils/utils";

interface LocationSectionProps {
   lastDonorData: IDonorDonationDTO;
}
const LocationSection: React.FC<LocationSectionProps> = ({ lastDonorData }) => {
   return (
      <LocationSectionDiv>
         <ClipBoardIcon />
         <div className="right-section">
            <div className="header-css">Last Physical Exam</div>
            <div className="content-css">
               <div>
                  Date Collected: <b>{displayDate(lastDonorData.date, true)}</b>
               </div>
               <div>Location: {lastDonorData.location?.name}</div>
               <div>
                  {lastDonorData.location?.addressOne},
                  {lastDonorData.location?.city},{lastDonorData.location?.state}{" "}
                  {lastDonorData.location?.zipCode}
               </div>
            </div>
         </div>
      </LocationSectionDiv>
   );
};

export default LocationSection;
