import React, { useContext, useState } from "react";
import { useValidEmail } from "../../../hooks/useAuthValidationHook";
import { Button, Form, Modal } from "react-bootstrap";
import { Email } from "../authComponents";
import { ReactComponent as ForwardArrow } from "../../../../assets/svgs/forward-arrow.svg";
import { ModalPageNames } from "../../../consts";
import { AuthContext } from "../../../contexts/auth/authContext";
import { CommonModalHeader, FormGroupAlignedCenter } from "../styled";
import { CrossIconImg, ForgotPasswordForm } from "./styled";
import Alert from "react-bootstrap/Alert";
import { useMediaQuery } from "react-responsive";

function ForgotPassword({
   onClose,
   setEmailToBeVerified,
   setPageName,
   setIsPasswordSection,
   passwordResetRequired,
   passwordResetEmail
}) {
   // If a password reset is required, use the provided passwordResetEmail value as the email address (SCREDP-55)
   const { email, setEmail, emailIsValid } = useValidEmail(passwordResetRequired ? passwordResetEmail : "");
   const [error, setError] = useState("");

   const isInValid: boolean = !emailIsValid || email.length === 0;
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });

   const authContext = useContext(AuthContext);

   const initiateForgotPassword = async () => {
      try {
         await authContext.forgotPassword(email);
         setEmailToBeVerified(email);
         setPageName(ModalPageNames.VERIFYING_ACCOUNT);
         setIsPasswordSection(true);
      } catch (err: any) {
         setError(err.message);
      }
   };

   return (
      <>
         <Modal.Header></Modal.Header>
         <Modal.Body>
            <CommonModalHeader className="desktop-header-css">
               <h5>
                  <b>{passwordResetRequired ? "New Password Required" : "Forgot Password"}</b>
               </h5>
            </CommonModalHeader>
            {error && <Alert variant="danger">{error}</Alert>}
            <ForgotPasswordForm className={isMobile ? "" : "desktop-form-css"}>
               {/* Display an indication when a password reset is required (SCREDP-55) */
                  passwordResetRequired && 
                  <FormGroupAlignedCenter>
                     <div className={`mb-1 ${isMobile ? "mobile-padding-css" : ""}`}>A new password is required for your account.<br/>Click Continue to proceed.</div>
                  </FormGroupAlignedCenter>
               }
               {!passwordResetRequired &&
                  <>
                     <FormGroupAlignedCenter
                        className={`mb-5 header-css ${
                           isMobile ? "mobile-padding-css" : ""
                        }`}
                     >
                        Please enter your email address {isMobile && <br></br>}{" "}
                        associated with account.
                     </FormGroupAlignedCenter>
                     <div className={`mb-5 ${isMobile ? "mobile-padding-css" : ""}`}>
                        <Email
                           emailIsValid={emailIsValid}
                           setEmail={setEmail}
                           placeholderValue={"Registered Email Address"}
                           isRequired={false}
                        />
                     </div>
                  </>
               }
               <Form.Group
                  className={`footer-section-css ${
                     isMobile ? "mobile-footer-css" : "desktop-footer-css"
                  }`}
               >
                  <Button onClick={onClose} className="btn-css">
                     Cancel <CrossIconImg />
                  </Button>
                  <Button
                     disabled={isInValid}
                     onClick={initiateForgotPassword}
                     className="btn-css"
                  >
                     {" "}
                     Continue <ForwardArrow />
                  </Button>
               </Form.Group>
            </ForgotPasswordForm>
         </Modal.Body>
      </>
   );
}

export default ForgotPassword;
