import { format } from "date-fns";
import React from "react";
import { isCurrentDateEqualToPreviousDate } from "../../utils/utils";
import MyDonationMobileSlot from "./MyDonationMobileSlot";
import { IDonorDonationDTO } from "services/bexWISE/donorDataService/IDonorDataService";
interface MyDonationMobileProps {
   donation: IDonorDonationDTO;
   donations: IDonorDonationDTO[];
   index: number;
}
const MyDonationMobile: React.FC<MyDonationMobileProps> = React.memo(
   ({ donation, donations, index }) => {
      const formatYear = (date: string | Date): JSX.Element => {
         return (
            <p className="my-2 fw-bold ">{format(new Date(date), "yyyy")}</p>
         );
      };
      return (
         <>
            {!isCurrentDateEqualToPreviousDate(
               donation?.date,
               index,
               donations,
            ) && formatYear(donation?.date)}

            <MyDonationMobileSlot donation={donation} />
         </>
      );
   },
);

export default MyDonationMobile;
