import React, { ReactElement } from "react";
import { InputWrapper } from "../../InputWrapper/InputWrapper";
import "./style.scss";
import { MultiSelect } from "primereact/multiselect";
import { FilterControlWrapper } from "../../../Filter/FilterControlWrapper";
export interface IMultiSelectProps {
   selectedValues: string[];
   values: string[];
   onSelectedValuesChanges: (selectedValues: string[]) => void;
   placeholder: string;
   staticIconSrc?: React.ReactElement;
}

export function MultipleSelect({
   selectedValues,
   values,
   onSelectedValuesChanges,
   placeholder,
   staticIconSrc,
}: IMultiSelectProps): ReactElement {
   return (
      <FilterControlWrapper>
         <InputWrapper>
            <div className={"input multi-select"}>
               <div className={"input-label-container"}>
                  {staticIconSrc && (
                     <div className={"input-label-image-container"}>
                        {staticIconSrc}
                     </div>
                  )}
                  <MultiSelect
                     value={selectedValues}
                     onChange={change => onSelectedValuesChanges(change.value)}
                     options={values}
                     placeholder={placeholder}
                     maxSelectedLabels={3}
                     className="w-full md:w-20rem"
                  />
               </div>
            </div>
         </InputWrapper>
      </FilterControlWrapper>
   );
}
