import { getBexWISEResolutionServiceInstance } from "../bexWISEResolutionService/getBexWISEResolutionService";
import { IAppointmentDataService } from "./IAppointmentDataService";
import { AppointmentDataService } from "./AppointmentDataService";
import { getCognitoService } from "../../cognitoService/getCognitoService";
import { ICognitoSignUpParams } from "../../cognitoService/ICognitoSignUpParams";

let instance: IAppointmentDataService | null = null;

export function getAppointmentDataServiceInstance(): IAppointmentDataService {
   if (instance === null) {
      instance = new AppointmentDataService(
         getBexWISEResolutionServiceInstance(),
         getCognitoService<ICognitoSignUpParams>(),
      );
   }
   return instance;
}
