import React, { ReactElement, useState, useEffect, useContext } from "react";
import {
   IDonorDataService,
   IDonorQRCodeInterface,
} from "../../../../../services/bexWISE/donorDataService/IDonorDataService";
import { getDonorDataServiceInstance } from "../../../../../services/bexWISE/donorDataService/getDonorDataServiceInstance";
import { DONOR_CARD_TYPES } from "../../../../consts";
import { useMediaQuery } from "react-responsive";
import { AuthContext } from "../../../../contexts/auth/authContext";
import { EnvironmentContext } from "../../../../contexts/environment/environmentContext";

export interface IDonorCardImageProps {}

export function DonorCardImage(props: IDonorCardImageProps): ReactElement {
   const { environmentConfig } = useContext(EnvironmentContext);
   const isMobile: boolean = useMediaQuery({ maxWidth: 1000 });
   const { userDonorId } = useContext(AuthContext);

   const [qrImage, setQrImage] = useState<string | null>(null);
   const [donorCardStyle, setDonorCardStyle] = useState<string | null>(null);
   const qrCodeParameters = (type: string): IDonorQRCodeInterface => {
      switch (type) {
         case DONOR_CARD_TYPES.QR:
            return {
               width: isMobile ? "350" : "70",
               height: isMobile ? "350" : "70",
               type: type.toUpperCase(),
            };
         case DONOR_CARD_TYPES.CODE128:
            return {
               width: isMobile ? "350" : "130",
               height: isMobile ? "200" : "50",
               type: type.toUpperCase(),
            };
         case DONOR_CARD_TYPES.PDF417:
            return {
               width: isMobile ? "350" : "150",
               height: isMobile ? "250" : "150",
               type: type.toUpperCase(),
            };
         default:
            return {
               width: "",
               height: "",
               type: "",
            };
      }
   };

   const getDonorCard = async (
      donorId: string,
      qrCodeType: string,
   ): Promise<void> => {
      try {
         const donorDriveDataService: IDonorDataService =
            getDonorDataServiceInstance();
         const response: Blob = await donorDriveDataService.getDonorIdCard(
            donorId,
            qrCodeParameters(qrCodeType),
         );
         const imageUrl: string = URL.createObjectURL(response);
         setQrImage(imageUrl);
      } catch (err) {
         throw err;
      }
   };

   useEffect(() => {
      if (
         environmentConfig &&
         environmentConfig.donorCardStyle !== donorCardStyle
      ) {
         setDonorCardStyle(environmentConfig.donorCardStyle);
      }
   }, [environmentConfig]);
   useEffect(() => {
      if (userDonorId && donorCardStyle) {
         getDonorCard(userDonorId, donorCardStyle);
      }
   }, [isMobile, userDonorId, donorCardStyle]);

   return (
      <div className={"DonorCardImage"}>
         {qrImage && <img src={qrImage} alt="qr-code-mg" />}
      </div>
   );
}
