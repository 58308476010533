import { IEnvironmentService } from "./IEnvironmentService";
import { IEnvironmentConfiguration } from "./IEnvironmentConfiguration";
import axios from "axios";
import { Base64Image } from "./Base64Image";
import { IGetImageResponse } from "./IGetImageResponse";
import { IEnvironmentProperties } from "./IEnvironmentProperties";

// Get the CloudFront domain from the environment (used for running locally during development)
const devDistributionURL: string = (process.env.REACT_APP_ENVIRONMENT_CLOUDFRONT_DIST_DOMAIN ? process.env.REACT_APP_ENVIRONMENT_CLOUDFRONT_DIST_DOMAIN : "");

// Returns whether the app is being run locally
function isLocal(): boolean {
   return window.document.location.hostname === "localhost";
}

export class EnvironmentService implements IEnvironmentService {
   private configuration: IEnvironmentConfiguration | null = null;
   private properties: string | null = null;
   public getConfiguration(): Promise<IEnvironmentConfiguration> {
      if (this.configuration === null) {
         // Use dev url if running locally
         const configURL: string = isLocal()
            ? `${devDistributionURL}/api/config`
            : "api/config";
         // Configuration not yet cached
         return axios
            .get<any, { data: IEnvironmentConfiguration }>(configURL)
            .then((response: { data: IEnvironmentConfiguration }) => {
               this.configuration = response.data;
               return this.configuration;
            });
      } else {
         // Configuration already cached, return cached value
         return Promise.resolve(this.configuration);
      }
   }

   public getProperties(): Promise<IEnvironmentProperties> {
      if (this.properties === null) {
         // Properties not yet cached
         // Use dev url if running locally
         const propsURL: string = isLocal()
            ? `${devDistributionURL}/api/props`
            : "api/props";
         return axios
            .get<any, { data: { body: string } }>(propsURL)
            .then((response: { data: { body: string } }) => {
               this.properties = response.data.body;
               return JSON.parse(this.properties);
            });
      } else {
         // Properties already cached, return cached value
         return Promise.resolve(JSON.parse(this.properties));
      }
   }

   public updateConfiguration(
      update: Partial<IEnvironmentConfiguration>,
   ): Promise<void> {
      // Fetch the current configuration
      return this.getConfiguration().then(
         (currentConfiguration: IEnvironmentConfiguration) => {
            // Update local configuration
            Object.keys(update).forEach((updatedKey: string) => {
               currentConfiguration[updatedKey] = update[updatedKey];
            });
            this.configuration = { ...currentConfiguration };
            return Promise.resolve();
         },
      );
   }

   public getImage(imageName: string): Promise<Base64Image | undefined> {
      const imageURL: string = isLocal()
         ? `${devDistributionURL}/api/images/${imageName}`
         : `api/images/${imageName}`;
      return axios
         .get<void, IGetImageResponse>(`${imageURL}`)
         .then((imageResponse: { imageData: string }) => {
            // @ts-ignore
            return imageResponse.data.imageData;
         });
   }
}
