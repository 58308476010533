import React from "react";
import { Navigate } from "react-router-dom";
import { AuthStatus } from "../../services/cognitoService/AuthStatus";
import { AuthContext } from "../contexts/auth/authContext";

interface RoutesContentInterface {
   isGuarded: boolean;
   redirectPath?: string;
   children: React.ReactElement;
}
const GuardedRoutes = ({
   isGuarded,
   redirectPath = "/",
   children,
}: RoutesContentInterface) => {
   const { authStatus } = React.useContext(AuthContext);
   if (authStatus === AuthStatus.SignedIn || !isGuarded) {
      return children;
   }
   return <Navigate to={redirectPath} replace />;
};

export default GuardedRoutes;
