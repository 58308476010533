import { IBexWISEResolutionService } from "./IBexWISEResolutionService";
import { IEnvironmentService } from "../../environmentService/IEnvironmentService";
import { IEnvironmentConfiguration } from "../../environmentService/IEnvironmentConfiguration";

// The purpose of this class is to resolve the bexWISE base url
export class BexWISEResolutionService implements IBexWISEResolutionService {
   constructor(private environmentService: IEnvironmentService) {}
   public resolveBexWiseAPIBaseURL(): Promise<string> {
      return this.environmentService
         .getConfiguration()
         .then(
            (configuration: IEnvironmentConfiguration) =>
               configuration.bexWiseAPIBaseURL,
         );
   }
}
