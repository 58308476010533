import * as _ from "lodash";
export function dateFilter(
   startDate: Date,
   endDate: Date,
   date: Date,
): boolean {
   if (!_.isDate(startDate) || !_.isDate(endDate) || !_.isDate(date)) {
      if (!_.isDate(startDate)) {
         console.error("Start date is invalid");
      }
      if (!_.isDate(endDate)) {
         console.error("End date is invalid");
      }
      if (!_.isDate(date)) {
         console.error("Comparison date is invalid");
      }
      throw new Error("Invalid date provided to filter");
   } else {
      return date > startDate && date < endDate;
   }
}
