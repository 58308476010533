import React from "react";
import { ReactComponent as ExpandIcon } from "../../../../../../../assets/svgs/expand.svg";
import { ReactComponent as FirstViewIcon } from "../../../../../../../assets/svgs/firstView-zoom-icon.svg";
import { ReactComponent as ThirdViewIcon } from "../../../../../../../assets/svgs/third-view-zoom-icon.svg";
import { OpportunityLocatorViews } from "app/consts";
import { ZoomButton } from "./styled";
import { PRIMARY_COLORS } from "../../../../../../consts/colors";

interface ChangeLayoutButtonProps {
   selectedView: OpportunityLocatorViews;
   changeLayout: (arg: OpportunityLocatorViews) => void;
}

const ChangeLayoutButton: React.FC<ChangeLayoutButtonProps> = React.memo(
   ({ selectedView, changeLayout }) => {
      const renderLayoutIcon = React.useCallback((): JSX.Element => {
         switch (selectedView) {
            case OpportunityLocatorViews.LANDING_LAYOUT:
               return (
                  <FirstViewIcon
                     fill={PRIMARY_COLORS.SHUTTLE_GREY}
                     stroke={PRIMARY_COLORS.SHUTTLE_GREY}
                     onClick={() =>
                        changeLayout(
                           OpportunityLocatorViews.FULL_MAP_EXPANDED_LAYOUT,
                        )
                     }
                  />
               );
            case OpportunityLocatorViews.FULL_MAP_CONTRACTED_LAYOUT:
               return (
                  <ExpandIcon
                     fill={PRIMARY_COLORS.SHUTTLE_GREY}
                     stroke={PRIMARY_COLORS.SHUTTLE_GREY}
                     onClick={() =>
                        changeLayout(
                           OpportunityLocatorViews.FULL_MAP_EXPANDED_LAYOUT,
                        )
                     }
                  />
               );
            case OpportunityLocatorViews.FULL_MAP_EXPANDED_LAYOUT:
               return (
                  <ThirdViewIcon
                     fill={PRIMARY_COLORS.SHUTTLE_GREY}
                     stroke={PRIMARY_COLORS.SHUTTLE_GREY}
                     onClick={() =>
                        changeLayout(
                           OpportunityLocatorViews.FULL_MAP_CONTRACTED_LAYOUT,
                        )
                     }
                  />
               );
         }
      }, [selectedView]);

      return (
         <div>
            <ZoomButton type="button">{renderLayoutIcon()}</ZoomButton>
         </div>
      );
   },
);

export default ChangeLayoutButton;
