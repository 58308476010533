import React from "react";
import PhysicalExamGraphImg from "../../../../../../assets/images/physicalexam-graph.png";
import Image from "react-bootstrap/Image";
import { Col } from "react-bootstrap";
import { MeasureRow } from "./styled";
import {
   IDonorDonationDTO,
   IPhysicalExamData,
} from "../../../../../../services/bexWISE/donorDataService/IDonorDataService";

interface MeasuresMobileViewInterface {
   label: string;
   val: string;
   visibleData: IDonorDonationDTO;
}
const MeasuresMobileView: React.FC<MeasuresMobileViewInterface> = ({
   label,
   val,
   visibleData,
}) => {
   const displayVal = (
      eachDonation: IDonorDonationDTO,
      val: string,
   ): string => {
      const physicalExamResult: IPhysicalExamData = eachDonation.physicalExam;
      if (val === "bloodPressure") {
         return `${physicalExamResult.firstReading.bloodPressureSystolic}/${physicalExamResult.firstReading.bloodPressureDiastolic}`;
      }
      return physicalExamResult[val] || physicalExamResult.firstReading[val];
   };

   const DisplayItems = ({ eachDonation, val }): JSX.Element => {
      return <div>{displayVal(eachDonation, val)}</div>;
   };

   return (
      <MeasureRow>
         <Col xs={9} className="label-section-css">
            <Image
               src={PhysicalExamGraphImg}
               fluid
               alt="graph-physical-exam-page-img"
               className="graph-img-css"
            />
            <span className="label-span-css">{label}</span>
         </Col>
         <Col xs={3} className="value-section-css">
            <DisplayItems eachDonation={visibleData} val={val} />
         </Col>
      </MeasureRow>
   );
};

export default MeasuresMobileView;
