import { format } from "date-fns";
import React from "react";
import { Div } from "./styeld";

interface HorizontalLineProps {
   appointmentTime?: string | Date;
   children: React.ReactElement;
}

const HorizontalLine: React.FC<HorizontalLineProps> = ({
   appointmentTime,
   children,
}) => {
   return (
      <Div>
         <div className="d-flex flex-column border border-secondary position-relative">
            {appointmentTime && (
               <span className="hr-years fw-bolder">
                  {format(new Date(appointmentTime), "yyyy")}
               </span>
            )}
         </div>
         {children}
      </Div>
   );
};

export default HorizontalLine;
