import * as React from "react";
import { OpportunityLocatorViewContext } from "./context/OpportunityLocatorViewContext";
import { OpportunityLocatorViews } from "app/consts";
import { useMediaQuery } from "react-responsive";
import MobileLayout from "./Layouts/MobileLayout";
import Container from "react-bootstrap/Container";
import "./style.scss";
import { LandingLayout } from "./Layouts/LandingLayout/LandingLayout";
import { FullLayout } from "./Layouts/FullLayout/FullLayout";

export function OpportunitySearchComponent() {
   const { selectedView } = React.useContext(OpportunityLocatorViewContext);
   const isMobile: boolean = useMediaQuery({ maxWidth: 720 });
   const isMobileLayout: boolean = React.useMemo(() => {
      return (
         (selectedView === OpportunityLocatorViews.FULL_MAP_CONTRACTED_LAYOUT ||
            selectedView ===
               OpportunityLocatorViews.FULL_MAP_EXPANDED_LAYOUT) &&
         isMobile
      );
   }, [isMobile, selectedView]);

   const IsContainerShrink: boolean = React.useMemo(
      () =>
         !(
            selectedView === OpportunityLocatorViews.LANDING_LAYOUT && !isMobile
         ),
      [selectedView, isMobile],
   );

   const renderLayout = React.useCallback((): JSX.Element => {
      switch (true) {
         case isMobileLayout:
            return <MobileLayout />;
         case selectedView === OpportunityLocatorViews.LANDING_LAYOUT:
            return <LandingLayout />;
         case selectedView ===
            OpportunityLocatorViews.FULL_MAP_CONTRACTED_LAYOUT:
            return <FullLayout />;
         case selectedView === OpportunityLocatorViews.FULL_MAP_EXPANDED_LAYOUT:
            return <FullLayout />;
         default:
            return <LandingLayout />;
      }
   }, [selectedView, isMobileLayout]);

   function getViewClass() {
      switch (true) {
         case isMobileLayout:
            return "op-mobile-view";
         case selectedView === OpportunityLocatorViews.LANDING_LAYOUT:
            return "op-landing-layout";
         case selectedView ===
            OpportunityLocatorViews.FULL_MAP_CONTRACTED_LAYOUT:
            return "op-full op-full-map-contracted-layout";
         case selectedView === OpportunityLocatorViews.FULL_MAP_EXPANDED_LAYOUT:
            return "op-full op-full-map-expanded-layout";
         default:
            return "op-landing-layout";
      }
   }

   return (
      <Container
         className={`opportunity-search-container d-flex flex-column ${getViewClass()}`}
         fluid={IsContainerShrink}
      >
         {renderLayout()}
      </Container>
   );
}
