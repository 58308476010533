import React from "react";
import { FormSelect as FormSelectOptions } from "./styled";
import { IDonorDonationDTO } from "../../../../../../services/bexWISE/donorDataService/IDonorDataService";
import { displayDate } from "../../../utils/utils";

export interface FormSelectProps {
   list: IDonorDonationDTO[];
   name: string;
   value: string;
   handleChange: Function;
}

export const MonthDayDropDown: React.FC<FormSelectProps> = ({
   list,
   name,
   value,
   handleChange,
}) => {
   return (
      <>
         <FormSelectOptions
            name={name}
            value={value}
            onChange={e => handleChange(e.target.value)}
         >
            {list.map(item => {
               return (
                  <option key={item.date} value={item.date}>
                     {displayDate(item.date, false)}
                  </option>
               );
            })}
         </FormSelectOptions>
      </>
   );
};
