import styled from "styled-components";
import { Button, Nav } from "react-bootstrap";
import { ReactComponent as PersonVcardIcon } from "../../../../assets/svgs/person-vcard.svg";
import { ReactComponent as GearIcon } from "../../../../assets/svgs/gear.svg";
import { ReactComponent as CompassIcon } from "../../../../assets/svgs/compass-regular.svg";
import { ReactComponent as LogOutIcon } from "../../../../assets/svgs/logout.svg";
import { ReactComponent as ColumnsGapIcon } from "../../../../assets/svgs/columns-gap.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/svgs/search.svg";
import { ReactComponent as CalendarIcon } from "../../../../assets/svgs/calendar_month_solid.svg";
import { ReactComponent as DonationIcon } from "../../../../assets/svgs/donation.svg";
import { ReactComponent as ClipBoardIcon } from "../../../../assets/svgs/clipboard.svg";
import { ReactComponent as CircleUserSolid } from "../../../../assets/svgs/circle-user-solid.svg";
import { ReactComponent as ForwardArrowIcon } from "../../../../assets/svgs/forward-arrow.svg";
import { ReactComponent as PencilSquareIcon } from "../../../../assets/svgs/pencil-square.svg";
import { css } from "styled-components/macro";
import { PRIMARY_COLORS } from "../../../consts/colors";
import profileImg from "../../../../assets/images/profile-img.png";

interface StyledNavDivProps {
   containerWidth: string;
}



export const StyledNavDiv = styled("div")`
   border-right: 1px solid rgba(0, 0, 0, 0.175);
   background-color: white !important;
   height: 100%;
   left: 0;
   z-index: 666;

   .active{
      .userName {
          > i {
            transform: rotate(180deg);
         }
      }
   }

   .profile-name-section {
      display: block;
      text-decoration: none;
      text-align: center;
      padding: 20px 0;
      background-image: url(${profileImg});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .up-arrow {
         transform: rotate(270deg);
      }
      .down-arrow {
         transform: rotate(90deg);
      }

      &: hover {
         background-color: rgba(0, 0, 0, 0.06);
      }

      .userName {
         color: #515C66;
         margin: 10px 0px;

         .mr-2 {
            margin-right: 10px;
         }

         .pi-small {
            font-size: 14px;
         }

         i {
            transition: transform 0.2s;
         }
      }
   }
   .selected-link {
      background-color: ${PRIMARY_COLORS.HAWKES_BLUE};
   }



`;

export const StyledNav = styled(Nav)`
   display: block;

   .navbar-upper-section {
      background-color: rgba(0, 0, 0, 0.03);
      border-top: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
   }

   .navbar-lower-section {
      padding-top: 1rem;
      border-top: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
   }

   .nav-each-item {
      display: flex;
      align-items: center;
      gap: 15px;
      color: #515C66;
      font-size: 13px;
      font-weight: 500;
      padding: 1rem;

      &: hover {
         background-color: rgba(0, 0, 0, 0.06);
      }

      &:focus {
         border-color: #CFE4FF;
      }
   }

   .questionnaire{
      background-color: #eaf1fe;
      color: #515C66;
      margin: 15px;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
   }

   .questionnaireOverlay {
      border-radius: 15px;
      padding: 15px 25px;
   }

   .questionnaireSlim{
      border-radius: 50%;
      margin: 10px;
   }

   #sidebar-wrapper {
      min-height: 100vh !important;
      width: 100vw;
      margin-left: -1rem;
      -webkit-transition: margin 0.25s ease-out;
      -moz-transition: margin 0.25s ease-out;
      -o-transition: margin 0.25s ease-out;
      transition: margin 0.25s ease-out;
   }
   #sidebar-wrapper .sidebar-heading {
      padding: 0.875rem 1.25rem;
      font-size: 1.2rem;
   }

   #page-content-wrapper {
      min-width: 0;
      width: 100%;
   }

`;

const IconImgStyles = css`
   width: 22px;
   height: 22px;
   margin-left: 2px;
   fill: ${PRIMARY_COLORS.BATTLESHIP_GREY} !important;
`;
export const PersonVcardImg = styled(PersonVcardIcon)`
   ${IconImgStyles}
`;

export const GearImg = styled(GearIcon)`
   ${IconImgStyles}
`;

export const CompassImg = styled(CompassIcon)`
   ${IconImgStyles}
`;
export const LogOutImg = styled(LogOutIcon)`
   ${IconImgStyles}
`;
styled(ColumnsGapIcon)`
   ${IconImgStyles};
   transform: rotate3d(1, 0, 0, 180deg);
`;
export const SearchImg = styled(SearchIcon)`
   ${IconImgStyles}
`;
export const CalendarImg = styled(CalendarIcon)`
   ${IconImgStyles}
`;
export const DonationImg = styled(DonationIcon)`
   width: 28px;
   height: 28px;
   flex-shrink: 0;
   fill: ${PRIMARY_COLORS.BATTLESHIP_GREY};
`;
export const ClipBoardImg = styled(ClipBoardIcon)`
   ${IconImgStyles};
`;

export const CircleUserSolidImg = styled(CircleUserSolid)`
   width: 56px;
   height: 56px;
   cursor: pointer;
   fill: ${PRIMARY_COLORS.BATTLESHIP_GREY};
`;

export const CircleUserSmallImg = styled(CircleUserSolid)`
   width: 32px;
   height: 32px;
   cursor: pointer;
   fill: ${PRIMARY_COLORS.BATTLESHIP_GREY};
`;
export const ArrowIcon = styled(ForwardArrowIcon)`
   fill: ${PRIMARY_COLORS.BATTLESHIP_GREY};
   margin-left: 10px;
   cursor: pointer;
`;

export const PencilImg = styled(PencilSquareIcon)`
   width: 20px;
   height: 20px;
   margin-left: -6px;
   transform: rotate(90deg);
   flex-shrink: 0;
`;

export const QuestionnaireButton = styled(Button)`
   span {
      font-size: 12px !important;
   }
   margin-top: 10px;
   margin-left: 28px;
   border-radius: 15px;
   display: flex;
   gap: 15px;
   align-items: center;
   background-color: ${PRIMARY_COLORS.BLUISH} !important;
   border-color: ${PRIMARY_COLORS.BLUISH} !important;
`;
