import { IBloodDrive } from "types/IBloodDrive";
import { IDonorDonationDTO } from "services/bexWISE/donorDataService/IDonorDataService";

export const alphabeticalSorting = (data: IBloodDrive[]): IBloodDrive[] => {
   data.sort(function (a: IBloodDrive, b: IBloodDrive) {
      if (a.location.name < b.location.name) {
         return -1;
      }
      return 1;
   });

   return data;
};

export const distanceSorting = (data: IBloodDrive[]): IBloodDrive[] => {
   data.sort(function (a: IBloodDrive, b: IBloodDrive) {
      if (
         a!.location!.distanceFromOriginMeters! <
         b.location.distanceFromOriginMeters!
      ) {
         return -1;
      }
      return 1;
   });

   return data;
};

export const chronologicalOrder = (data: IBloodDrive[]): IBloodDrive[] => {
   data.sort((a: IBloodDrive, b: IBloodDrive) => {
      const startTimeA = new Date(a.startTime);
      const startTimeB = new Date(b.startTime);

      return startTimeA.getTime() - startTimeB.getTime();
   });
   return data;
};

export const procedureSorting = (
   data: IDonorDonationDTO[],
): IDonorDonationDTO[] => {
   data.sort(function (a: IDonorDonationDTO, b: IDonorDonationDTO) {
      if (a.donationProcedureDescription < b.donationProcedureDescription!) {
         return -1;
      }
      return 1;
   });

   return data;
};

export const locationSorting = (
   data: IDonorDonationDTO[],
): IDonorDonationDTO[] => {
   data.sort(function (a: IDonorDonationDTO, b: IDonorDonationDTO) {
      if (a.location.name < b.location.name!) {
         return -1;
      }
      return 1;
   });

   return data;
};

export const chronologicalOrderForDonation = (
   data: IDonorDonationDTO[],
): IDonorDonationDTO[] => {
   data.sort((a: IDonorDonationDTO, b: IDonorDonationDTO) => {
      const startTimeA = new Date(a.date);
      const startTimeB = new Date(b.date);

      return startTimeB.getTime() - startTimeA.getTime();
   });
   return data;
};
