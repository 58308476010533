import { createContext } from "react";
import { IEnvironmentProperties } from "../../../services/environmentService/IEnvironmentProperties";
import { IEnvironmentConfiguration } from "../../../services/environmentService/IEnvironmentConfiguration";
import { ICenterData } from "../../../services/bexWISE/centerDataService/ICenterDataService";

export interface IEnvironmentContext {
   environmentProperties: IEnvironmentProperties | null;
   environmentConfig: IEnvironmentConfiguration | null;
   centerData: ICenterData | null;
   logoSrc: string | null;
   bannerSrc: string | null;
}

export const defaultEnvironmentContext: IEnvironmentContext = {
   environmentProperties: null,
   environmentConfig: null,
   centerData: null,
   logoSrc: null,
   bannerSrc: null,
};

export const EnvironmentContext = createContext<IEnvironmentContext>(
   defaultEnvironmentContext,
);
