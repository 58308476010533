import styled from "styled-components";
import { Row } from "react-bootstrap";
import { PRIMARY_COLORS } from "../../../../../consts/colors";

export const MeasureRow = styled(Row)`
   margin-right: 0px;
   margin-left: 0px;
   .label-section-css {
      display: flex;
      align-items: center;
      gap: 40px;
      padding: 15px 20px;
      border-bottom: 1px solid ${PRIMARY_COLORS.PLATINUM};
      .graph-img-css {
         width: 20px;
         height: 18px;
      }
      .label-span-css {
         font-size: 12px !important;
         font-weight: bold;
      }
   }
   .value-section-css {
      padding-left: 0px;
      padding-right: 0px;
      display: flex;
      border-bottom: 1px solid ${PRIMARY_COLORS.PLATINUM};
      .arrow-section {
         width: 30px;
      }
      .selected-result {
         padding: 15px 0px;
         background-color: ${PRIMARY_COLORS.LILY_WHITE};
         width: 80px;
         text-align: center;
         border-bottom: 1px solid ${PRIMARY_COLORS.LILY_WHITE};
      }
      .unselected-result {
         padding: 15px 0px;
         display: flex;
         .each-result-section {
            width: 80px;
            text-align: center;
         }
      }
      font-size: 12px;
      font-weight: bold;
   }
`;
