import styled from "styled-components";
import { ReactComponent as CalendarMonthSolid } from "../../../../../assets/svgs/calendar_month_solid.svg";
import { ReactComponent as MoistureIcon } from "../../../../../assets/svgs/moisture.svg";
import { ReactComponent as DonationIcon } from "../../../../../assets/svgs/donation.svg";
import { PRIMARY_COLORS } from "../../../../consts/colors";
import { css } from "styled-components/macro";

const baseIconCss = css`
   width: 20px;
   height: 20px;
   fill: ${PRIMARY_COLORS.WHITE};
`;
export const CalendarMonthSolidImg = styled(CalendarMonthSolid)`
   ${baseIconCss};
`;

export const MoistureImg = styled(MoistureIcon)`
   ${baseIconCss};
`;

export const DonationImg = styled(DonationIcon)`
   ${baseIconCss};
`;
