import { AbstractBloodDriveDataService } from "./AbstractBloodDriveDataService";
import { IBloodDrive } from "../../../types/IBloodDrive";
import {
   IDriveAppointmentAvailability,
   IGetDrivesRequest,
} from "./IBloodDriveDataService";

export class BloodDriveDataService extends AbstractBloodDriveDataService {
   // driveDate must be in the form YYYY-MM-DD
   public getDriveAppointments(
      driveId: string,
      driveDate: string,
   ): Promise<IDriveAppointmentAvailability[]> {
      return this.getBex<IDriveAppointmentAvailability[]>(
         `drives/${driveId}/appointments`,
         { driveId, driveDate },
      );
   }

   // driveDate must be in the form YYYY-MM-DD
   public getDrive(driveId: string, driveDate: string, origin: string): Promise<IBloodDrive> {
      // Added the origin parameter needed for the API call (SCREDP-77)
      return this.getBex<IBloodDrive>(
         `drives/${driveId}?driveDate=${driveDate}&origin=${origin}`,
      );
   }

   public getDrives({
      origin,
      accountName,
      searchRadius,
   }: IGetDrivesRequest): Promise<IBloodDrive[]> {
      return this.getBex<IBloodDrive[]>(`drives`, {
         origin,
         accountName,
         searchRadius,
      }).catch(() => {
         return [];
      });
   }
}
