import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { ReactComponent as ChevronUp } from "../../../../../../assets/svgs/caret-up-fill.svg";
import { ReactComponent as ChevronDown } from "../../../../../../assets/svgs/caret-down-fill.svg";
import { PRIMARY_COLORS } from "../../../../../consts/colors";

export const ChevronUpIcon = styled(ChevronUp)`
   width: 28px;
`;

export const ChevronDownIcon = styled(ChevronDown)`
   width: 28px;
`;

export const ChevronDownButtonContainer = styled(Button)`
   border: 1px solid ${PRIMARY_COLORS.QUILL_GREY};
   border-top: 0px;
   border-radius: 0px 2px 5px 0px;
   padding: 0;
`;

export const ChevronUpButtonContainer = styled(Button)`
   border: 1px solid ${PRIMARY_COLORS.QUILL_GREY};
   border-radius: 0px 5px 2px 0px;
   padding: 0;
`;
