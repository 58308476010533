import React, { useContext, useEffect, useState } from "react";
import { DonorCardDiv } from "./styled";
import { IDonorDTO } from "../../../../../types/IDonorDTO";
import { AuthContext } from "../../../../contexts/auth/authContext";
import { EnvironmentContext } from "../../../../contexts/environment/environmentContext";
import { DonorCardImage } from "../DonorCardImage/DonorCardImage";

interface DonorCardProps {
   profileData: IDonorDTO;
}

const DonorCard: React.FC<DonorCardProps> = ({ profileData }) => {
   const { logoSrc, centerData } = useContext(EnvironmentContext);
   const [name, setName] = useState<string>("");
   const { userDonorId } = useContext(AuthContext);

   useEffect(() => {
      if (profileData.firstName && profileData.lastName) {
         setName(`${profileData.firstName} ${profileData.lastName}`);
      }
   }, [profileData]);

   return (
      <DonorCardDiv>
         <div className="header-css">
            {logoSrc && <img src={logoSrc} className="logo-css" alt="logo" />}
            <div className="logo-section-css">
               <span className="bci-css">{centerData?.name.toUpperCase()}</span>
               <span className="di-css">DONOR IDENTIFICATION</span>
            </div>
         </div>
         <div className="content-css">
            <div className="content-name-section">
               <div className="name-css">{name.toUpperCase()}</div>
               <div className="blood-type-css">
                  {profileData?.bloodType?.symbol}
               </div>
            </div>
            <div className="content-qr-code-section">
               <div>
                  <DonorCardImage />
               </div>
               <div className="donor-id-css">{userDonorId}</div>
            </div>
         </div>
      </DonorCardDiv>
   );
};

export default DonorCard;
