import React, { ReactElement, useContext } from "react";
import { Section } from "../../../../components/Section/Section";
import { RiHistoryFill } from "react-icons/ri";
import MyDonationSlots from "../MyDonationSlots";
import {
   DonationContext,
   IDonationContext,
} from "../../context/DonationContext";
import DonationNotFound from "../DonationNotFound";
import { CommonContext } from "../../../../contexts/common/commonContext";
import { MyDonationsFilters } from "../MyDonationsFilters/MyDonationsFilters";

export function MyDonationsBody(): ReactElement {
   const { donations } = useContext<IDonationContext>(DonationContext);
   const { isLoading } = useContext(CommonContext);

   return (
      <>
         {donations.length ? (
            <Section
               header={{
                  title: "Donation History",
                  icon: <RiHistoryFill fill="white" size={20} />,
               }}
            >
               <MyDonationsFilters />
               <MyDonationSlots />
            </Section>
         ) : null}
         {!isLoading && !donations.length && <DonationNotFound />}
      </>
   );
}
