import { ILocation } from "types/ILocation";

export const getStrictFormattedAddress = ({
   name = "",
   addressOne,
   addressTwo,
   city,
   state,
   zipCode,
}: ILocation) => {
   const separator = (value: string) => (value ? `${value},` : "");
   return `${separator(addressOne)} ${separator(addressTwo)} ${separator(
      city,
   )} ${state} ${zipCode}`;
};
