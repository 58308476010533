import React, { useContext, useState } from "react";
import { PastAppointmentDesktop } from "./PastAppointmentDesktop";
import { useMediaQuery } from "react-responsive";
import PastAppointmentMobile from "./PastAppointmentMobile";
import { PastAppointmentFiltersDesktop } from "./components/PastAppointmentsFiltersDesktop";
import { NoPastAppointments } from "../../styled";
import { Section } from "app/components/Section/Section";
import { ReactComponent as CalendarIcon } from "../../../../../assets/svgs/calender.svg";
import { MobileMoreFiltersButton } from "../../../../components/MobileMoreFiltersButton/MobileMoreFiltersButton";
import { MyAppointmentsPageContext } from "../../context/MyAppointmentsPageContext";
import { FilteredPastAppointmentsContext } from "./context/FilteredPastAppointmentsContext";
import { FiltersModal } from "../../../../components/FiltersModal/FiltersModal";
import { MyAppointmentsMoreFiltersModalBody } from "./components/PastAppointmentsFilterMobileModal/MyAppointmentsMoreFiltersModalBody";
import { LocationOption } from "../../../../consts";
import {
   defaultAppointmentTypes,
   defaultDonorStatus,
   defaultSortedBy,
} from "./pastAppointmentFilterDefaults";
import {
   getDefaultEndDate,
   getDefaultStartDate,
} from "../../../../../utils/defaultDates";

const PastAppointmentsSection: React.FC = () => {
   const [modalShow, setModalShow] = useState(false);
   const isMobile: boolean = useMediaQuery({ maxWidth: 1000 });
   const { pastAppointments } = useContext(MyAppointmentsPageContext);
   const { filters, updateFilters, sortedFilteredAppointments } = useContext(
      FilteredPastAppointmentsContext,
   );

   return (
      <Section
         header={{
            title: "Past Appointments",
            icon: <CalendarIcon fill={"white"} />,
         }}
      >
         {pastAppointments && pastAppointments.length !== 0 && (
            <>
               {isMobile ? (
                  <MobileMoreFiltersButton onClick={() => setModalShow(true)} />
               ) : (
                  <PastAppointmentFiltersDesktop />
               )}
            </>
         )}
         {sortedFilteredAppointments !== null &&
         sortedFilteredAppointments.length !== 0 ? (
            <>
               {sortedFilteredAppointments.map((appointment, index) => {
                  return (
                     <React.Fragment key={appointment.appointmentId}>
                        {isMobile ? (
                           <PastAppointmentMobile
                              appointments={sortedFilteredAppointments}
                              index={index}
                              appointment={appointment}
                           />
                        ) : (
                           <PastAppointmentDesktop
                              appointments={sortedFilteredAppointments}
                              index={index}
                              appointment={appointment}
                           />
                        )}
                     </React.Fragment>
                  );
               })}{" "}
            </>
         ) : (
            <NoPastAppointments
               className={isMobile ? "mobile-section-css" : ""}
            >
               <b>No donations to display</b>
            </NoPastAppointments>
         )}
         <FiltersModal
            fullScreen={isMobile}
            showClose={modalShow}
            title={"Filters"}
            FilterRenderer={props => (
               <MyAppointmentsMoreFiltersModalBody {...props} />
            )}
            initialFilterValue={filters}
            clearAllValues={{
               selectedLocationSource: LocationOption.ALL,
               selectedAppointmentTypes: defaultAppointmentTypes,
               startDate: getDefaultStartDate(
                  pastAppointments || [],
                  "appointmentDateTime",
               ),
               endDate: getDefaultEndDate(
                  pastAppointments || [],
                  "appointmentDateTime",
               ),
               selectedDonorStatuses: defaultDonorStatus,
               sortBySelected: defaultSortedBy,
            }}
            onFilterApplied={updatedValues => {
               updateFilters(updatedValues);
               setModalShow(false);
            }}
            show={modalShow}
            onHide={() => {
               setModalShow(false);
            }}
         />
      </Section>
   );
};

export default PastAppointmentsSection;
