import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { PRIMARY_COLORS } from "../../../consts/colors";

export const SignInContainer = styled(Container)`
   &.mobile-css {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
   }
   &.desktop-css {
      width: 85%;
   }
   .forgot-password-group {
      display: flex;
      justify-content: flex-end;
      .forgot-password-link {
         color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
         font-size: 13px;
         text-decoration: none;
      }
   }
   .remember-label-group {
      margin-left: 8px;
      .remember-label {
         font-size: 13px;
      }
   }
   .sign-in-button {
      width: 100%;
      .box-arrow-css {
         width: 22px;
         height: 22px;
         margin-left: 8px;
      }
   }

   .create-account-group {
      display: flex;
      align-items: center;
      .create-account-link {
         text-decoration: none;
         color: ${PRIMARY_COLORS.MUTED_BLUE};
         font-size: 14px;
      }
   }
`;
export const SignInModalAlert = styled(Alert)`
   &.mobile-alert-css {
      margin: 40px 20px 0px 20px;
   }
   &.desktop-alert-css {
      margin: 0px 40px 40px 40px;
   }
`;
