import styled from "styled-components";

export const Div = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   border-radius: 10px;
   padding: 8px 10px;
   min-width: 120px;
   align-items: center;
   gap: 10px;
   cursor: pointer;
   color: #6c757d;
   font-weight: 500;
   box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
`;
