import { SingleSelect } from "../../../../Form/Inputs/Generic/SingleSelect/SingleSelect";
import { locationOrAccountDropdownOptions } from "../../../../../consts";
import React, { useContext } from "react";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../../context/OpportunitySearchContext";

export function LocationOrAccountDropdown() {
   const { filters, updateFilter } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );
   return (
      <SingleSelect
         values={locationOrAccountDropdownOptions}
         selectedValue={
            locationOrAccountDropdownOptions.find(
               o => o.value === filters?.searchType,
            )!
         }
         onValueSelected={value => { updateFilter({ searchType: value.value }); }}
         border={false}
      />
   );
}
