import React, { useContext, useState } from "react";
import {
   AppointmentCardHeaderDiv,
   Body,
   Header,
   StyledCalendarSolid,
   StyledConfirmAppointmentCard,
   StyledDrop,
   StyledGeoLocation,
   Title,
} from "./styled";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import {
   AppointmentTypesDropdownLabels,
   ModalPageNames,
} from "../../../../consts";
import SignInModal from "../../../../components/AuthenticationModals/SignInModal";
import { AuthContext } from "../../../../contexts/auth/authContext";
import { AuthStatus } from "../../../../../services/cognitoService/AuthStatus";
import { getAppointmentDataServiceInstance } from "../../../../../services/bexWISE/appointmentDataService/getAppointmentDataServiceInstance";
import { formatDateValue } from "../../hooks/useAppointmentData";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
   IAppointmentDataService,
   IBookAppointmentRequest,
   IRescheduleAppointmentRequest,
} from "../../../../../services/bexWISE/appointmentDataService/IAppointmentDataService";
import { IConfirmedAppointmentDetails } from "../../../../../types/IConfirmedApponitmentDetails";
import { AppointmentKinds } from "../../../../consts/AppointmentKinds";
import { ROUTE_PATHS } from "../../../../consts/RoutePaths";
import { UserContext } from "../../../../contexts/user/userContext";

function ConfirmAppointmentSection({
   card,
   appointmentTypeDisplayed,
   appointmentType,
   timeSelected,
   selectedDate,
   drive_id,
   timeSlotSelectedId,
   isNotEligible,
   eligibleDateForSelectedType,
   reschedulingAppointmentId,
}) {
   const [modalShow, setModalShow] = useState<boolean>(false);
   const [pageNameSent, setPageNameSent] = useState<string>("");
   const { authStatus } = useContext(AuthContext);
   const { profileData, userDonorId } = useContext(UserContext);
   const isLoggedOut: boolean = authStatus === AuthStatus.SignedOut;
   const navigate: NavigateFunction = useNavigate();

   const confirmedAptDetails: IConfirmedAppointmentDetails = {
      addressOne: card?.location?.addressOne,
      name: card?.location?.name,
      timeSelected: timeSelected,
      dateSelected: selectedDate,
      city: card?.location?.city,
      state: card?.location?.state,
      zipCode: card?.location?.zipCode,
      appointmentKind: isNotEligible
         ? AppointmentKinds.NOT_ELIGIBLE
         : isLoggedOut
            ? AppointmentKinds.REQUESTED
            : AppointmentKinds.CONFIRMED,
      userFirstName: profileData?.firstName || null,
      driveId: drive_id,
      driveDate: selectedDate,
      appointmentType: appointmentType,
      appointmentTime: timeSelected,
      appointmentTypeDisplayed: appointmentTypeDisplayed,
      eligibleDateForSelectedType: eligibleDateForSelectedType,
      appointmentSlotId: timeSlotSelectedId,
   };

   const confirmAppointmentFunction = (): void => {
      setModalShow(true);
      setPageNameSent(
         isLoggedOut
            ? ModalPageNames.INITIAL
            : ModalPageNames.APPOINTMENT_CONFIRMATION,
      );
   };

   const rescheduleAppointment = async (
      rescheduleAppointmentRequest: IRescheduleAppointmentRequest,
      reschedulingAppointmentId: number,
   ): Promise<void> => {
      try {
         const appointmentDataService: IAppointmentDataService =
            getAppointmentDataServiceInstance();
         await appointmentDataService.rescheduleAppointment(
            rescheduleAppointmentRequest,
            reschedulingAppointmentId,
         );
         sessionStorage.setItem(
            "confirmedAppointmentDetails",
            JSON.stringify(confirmedAptDetails),
         );
         navigate(`${ROUTE_PATHS.LANDING_PATH}`);
      } catch (err) {
         sessionStorage.removeItem("confirmedAppointmentDetails");
         throw err;
      }
   };

   const bookAppointment = async (
      bookAppointmentRequest: IBookAppointmentRequest,
   ): Promise<void> => {
      try {
         const appointmentDataService: IAppointmentDataService =
            getAppointmentDataServiceInstance();
         await appointmentDataService.bookAppointment(bookAppointmentRequest);
         sessionStorage.setItem(
            "confirmedAppointmentDetails",
            JSON.stringify(confirmedAptDetails),
         );
         navigate(`${ROUTE_PATHS.LANDING_PATH}`);
      } catch (err) {
         sessionStorage.removeItem("confirmedAppointmentDetails");
         throw err;
      }
   };
   const continueButtonFunction = (): void => {
      if (isLoggedOut || isNotEligible) {
         confirmAppointmentFunction();
      } else {
         if (userDonorId) {
            const bookOrReSchAptRequest: IBookAppointmentRequest = {
               donorId: userDonorId,
               driveId: drive_id,
               driveDate: formatDateValue(selectedDate),
               appointmentSlotId: timeSlotSelectedId,
            };
            if (reschedulingAppointmentId) {
               rescheduleAppointment(
                  bookOrReSchAptRequest,
                  Number(reschedulingAppointmentId),
               );
            } else {
               bookAppointment(bookOrReSchAptRequest);
            }
         }
      }
   };

   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });
   const isAccountNameSameAsLocationName = card?.location?.name === card?.account?.accountName;

   return (
      <div className="mb-3 mt-4">
         <Title className="mb-3">Confirm Selected Appointment :</Title>

         <StyledConfirmAppointmentCard>
            <Header>
               <AppointmentCardHeaderDiv>
                  <StyledGeoLocation />
                  <Title>
                     {card?.account?.accountName || ""}
                  </Title>
               </AppointmentCardHeaderDiv>
            </Header>
            <Body as={Row}>
               <Col
                  xs={12}
                  sm={12}
                  md={4}
                  className={isMobile ? "location-mobile-css" : ""}
               >
                  {/* If the account name and the location are the same, only show the account name (SCREDP-63) */}
                  {!isAccountNameSameAsLocationName && (<>
                     {card?.location?.name || ""}<br />
                  </>)}
                  {card?.location?.addressOne || ""}
                  {card?.location?.addressTwo && <>
                     ,<br /> ${card?.location?.addressTwo}
                  </>
                  }
                  <br />
                  {card?.location?.city || ""}, {card?.location?.state || ""}{" "}
                  {card?.location?.zipCode || ""}
               </Col>
               <Col xs={12} sm={12} md={6}>
                  <div className="align-items-center-css">
                     <StyledCalendarSolid />
                     <b>When:</b>
                     <span className="margin-left-css">
                        {new Date(selectedDate).toLocaleDateString("en-US")} @{" "}
                        {new Date(
                           timeSelected.replace("Z", ""),
                        ).toLocaleTimeString("en-US", {
                           hour: "2-digit",
                           minute: "2-digit",
                        })}
                     </span>
                  </div>
                  <div className="mb-2 align-items-center-css">
                     <StyledDrop />
                     <b>Donation:</b>
                     <span className="margin-left-css">
                        {AppointmentTypesDropdownLabels[appointmentType] ||
                           appointmentTypeDisplayed}
                     </span>
                  </div>
               </Col>
               <Col xs={12} sm={12} md={2} className="continue-btn-div">
                  <Button
                     onClick={() => continueButtonFunction()}
                     variant="danger"
                     className="continue-confirm-btn"
                  >
                     Continue
                  </Button>
               </Col>
            </Body>
         </StyledConfirmAppointmentCard>
         <SignInModal
            show={modalShow}
            setModalShow={setModalShow}
            pageNameSent={pageNameSent}
            confirmedAptDetails={confirmedAptDetails}
         />
      </div>
   );
}

export default ConfirmAppointmentSection;
