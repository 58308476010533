import React from "react";
import { ProfileSectionDiv } from "./styled";
import PhysicalExamProfileImg from "../../../../../../assets/images/physical-exam.png";
import Image from "react-bootstrap/Image";
import { IDonorDTO } from "../../../../../../types/IDonorDTO";

interface PhysicalExamProfileInterface {
   profileData: IDonorDTO;
   weight: number;
   height: number;
}

export const getAge = (dateString: string | number | Date): number => {
   const today: Date = new Date();
   const birthDate: Date = new Date(dateString);
   let age: number = today.getFullYear() - birthDate.getFullYear();
   const m: number = today.getMonth() - birthDate.getMonth();
   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
   }
   return age;
};
const ProfileSection: React.FC<PhysicalExamProfileInterface> = ({
   profileData,
   weight,
   height,
}) => {
   return (
      <ProfileSectionDiv>
         <Image
            src={PhysicalExamProfileImg}
            fluid
            alt="profile-physical-exam-page-img"
            className="profile-img-css"
         />
         <div className="middle-section">
            <div className="name-section">
               {profileData.firstName?.toUpperCase()}{" "}
               {profileData.lastName?.toUpperCase()}
            </div>
            <div className="details-section">
               <div>{getAge(profileData.birthDate)} years old</div>
               <div>|</div>
               <div>{profileData.sex.description}</div>
               <div>|</div>
               <div>{height}</div>
               <div>|</div>
               <div>{weight} lbs</div>
            </div>
         </div>
      </ProfileSectionDiv>
   );
};

export default ProfileSection;
