import { createContext } from "react";

export interface IAppContext {
   host: string;
}

const defaultAppContext: IAppContext = {
   host: "localhost",
};

export const AppContext = createContext<IAppContext>(defaultAppContext);
