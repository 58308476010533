import React, { ReactElement } from "react";
import "./style.scss";
export interface ISectionContainerProps {
   children: React.ReactElement | React.ReactElement[];
   className?: string;
}

export function SectionContainer(
   props: ISectionContainerProps,
): ReactElement<ISectionContainerProps> {
   return (
      <div className={`section-container ${props.className}`}>
         {props.children}
      </div>
   );
}
