import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import CommonHeader from "../../../../components/CommonHeader";
import { NoDonationsContainer } from "./styled";
import { getAge } from "../PhysicalExamsDesktopView/ProfileSection";
import PhysicalExamNoResultImg from "../../../../../assets/images/physical-exam-no-result.png";
import PhysicalExamMakeAnAptImg from "../../../../../assets/images/physical-exam-make-an-appointment.png";
import Image from "react-bootstrap/Image";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { IDonorDTO } from "../../../../../types/IDonorDTO";
import { Section } from "../../../../components/Section/Section";
import { ROUTE_PATHS } from "../../../../consts/RoutePaths";

interface NoDonationProps {
   isMobile: boolean;
   profileData: IDonorDTO;
}

const NoDonationsComponent: React.FC<NoDonationProps> = ({
   isMobile,
   profileData,
}) => {
   const navigate: NavigateFunction = useNavigate();
   return (
      <NoDonationsContainer className={isMobile ? "mobile-css" : "desktop-css"}>
         <Row>
            <Col xs={12} lg={6}>
               <Section className="physical-exam-css">
                  <CommonHeader title="PHYSICAL EXAM" />
                  <Row className="profile-section">
                     <Col xs={12} lg={7} className="profile-content-section">
                        <div className="middle-section">
                           <div className="name-section">
                              {profileData.firstName?.toUpperCase()}{" "}
                              {profileData.lastName?.toUpperCase()}
                           </div>
                           <div className="details-section">
                              <div>
                                 {getAge(profileData.birthDate)} years old
                              </div>
                              <div>|</div>
                              <div>{profileData.sex.description}</div>
                           </div>
                        </div>
                        <hr />
                        <div className="bottom-section-css">
                           No Physical Exam Result to display.
                        </div>
                     </Col>
                     {!isMobile && (
                        <Col xs={12} lg={5}>
                           <Image
                              src={PhysicalExamNoResultImg}
                              fluid
                              alt="profile-no-physical-exam-page-img"
                              className="profile--no-physical-exam-img-css"
                           />
                        </Col>
                     )}
                  </Row>
               </Section>
            </Col>
            <Col xs={12} lg={6}>
               <div className="make-an-appointment-section">
                  <div className="btn-section">
                     <Button
                        className="btn-css"
                        onClick={() => navigate(ROUTE_PATHS.LANDING_PATH)}
                     >
                        Make an appointment
                     </Button>
                  </div>

                  <Image
                     src={PhysicalExamMakeAnAptImg}
                     fluid
                     alt="make-an-appointment-img"
                     className="make-an-apt-img-css"
                  />
               </div>
            </Col>
         </Row>
      </NoDonationsContainer>
   );
};

export default NoDonationsComponent;
