import React, { useContext, useState } from "react";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import markerIcon from "../../../../../../assets/images/marker.png";
import ChangeLayoutButton from "../components/ChangeLayoutButton";
import { useMediaQuery } from "react-responsive";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "app/components/OpportunitySearchComponent/context/OpportunitySearchContext";
import { OpportunityLocatorViewContext } from "app/components/OpportunitySearchComponent/context/OpportunityLocatorViewContext";
import { LocationOrAccountOptions } from "../../../../../consts";
import { CommonContext } from "../../../../../contexts/common/commonContext";
import { IBloodDrive } from "../../../../../../types/IBloodDrive";
import BloodDriveMarkerPopup from "../components/BloodDriveMarkerPopup";
import * as _ from "lodash";
const containerStyle = {
   width: "100%",
   height: "100%",
};

const Map = () => {
   const { defaultOriginZipCode } = useContext(CommonContext);
   const {
      filters,
      selectedDriveId: selectedDrive,
      scrolledItems,
      setSelectedDrive,
   } = useContext<IOpportunitySearchContext>(OpportunitySearchContext);
   const { selectedView, changeLayout, isScrollLoading } = React.useContext(
      OpportunityLocatorViewContext,
   );
   const [map, setMap] = useState<google.maps.Map>();
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });
   const [mapCenterPoint, setMapCenterPoint] = useState<null | { lat: number; lng: number }>(
      null,
   );

   const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
   });

   React.useEffect(() => {
      // Center the map over the location of the first drive in the list
      if (scrolledItems.length) {
         const firstItemLocation = scrolledItems?.[0].location;
         if (firstItemLocation?.latitude && firstItemLocation?.longitude) {
            const defaultMapCenterPoint = { lat: firstItemLocation?.latitude ?? 0, lng: firstItemLocation?.longitude ?? 0 };
            setMapCenterPoint(defaultMapCenterPoint);
         }
         else {
            console.error("Location(s) do not have a latitude and longitude defined; Google Map cannot be displayed");
         }
      }
   },
      [scrolledItems],
   );

   const handleMarkerClick = appointment => {
      const element: HTMLElement | null = document.getElementById(
         appointment.resultId,
      );
      setSelectedDrive(appointment.resultId);
      element?.scrollIntoView({
         behavior: "smooth",
         block: "center",
      });
   };

   React.useEffect(() => {
      const handleClick = (event: any) => {
         if (!event?.pa?.path.some(el => el?.className?.includes("gm-style"))) {
            setSelectedDrive(null);
         }
      };

      if (map) {
         map.addListener("click", handleClick);
      }
   }, [map, setSelectedDrive]);

   const hideZoomControlsOnMap = React.useMemo(() => !isMobile, [isMobile]);
   const isMapLoadedAndHasData = isLoaded && scrolledItems?.length && mapCenterPoint;

   const onGetDirectionsClicked = (appointment: IBloodDrive) => {
      const origin: string =
         filters?.searchType === LocationOrAccountOptions.LOCATION
            ? filters.searchOrigin
            : `${defaultOriginZipCode}`;
      const destination: string = `${appointment.location.latitude},${appointment.location.longitude}`;
      window.open(
         `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=car`,
      );
   };

   function getAppointmentLatLng(appointment): { lat: number; lng: number } {
      return {
         lat: Number(appointment.location.latitude!),
         lng: Number(appointment.location.longitude!),
      };
   }

   return isMapLoadedAndHasData ? (
      <>
         <GoogleMap mapContainerClassName="mapBorder"
            mapContainerStyle={containerStyle}
            center={mapCenterPoint}
            zoom={11}
            options={{
               streetViewControl: false,
               fullscreenControl: false,
               mapTypeControl: false,
               zoomControl: hideZoomControlsOnMap,
            }}
            onLoad={map => {
               setMap(map);
            }}
         >
            {!isScrollLoading && (
               <ChangeLayoutButton
                  selectedView={selectedView}
                  changeLayout={changeLayout}
               />
            )}

            {_.uniqBy(scrolledItems, i => {
               return i.location.name;
            }).map((scrolledItem, index) => (
               <MarkerF
                  key={`${scrolledItem.account.accountCode}-${index}`}
                  position={getAppointmentLatLng(scrolledItem)}
                  options={{
                     icon: {
                        url: markerIcon,
                        scaledSize: new window.google.maps.Size(36, 36),
                     },
                     cursor: "pointer",
                  }}
                  animation={google.maps.Animation.DROP}
                  onClick={() => {
                     handleMarkerClick(scrolledItem);
                     map!.setCenter(getAppointmentLatLng(scrolledItem));
                  }}
               >
                  {scrolledItem?.resultId === selectedDrive && (
                     <BloodDriveMarkerPopup
                        drive={scrolledItem}
                        setSelectedDrive={setSelectedDrive}
                        onGetDirectionsClicked={() =>
                           onGetDirectionsClicked(scrolledItem)
                        }
                     />
                  )}
               </MarkerF>
            ))}
         </GoogleMap>
      </>
   ) : null;
};

export default React.memo(Map);
