import React, { useContext } from "react";
import { DropdownToggle, StyledDropdown } from "./styled";
import { ReactComponent as CalenderIcon } from "assets/svgs/calender.svg";
import { DatePicker } from "./DatePicker";
import Dropdown from "react-bootstrap/Dropdown";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../../../OpportunitySearchComponent/context/OpportunitySearchContext";

interface FormDatePickerProps {
   name: string;
}

const FormDatePicker: React.FC<FormDatePickerProps> = () => {
   const { filters } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );

   const [placeHolder, setPlaceHolder] = React.useState<string>("For Today");

   React.useEffect(() => {
      if (filters && filters.dateKind) {
         setPlaceHolder(filters.dateKind);
      }
   }, [filters && filters.dateKind]);

   return (
      <StyledDropdown type="button">
         <DropdownToggle
            className="d-flex justify-content-between align-items-center w-100"
            error={undefined}
            type="button"
         >
            <div className="d-flex justify-content-between align-items-center gap-2">
               <CalenderIcon fill="grey" />
               <span>{placeHolder}</span>
            </div>
         </DropdownToggle>

         <Dropdown.Menu className="p-0 w-100">
            <DatePicker />
         </Dropdown.Menu>
         {/*<div className={errors?.date ? "d-block invalid-feedback" : "d-none"}>*/}
         {/*   {"Required!"}*/}
         {/*</div>*/}
      </StyledDropdown>
   );
};

export default FormDatePicker;
