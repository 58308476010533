import React, { ReactElement, useState } from "react";
import { IModalDisplayParams } from "./IModalDisplayParams";
import { MoreFiltersIconControl } from "./MoreFiltersIconControl";
export interface IMoreFiltersModalControlProps {
   moreFiltersModal: React.FC<IModalDisplayParams>;
}

export function MoreFiltersModalControl(
   props: IMoreFiltersModalControlProps,
): ReactElement {
   const [isDisplayed, setIsDisplayed] = useState<boolean>(false);
   return (
      <>
         <MoreFiltersIconControl onClick={() => setIsDisplayed(true)} />
         <props.moreFiltersModal
            setIsDisplayed={setIsDisplayed}
            isDisplayed={isDisplayed}
         />

      </>
   );
}
