import React, { useContext } from "react";
import { FiltersModal } from "../../../FiltersModal/FiltersModal";
import { OpportunitySearchMoreFiltersBody } from "./OpportunitySearchMoreFiltersBody";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../context/OpportunitySearchContext";
import { defaultOpportunitySearchFilter } from "../../hooks/useOpportunitySearchFilter";
import { CommonContext } from "../../../../contexts/common/commonContext";
interface IOpportunitySearchMoreFiltersMobileModal {
   isMoreFiltersDisplayed: boolean;
   setIsMoreFiltersDisplayed: (isDisplayed: boolean) => void;
}
export const OpportunitySearchMoreFiltersMobileModal: React.FC<
   IOpportunitySearchMoreFiltersMobileModal
> = ({ isMoreFiltersDisplayed, setIsMoreFiltersDisplayed }) => {
   const { filters, updateFilter } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );
   const { defaultOriginZipCode, currentOriginFromBrowser } =
      useContext(CommonContext);
   return (
      <FiltersModal
         showClose={true}
         fullScreen={false}
         FilterRenderer={props => (
            <OpportunitySearchMoreFiltersBody {...props} />
         )}
         initialFilterValue={filters}
         onFilterApplied={updatedValues => {
            if (updatedValues !== null) {
               updateFilter({ ...updatedValues });
            }
            setIsMoreFiltersDisplayed(false);
         }}
         clearAllValues={{
            ...defaultOpportunitySearchFilter,
            searchOrigin: `${currentOriginFromBrowser || defaultOriginZipCode
               }`,
         }}
         show={isMoreFiltersDisplayed}
         onHide={() => {
            setIsMoreFiltersDisplayed(false);
         }}
      />
   );
};
