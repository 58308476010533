import { IBloodDrive } from "../../../../types/IBloodDrive";
import { useEffect, useState } from "react";
import { LocationOrAccountOptions } from "../../../consts";
import {
   IOpportunitySearchFilter,
   useOpportunitySearchFilter,
} from "./useOpportunitySearchFilter";
import { useOpportunitySearchQuery } from "./useOpportunitySearchQuery";
import { OpportunitySearchQuery } from "../types/OpportunitySearchQuery";
import { useOpportunitySearchFilteredData } from "./useOpportunitySearchFilteredData";

interface IUseOpportunitySearchDataProps {}

interface IUseOpportunitySearchData {
   searchFilters: IOpportunitySearchFilter;
   searchResultDrives: IBloodDrive[] | null;
   filteredDrives: IBloodDrive[] | null;
   loading: boolean;
   updateFilter: (update: Partial<IOpportunitySearchFilter>) => void;
}

// Convert filter selection to query
function createSearchQueryFromFilters(
   filters: IOpportunitySearchFilter,
): OpportunitySearchQuery {
   switch (filters.searchType) {
      case LocationOrAccountOptions.ACCOUNT:
         return { 
            accountName: filters.accountName
            // Removed the searchRadius filter to prevent going to the server in the filter change (SCREDP-86)
            // searchRadius: filters.radiusMiles,
         };
      case LocationOrAccountOptions.LOCATION:
         return {
            origin: filters.searchOrigin
            // Removed the searchRadius filter to prevent going to the server in the filter change (SCREDP-86)
            // searchRadius: filters.radiusMiles,
         };
   }
}
export function useOpportunitySearch(
   props: IUseOpportunitySearchDataProps,
): IUseOpportunitySearchData {
   // Manage search filters
   const { searchFilters, updateFilter } = useOpportunitySearchFilter();

   // Manage search queries
   const { searchResults, setQuery } = useOpportunitySearchQuery({
      defaultSearch: createSearchQueryFromFilters(searchFilters),
   });

   // Manage filtered data
   const { filteredData, setData, setFilters } =
      useOpportunitySearchFilteredData({
         initialDrives: searchResults,
         initialFilters: searchFilters,
      });

   // When search filters change, update the query and the filters
   useEffect(() => {
      setQuery(createSearchQueryFromFilters(searchFilters));
      setFilters(searchFilters);
   }, [searchFilters]);

   // When the search results change, update the filtered data
   useEffect(() => {
      setData(searchResults);
   }, [searchResults]);

   const [searchData, setSearchData] = useState<IUseOpportunitySearchData>({
      searchFilters,
      loading: true,
      searchResultDrives: searchResults,
      filteredDrives: filteredData,
      updateFilter,
   });

   // Update search data on change
   useEffect(() => {
      setSearchData({
         searchFilters,
         loading: true,
         searchResultDrives: searchResults,
         filteredDrives: filteredData,
         updateFilter,
      });
   }, [searchResults, searchFilters, filteredData]);

   return searchData;
}
