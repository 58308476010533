import React, { ReactElement } from "react";

export interface ISectionHeaderProps {
   title?: string;
   icon?: ReactElement;
}

export function SectionHeader(props: ISectionHeaderProps): ReactElement {
   return (
      <div className={"section-header"}>
         <>
            {props.icon && props.icon}
            {props.title && <h4>{props.title}</h4>}
         </>
      </div>
   );
}
