import React, { createContext, useEffect, useState } from "react";
import { IBloodDrive } from "types/IBloodDrive";
import { useOpportunitySearch } from "../hooks/useOpportunitySearch";
import {
   defaultOpportunitySearchFilter,
   IOpportunitySearchFilter,
} from "../hooks/useOpportunitySearchFilter";
import { getUniqueAppointmentTypes } from "../../../../utils/getUniqueAppointmentTypes";
import { LocationOrAccountOptions, SortBy } from "../../../consts";
import * as _ from "lodash";

export interface IOpportunitySearchContext {
   drives: IBloodDrive[] | null;
   filters: IOpportunitySearchFilter;
   selectedDriveId: number | null;
   filtered: IBloodDrive[] | null;
   scrolledItems: IBloodDrive[] | [];
   showNoDriveFound: boolean | undefined;
   uniqueAppointmentTypes: string[];
   setSelectedDrive: (selectedDrive: number | null) => void;
   updateFilter: (update: Partial<IOpportunitySearchFilter>) => void;
}

const initialState: IOpportunitySearchContext = {
   drives: [] as IBloodDrive[],
   filters: defaultOpportunitySearchFilter,
   selectedDriveId: null,
   filtered: [],
   scrolledItems: [],
   showNoDriveFound: undefined,
   uniqueAppointmentTypes: [],
   updateFilter: (update: Partial<IOpportunitySearchFilter>) => { },
   setSelectedDrive: (selectedDrive: number | null) => { },
};
interface OpportunitySearchProviderProps {
   children: React.ReactNode;
}
export const OpportunitySearchContext =
   createContext<IOpportunitySearchContext>(initialState);

export const OpportunitySearchProvider: React.FC<
   OpportunitySearchProviderProps
> = ({ children }) => {
   const { searchFilters, searchResultDrives, filteredDrives, updateFilter } =
      useOpportunitySearch({});

   // const { selectedView, changeLayout } = React.useContext(
   //    OpportunityLocatorViewContext,
   // );

   const [state, setState] = useState<IOpportunitySearchContext>({
      ...initialState,
      filters: searchFilters,
      filtered: filteredDrives,
      drives: searchResultDrives,
   });

   useEffect(() => {
      // Deep equal
      if (!_.isEqual(state.filters, searchFilters)) {
         setState({ ...state, filters: searchFilters });

         // Don't change view until clicking on search button (AEP-61)
         // if (selectedView === OpportunityLocatorViews.LANDING_LAYOUT) {
         //    changeLayout(OpportunityLocatorViews.FULL_MAP_CONTRACTED_LAYOUT);
         // }
      }
   }, [searchFilters]);

   useEffect(() => {
      setState({
         ...state,
         drives: searchResultDrives,
         uniqueAppointmentTypes: getUniqueAppointmentTypes(searchResultDrives),
      });
   }, [searchResultDrives]);

   useEffect(() => {
      setState({
         ...state,
         filtered: filteredDrives,
         scrolledItems: filteredDrives || [],
         showNoDriveFound: filteredDrives?.length === 0 || false,
      });
   }, [filteredDrives]);

   useEffect(() => {
      if (searchFilters.searchType === LocationOrAccountOptions.LOCATION) {
         updateFilter({ sortBy: SortBy.CHRONOLOGICAL_ORDER });
      } else if (
         searchFilters.searchType === LocationOrAccountOptions.ACCOUNT
      ) {
         updateFilter({ sortBy: SortBy.CHRONOLOGICAL_ORDER });
      }
   }, [searchFilters.searchType]);

   const setSelectedDrive = (selectedId: number | null): void => {
      setState({
         ...state,
         selectedDriveId: selectedId,
      });
   };

   return (
      <OpportunitySearchContext.Provider
         value={{ ...state, updateFilter, setSelectedDrive: setSelectedDrive }}
      >
         <>{children}</>
      </OpportunitySearchContext.Provider>
   );
};
