import React, { ReactNode, ReactElement } from "react";

export interface IFilterInputFormRowProps {
   children?: ReactNode[] | ReactNode;
   stretch?: boolean;
}

export function FilterInputFormRow({
   children,
   stretch = true,
}: IFilterInputFormRowProps): ReactElement {
   return (
      <div
         className={`filter-input-form-row ${
            stretch ? "stretch" : "no-stretch"
         }`}
      >
         {children}
      </div>
   );
}
