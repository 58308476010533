import React, { useContext, useState } from "react";
import {
   useValidPassword,
   useValidRePassword,
} from "../../../hooks/useAuthValidationHook";
import { Button, Form, Modal } from "react-bootstrap";
import { Password } from "../authComponents";
import { AuthContext, IAuthContext } from "../../../contexts/auth/authContext";
import { CommonModalHeader } from "../styled";
import Alert from "react-bootstrap/Alert";
import { useMediaQuery } from "react-responsive";
import { ChangePasswordForm, ChangePwdBody } from "./styled";

function ChangePasswordModal({ onClose }) {
   const [oldPassword, setOldPassword] = useState<string>("");
   const { password, setPassword, passwordIsValid } = useValidPassword("");
   const { rePassword, setRePassword, rePasswordIsValid } =
      useValidRePassword("");
   const [passwordVisible, isPasswordVisible] = useState<boolean>(false);
   const [rePasswordVisible, isRePasswordVisible] = useState<boolean>(false);
   const [oldPasswordVisible, isOldPasswordVisible] = useState<boolean>(false);
   const [error, setError] = useState<string>("");
   const isPasswordSame: boolean =
      password && rePassword ? password === rePassword : true;

   const isInValid: boolean =
      !passwordIsValid ||
      password.length === 0 ||
      !rePasswordIsValid ||
      rePassword.length === 0 ||
      !isPasswordSame ||
      oldPassword.length === 0;
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });

   const authContext: IAuthContext = useContext(AuthContext);

   const initiateChangePassword = async (): Promise<void> => {
      try {
         await authContext.changePassword(oldPassword, password);
         onClose();
      } catch (err: any) {
         setError(err.message);
      }
   };

   return (
      <>
         <Modal.Header closeButton></Modal.Header>
         <ChangePwdBody>
            <CommonModalHeader className="desktop-header-css change-pwd-header">
               <h5>
                  <b>Change Password</b>
               </h5>
            </CommonModalHeader>
            {error && <Alert variant="danger">{error}</Alert>}
            <ChangePasswordForm className={isMobile ? "" : "desktop-form-css"}>
               <div className={` ${isMobile ? "mobile-padding-css" : "mb-4 "}`}>
                  <Password
                     label="Old Password"
                     passwordIsValid={true}
                     setPassword={setOldPassword}
                     setError={setError}
                     passwordVisible={oldPasswordVisible}
                     isPasswordVisible={isOldPasswordVisible}
                     isRequired={false}
                     isPasswordShowRequired
                  />
               </div>
               <div
                  className={`mb-5 ${
                     isMobile ? "mobile-padding-css" : "mobile-desktop-css "
                  }`}
               >
                  <Password
                     label="New Password"
                     passwordIsValid={passwordIsValid && isPasswordSame}
                     setPassword={setPassword}
                     setError={setError}
                     passwordVisible={passwordVisible}
                     isPasswordVisible={isPasswordVisible}
                     isPasswordSame={isPasswordSame}
                     isRequired={false}
                     isPasswordShowRequired
                  />
                  <Password
                     label="Confirm New Password"
                     passwordIsValid={rePasswordIsValid && isPasswordSame}
                     setPassword={setRePassword}
                     setError={setError}
                     passwordVisible={rePasswordVisible}
                     isPasswordVisible={isRePasswordVisible}
                     isPasswordSame={isPasswordSame}
                     isRequired={false}
                     isPasswordShowRequired
                  />
               </div>
               <Form.Group
                  className={`footer-section-css ${
                     isMobile ? "mobile-footer-css" : "desktop-footer-css"
                  }`}
               >
                  <Button
                     disabled={isInValid}
                     onClick={initiateChangePassword}
                     className="btn-css"
                  >
                     {" "}
                     Submit
                  </Button>
               </Form.Group>
            </ChangePasswordForm>
         </ChangePwdBody>
      </>
   );
}

export default ChangePasswordModal;
