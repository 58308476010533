import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { PRIMARY_COLORS } from "../../../../../consts/colors";

const img =
   require("../../../../../../assets/svgs/caret-down-fill.svg").default;

export const FormSelect = styled(Form.Select)`
   color: ${PRIMARY_COLORS.BRIGHT_GREY};
   outline: none;
   padding-right: 1rem;
   padding-left: 1rem;
   border: 1px solid ${PRIMARY_COLORS.QUILL_GREY};
   background-image: url(${img});
   font-size: 0.95rem;
   background-size: 18px;
`;
