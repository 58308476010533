import React from "react";

function AppointmentText({ isSingleAppointmentType, timesCount }) {
   return (
      <>
         {timesCount > 0 &&
            (<p className="appointment-text">
               {!isSingleAppointmentType
                  ? `Now select an Appointment Type and then find a time that works for you!`
                  : `Now let's find a time that works for you!`}
            </p>)
         }


      </>

   );
}

export default AppointmentText;