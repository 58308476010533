import React, { ReactElement, useContext } from "react";
import { ReactComponent as DropIcon } from "../../../../../../../assets/svgs/drop.svg";
import {
   donorStatusOptions,
   locationDropDownOptions,
   sortByOptions,
} from "../../../../../../consts";
import { SingleSelect } from "../../../../../../components/Form/Inputs/Generic/SingleSelect/SingleSelect";
import { MultipleSelect } from "../../../../../../components/Form/Inputs/Generic/MultipleSelect/MultipleSelect";
import { DateRangePicker } from "../../../../../../components/Form/Inputs/Generic/DateRangePicker/DateRangePicker";
import { ReactComponent as CheckmarkIcon } from "../../../../../../../assets/svgs/check2-circle.svg";
import {
   IMyAppointmentsPageContext,
   MyAppointmentsPageContext,
} from "../../../../context/MyAppointmentsPageContext";
import { usePastAppointmentsPlaceholders } from "../PastAppointmentsFiltersDesktop/usePastAppointmentsPlaceholders";
import { IPastAppointmentFilters } from "../../context/FilteredPastAppointmentsContext";
import {
   getEndOfDay,
   getStartOfDay,
} from "../../../../../../../utils/dateUtil";

export interface IMyAppointmentsModalBodyProps {
   currentFilter: IPastAppointmentFilters;
   updateFilter: (updatedFilter: Partial<IPastAppointmentFilters>) => void;
}

export function MyAppointmentsMoreFiltersModalBody({
   currentFilter,
   updateFilter,
}: IMyAppointmentsModalBodyProps): ReactElement<IMyAppointmentsModalBodyProps> {
   const { startDate, endDate } = currentFilter;
   const { uniqueAppointmentTypes } = useContext<IMyAppointmentsPageContext>(
      MyAppointmentsPageContext,
   );
   const { appointmentTypesPlaceholder, donorArrivalStatusPlaceholder } =
      usePastAppointmentsPlaceholders();
   return (
      <div className={"MyDonationsFiltersModalBody"}>
         <SingleSelect
            values={locationDropDownOptions}
            selectedValue={
               locationDropDownOptions.find(
                  o => o.value === currentFilter.selectedLocationSource,
               )!
            }
            onValueSelected={value =>
               updateFilter({ selectedLocationSource: value.value })
            }
         />
         <MultipleSelect
            selectedValues={currentFilter.selectedAppointmentTypes}
            values={
               uniqueAppointmentTypes
                  ? uniqueAppointmentTypes.map(ap => ap.value)
                  : []
            }
            placeholder={appointmentTypesPlaceholder}
            onSelectedValuesChanges={newValue => {
               updateFilter({ selectedAppointmentTypes: newValue });
            }}
            staticIconSrc={<DropIcon className={"input-label-image"} />}
         />
         <DateRangePicker
            startDate={startDate}
            setStartDate={(newDate: string) =>
               updateFilter({
                  startDate: getStartOfDay(new Date(newDate)),
               })
            }
            endDate={endDate}
            setEndDate={(newDate: string) =>
               updateFilter({
                  endDate: getEndOfDay(new Date(newDate)),
               })
            }
         />
         <MultipleSelect
            selectedValues={currentFilter.selectedDonorStatuses}
            values={donorStatusOptions.map(option => option.value)}
            placeholder={donorArrivalStatusPlaceholder}
            onSelectedValuesChanges={newValue => {
               updateFilter({ selectedDonorStatuses: newValue });
            }}
            staticIconSrc={<CheckmarkIcon className={"input-label-image"} />}
         />
         <SingleSelect
            values={sortByOptions}
            selectedValue={
               sortByOptions.find(
                  option => option.value === currentFilter.sortBySelected,
               )!
            }
            onValueSelected={selectedValue => {
               updateFilter({ sortBySelected: selectedValue.value });
            }}
         />
      </div>
   );
}
