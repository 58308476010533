const urlPattern: RegExp = new RegExp(
   "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z@\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
   "i",
); // validate fragment locator

/**
 * Verifies if url is valid - defined, not null, not empty, and valid
 * @param url
 */
export function isValidSocialMediaURL(url: string | null | undefined): boolean {
   if (url === null || url === undefined) {
      return false;
   } else {
      return !!urlPattern.test(url);
   }
}
