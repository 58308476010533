/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import "./style/_appStyle.scss";
import * as React from "react";
import { Helmet } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GlobalStyle } from "styles/global-styles";
import { NotFoundPage } from "./pages/NotFoundPage/Loadable";
import { useTranslation } from "react-i18next";
import AuthProvider from "./contexts/auth/authContext";
import { EnvironmentContextProvider } from "./contexts/environment/EnvironmentContextProvider";
import { UserContextProvider } from "./contexts/user/UserContextProvider";
import { AppContext, IAppContext } from "./contexts/app/appContext";
import { useState } from "react";
import { Index } from "./routes";
import { AppointmentListPage } from "./pages/AppointmentDataPage";
import FutureAppointmentMap from "./pages/MyAppointmentsPage/components/FutureAppointmentMap";
import { CommonContextProvider } from "./contexts/common/commonContext";
import GuardedRoutes from "./routes/GuardedRoutes";
import MyAppointmentPage from "./pages/MyAppointmentsPage";
import DashboardLayout from "./layouts/DashboardLayout";
import { OpportunitySearchProvider } from "./components/OpportunitySearchComponent/context/OpportunitySearchContext";
import { OpportunityLocatorViewContextProvider } from "./components/OpportunitySearchComponent/context/OpportunityLocatorViewContext";
import ProfilePage from "./pages/ProfilePage";
import MyDonationPage from "./pages/MyDonationPage";
import PhysicalExamsPage from "./pages/PhysicalExamsPage";
import PreferencesPage from "./pages/PreferencesPage";
import { ROUTE_PATHS } from "./consts/RoutePaths";

interface RoutesContentInterface {
   path: string;
   element: React.ReactElement;
   isGuarded: boolean;
}

export function App() {
   const { i18n } = useTranslation();
   const [context, setContext] = useState<IAppContext>({
      host: window.location.host,
   });

   const routesContent: RoutesContentInterface[] = [
      {
         path: ROUTE_PATHS.LANDING_PATH,
         element: <Index />,
         isGuarded: false,
      },
      {
         path: ROUTE_PATHS.APPOINTMENT_LIST,
         element: <AppointmentListPage />,
         isGuarded: false,
      },
      {
         path: ROUTE_PATHS.MY_APPOINTMENTS,
         element: <MyAppointmentPage />,
         isGuarded: true,
      },
      {
         path: ROUTE_PATHS.MY_Donations,
         element: <MyDonationPage />,
         isGuarded: true,
      },
      {
         path: ROUTE_PATHS.FUTURE_APPOINTMENTS_MAP,
         element: <FutureAppointmentMap />,
         isGuarded: true,
      },
      {
         path: ROUTE_PATHS.PROFILE,
         element: <ProfilePage />,
         isGuarded: true,
      },
      {
         path: ROUTE_PATHS.PHYSICAL_EXAMS,
         element: <PhysicalExamsPage />,
         isGuarded: true,
      },
      {
         path: ROUTE_PATHS.PREFERENCES,
         element: <PreferencesPage />,
         isGuarded: true,
      },
   ];

   return (
      <AppContext.Provider value={context}>
         <EnvironmentContextProvider>
            <AuthProvider>
               <UserContextProvider>
                  <CommonContextProvider>
                     <OpportunityLocatorViewContextProvider>
                        <OpportunitySearchProvider>
                           <BrowserRouter>
                              <Helmet
                                 titleTemplate="%s"
                                 defaultTitle="Donor Portal"
                                 htmlAttributes={{ lang: i18n.language }}
                              >
                                 <meta
                                    name="description"
                                    content="Donor Portal"
                                 />
                              </Helmet>
                              <Routes>
                                 <Route element={<DashboardLayout />}>
                                    {routesContent.map(
                                       (route: RoutesContentInterface) => {
                                          return (
                                             <Route
                                                key={route.path}
                                                path={route.path}
                                                element={
                                                   <GuardedRoutes
                                                      isGuarded={
                                                         route.isGuarded
                                                      }
                                                   >
                                                      {route.element}
                                                   </GuardedRoutes>
                                                }
                                             />
                                          );
                                       },
                                    )}
                                 </Route>
                                 <Route path="*" element={<NotFoundPage />} />
                              </Routes>
                              <GlobalStyle />
                           </BrowserRouter>
                        </OpportunitySearchProvider>
                     </OpportunityLocatorViewContextProvider>
                  </CommonContextProvider>
               </UserContextProvider>
            </AuthProvider>
         </EnvironmentContextProvider>
      </AppContext.Provider>
   );
}
