import React, { useState } from "react";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import markerIcon from "../../../../../../assets/images/marker.png";
import { IBloodDrive } from "types/IBloodDrive";
const containerStyle = {
   width: "100%",
   height: "100%",
};

interface MapWithSinglePinProps {
   details: IBloodDrive;
}
const Map: React.FC<MapWithSinglePinProps> = ({ details }) => {
   const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
   });
   const [map, setMap] = useState<google.maps.Map>();
   const [mapCenterPoint, setMapCenterPoint] = useState<null | { lat: number; lng: number }>(
      null,
   );
   const [selectedPin, setSelectedPin] = React.useState(null);

   React.useEffect(() => {
         if(!mapCenterPoint && details) {
            const location = details.location;
            const defaultMapCenterPoint = { lat: location?.latitude ?? 0, lng: location?.longitude ?? 0 };
            if(defaultMapCenterPoint.lat && defaultMapCenterPoint.lng) {
               setMapCenterPoint(defaultMapCenterPoint);
            }
         }
      },
      [details],
   );

   const handleMarkerClick = appointment => setSelectedPin(appointment);

   return isLoaded && mapCenterPoint ? (
      <div style={{ height: "100vh", width: "100vw" }}>
         <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenterPoint}
            zoom={13}
            options={{
               streetViewControl: false,
               fullscreenControl: false,
               mapTypeControl: false,
            }}
            onLoad={map => {
               setMap(map);
            }}
         >
            {mapCenterPoint && (
               <MarkerF
                  key={details.account.accountCode}
                  position={mapCenterPoint}
                  options={{
                     icon: markerIcon,
                     cursor: "pointer",
                  }}
                  onClick={() => {
                     handleMarkerClick(details);
                     map!.setCenter({
                        lat: mapCenterPoint.lat,
                        lng: mapCenterPoint.lng,
                     });
                  }}
               ></MarkerF>
            )}
         </GoogleMap>
      </div>
   ) : (
      <>Loading ...</>
   );
};

export default React.memo(Map);
