import React from "react";
import { Button, Image } from "react-bootstrap";
import ScheduleDonationImg from "assets/images/schedule-donation.png";
import bookdonation from "assets/svgs/bookdonation.png";
import CommonHeader from "app/components/CommonHeader";
import { StyledBookApponiment, StyledDonationHistory } from "./styled";
import { useMediaQuery } from "react-responsive";
import { RiHistoryFill } from "react-icons/ri";

export const DonationNotFound = () => {
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });

   return (
      <div className="row gy-4">
         <div className="col-lg-6 col-md-12">
            <CommonHeader
               icon={<RiHistoryFill fill="white" size={20} />}
               title="Donation History"
            />
            <StyledDonationHistory>
               <h5>No donation to display</h5>
               <Image
                  className="d-none d-sm-block fluid"
                  src={ScheduleDonationImg}
                  width={"60%"}
                  height="220px"
               />
            </StyledDonationHistory>
         </div>
         <div className="col-lg-6 col-md-12">
            <StyledBookApponiment isMobile={isMobile}>
               <Button
                  className="make-appointment-button "
                  variant="danger"
                  onClick={() => {}}
               >
                  Make an Appointment
               </Button>
               <Image src={bookdonation} fluid />
            </StyledBookApponiment>
         </div>
      </div>
   );
};

export default DonationNotFound;
