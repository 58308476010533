import styled from "styled-components";
import { ReactComponent as EyeFill } from "../../../assets/svgs/eye-fill.svg";
import { ReactComponent as EyeSlashFill } from "../../../assets/svgs/eye-slash-fill.svg";
import { ReactComponent as EmailIcon } from "../../../assets/svgs/envelope.svg";
import { ReactComponent as LockIcon } from "../../../assets/svgs/lock.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/svgs/telephone.svg";
import { ReactComponent as PersonIcon } from "../../../assets/svgs/person.svg";
import { ReactComponent as PersonVcardIcon } from "../../../assets/svgs/person-vcard.svg";
import { ReactComponent as StreetViewIcon } from "../../../assets/svgs/street-view-solid.svg";
import { ReactComponent as CityBuildingsIcon } from "../../../assets/svgs/buildings-fill.svg";
import { ReactComponent as CalendarDateIcon } from "../../../assets/svgs/calendar-date.svg";
import { ReactComponent as CalendarDaysSolid } from "../../../assets/svgs/calendar_month_solid.svg";
import { css } from "styled-components/macro";
import Form from "react-bootstrap/Form";
import { PRIMARY_COLORS } from "../../consts/colors";

const img = require("../../../assets/svgs/caret-down-fill.svg").default;

const baseImageCss = css`
   top: 0.65em;
   left: 4%;
   position: absolute;
   width: 14px;
   height: 14px;
`;

export const IconsCss = css`
   width: 20px;
   height: 20px;
`;

export const CommonModalHeader = styled.header`
   &.mobile-header-css {
      margin-bottom: 0px;
   }
   &.desktop-header-css {
      margin-bottom: 40px;
   }
   display: flex;
   justify-content: center;
`;
export const StyledFormControl = styled(Form.Control)`
   padding-left: 2.5rem;
`;

export const PasswordSectionDiv = styled.div`
   input::-ms-reveal,
   input::-ms-clear {
      display: none;
   }
`;
export const PasswordVisibleIconSpan = styled.span`
   position: absolute;
   top: 5px;
   right: 5px;
`;

export const EyeImageFIll = styled(EyeFill)`
   background: none;
   fill: gray;
   cursor: pointer;
   width: 50px;
   height: 20px;
`;

export const EyeImageSlashFIll = styled(EyeSlashFill)`
   background: none;
   fill: gray;
   cursor: pointer;
   width: 50px;
   height: 20px;
`;

export const EmailImg = styled(EmailIcon)`
   ${baseImageCss}
`;

export const LockImg = styled(LockIcon)`
   ${baseImageCss}
`;

export const PhoneImg = styled(PhoneIcon)`
   ${baseImageCss}
`;

export const PersonImg = styled(PersonIcon)`
   ${baseImageCss}
`;

export const PersonVcardImg = styled(PersonVcardIcon)`
   ${baseImageCss};
   fill: gray;
`;

export const StreetAddressImg = styled(StreetViewIcon)`
   ${baseImageCss};
   fill: gray;
`;

export const CityBuildingsImg = styled(CityBuildingsIcon)`
   ${baseImageCss};
   fill: gray;
   transform: rotate3d(0, 1, 0, 180deg);
`;

export const CalendarDateImg = styled(CalendarDateIcon)`
   ${baseImageCss};
   z-index: 1;
   fill: gray;
`;

export const CalendarDaySolidImg = styled(CalendarDaysSolid)`
   width: 35px;
   height: 35px;
   fill: gray;
`;

export const RequiredFieldSpanStyled = styled.span`
   color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
   position: absolute;
   left: -20px;
   top: 11px;
`;

export const DateOfBirthDiv = styled.div`
   .input-css {
      padding-left: 2.5rem;
   }
`;

export const FormGroupAlignedCenter = styled(Form.Group)`
   text-align: center;
`;

export const GenderRadioSection = styled.div`
   display: flex;
   justify-content: space-evenly;
   border: 1px solid ${PRIMARY_COLORS.QUILL_GREY};
   border-radius: 0.375rem;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   padding: 0.375rem 0.75rem;
`;

export const FormSelect = styled(Form.Select)`
   color: ${PRIMARY_COLORS.BRIGHT_GREY};
   outline: none;
   padding-right: 2.5rem;
   padding-left: 0.75rem;
   border: 1px solid ${PRIMARY_COLORS.QUILL_GREY};
   background-image: url(${img});
   font-size: 0.95rem;
   background-size: 18px;
`;
