import React, { ReactElement, useContext } from "react";
import "./style.scss";
import "./styled";
import Button from "react-bootstrap/Button";
import packageJson from "../../../../package.json";
import SignInModal from "../AuthenticationModals/SignInModal";
import { ModalPageNames } from "../../consts";
import { LayoutContext } from "../../layouts/context/layoutContext";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { OpportunityLocatorViewContext } from "../OpportunitySearchComponent/context/OpportunityLocatorViewContext";
import { EnvironmentContext } from "../../contexts/environment/environmentContext";

function parseHeaderColor(headerColor: string | undefined | number): string {
   if (!headerColor) {
      return "white";
   } else {
      if (typeof headerColor === "string") {
         if (headerColor[0] === "#") {
            return headerColor.substring(1);
         } else {
            return headerColor;
         }
      } else {
         return `${headerColor}`;
      }
   }
}

export function Header(): ReactElement {
   const { isNavBarExpanded, setIsNavBarExpanded, isSignedIn } =
      useContext(LayoutContext);
   const { environmentConfig, logoSrc } = useContext(EnvironmentContext);
   const navigate: NavigateFunction = useNavigate();
   const [headerColor, setHeaderColor] = React.useState<string>(
      parseHeaderColor(environmentConfig?.headerColor),
   );
   const [modalShow, setModalShow] = React.useState(false);
   const { renderBasicLayout } = React.useContext(
      OpportunityLocatorViewContext,
   );
   const handleLogoClick = (): void => {
      navigate("/");
      renderBasicLayout();
   };

   const handleSignInClick = () => {
      setModalShow(true);
   };

   return (
      <div id={"layout-header"}>
         <div
            id={"TopNavbar"}
            style={{
               backgroundColor: isSignedIn ? `#${headerColor}` : "white",
            }}
            className={isNavBarExpanded ? "active" : ""}
         >
            <div className="logo-wrapper">
               {logoSrc && (
                  <img
                     src={logoSrc}
                     className="logo-css p-link"
                     onClick={handleLogoClick} alt="logo"
                  />
               )}

               {isSignedIn && (
                  <Button
                     className="arrow-button"
                     onClick={() => setIsNavBarExpanded(!isNavBarExpanded)}
                  >
                     {/* Changed icon to bars to indicate the menu (SCREDP-78) */}
                     <i className="pi pi-bars"></i>
                  </Button>
               )}
            </div>
            
            {/* Always display the version number regardless of whether the user is signed in (SCREDP-101) */}
            <div className="header-right-section" style={{alignItems: "center"}}>
               <div className={"label size-normal"} style={{color: (!isSignedIn ? "black" : "white") }}>
                  © 2015-{new Date().getFullYear()}, IT Synergistics LLC - v{packageJson.version}
               </div>
               {!isSignedIn &&
                  <Button id={"sign-in-button"} onClick={handleSignInClick}>
                     Sign In
                  </Button>}
            </div>

            <SignInModal
               show={modalShow}
               setModalShow={setModalShow}
               pageNameSent={ModalPageNames.SIGN_IN}
            />
         </div>
      </div>
   );
}
