import { IAppointmentsPlaceholders } from "./IAppointmentsPlaceholders";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";

const MAX_SMALL = 1200;
const MIN_LARGE = 1330;

export function usePastAppointmentsPlaceholders(): IAppointmentsPlaceholders {
   const isSmall: boolean = useMediaQuery({ maxWidth: MAX_SMALL });
   const isMedium: boolean = useMediaQuery({
      minWidth: MAX_SMALL + 1,
      maxWidth: MIN_LARGE - 1,
   });
   const isLarge: boolean = useMediaQuery({ minWidth: MIN_LARGE });
   const [placeholders, setPlaceholder] = useState({
      appointmentTypesPlaceholder: "",
      donorArrivalStatusPlaceholder: "",
   });

   useEffect(() => {
      if (isSmall) {
         setPlaceholder({
            appointmentTypesPlaceholder: "Appt Type",
            donorArrivalStatusPlaceholder: "Status",
         });
      } else if (isMedium) {
         setPlaceholder({
            appointmentTypesPlaceholder: "Appointment Type",
            donorArrivalStatusPlaceholder: "Arrival Status",
         });
      } else {
         setPlaceholder({
            appointmentTypesPlaceholder: "Select Appointment Types",
            donorArrivalStatusPlaceholder: "Select Donor Arrival Status",
         });
      }
   }, [isSmall, isMedium, isLarge]);
   return placeholders;
}
