import { DonationContextProvider } from "app/pages/MyDonationPage/context/DonationContext";
import React from "react";
import { PageComponent } from "../../components/PageComponent";
import MyDonationHeader from "./components/MyDonationHeader";
import { MyDonationsBody } from "./components/MyDonationsBody/MyDonationsBody";
import { SectionContainer } from "../../components/Section/SectionContainer";

const MyDonationPage = () => {
   return (
      <PageComponent
         name={"My Donations"}
         title={"My Donations"}
         description={"My Donations Page"}
      >
         <DonationContextProvider>
            <SectionContainer>
               <MyDonationHeader />
               <MyDonationsBody />
            </SectionContainer>
         </DonationContextProvider>
      </PageComponent>
   );
};

export default MyDonationPage;
