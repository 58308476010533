import React, { useContext, useEffect, useState } from "react";
import { PageComponent } from "../../components/PageComponent";
import { OpportunitySearchComponent } from "../../components/OpportunitySearchComponent/OpportunitySearchComponent";
import SignInModal from "../../components/AuthenticationModals/SignInModal";
import { ModalPageNames } from "../../consts";
import { EnvironmentContext } from "../../contexts/environment/environmentContext";

export function DefaultLandingPage() {
   const [modalShow, setModalShow] = useState<boolean>(false);
   const { environmentConfig } = useContext(EnvironmentContext);
   const [confirmedAptDetails, setConfirmedAptDetails] = useState<
      string | undefined
   >(undefined);

   useEffect(() => {
      const confirmedAptDetailValue: string | null =
         sessionStorage.getItem("confirmedAppointmentDetails") || null;
      if (confirmedAptDetailValue) {
         setConfirmedAptDetails(JSON.parse(confirmedAptDetailValue));
         setModalShow(true);
         sessionStorage.removeItem("confirmedAppointmentDetails");
      }
   }, []);
   return (
      <PageComponent
         name={"DonorLandingPage"}
         title={`${environmentConfig?.pageTitle} – Home (Appointment Search)`}
         description={"Donor Landing Page"}
      >
         <OpportunitySearchComponent />
         <SignInModal
            show={modalShow}
            setModalShow={setModalShow}
            pageNameSent={ModalPageNames.APPOINTMENT_CONFIRMATION}
            confirmedAptDetails={confirmedAptDetails}
         />
      </PageComponent>
   );
}
