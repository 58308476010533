import React from "react";
import { LabelContentDiv } from "./styled";
import { Col, Form } from "react-bootstrap";

interface LabelContentProps {
   label: string;
   value: string;
}
const LabelContentComponent: React.FC<LabelContentProps> = ({
   label,
   value,
}) => {
   return (
      <LabelContentDiv>
         <Form.Group className="section-css" controlId={`formName-${label}`}>
            <Form.Label column xs={5}>
               {label}
            </Form.Label>
            <Col xs={7}>
               <Form.Control
                  className="content-css"
                  plaintext
                  readOnly
                  defaultValue={value}
               />
            </Col>
         </Form.Group>
      </LabelContentDiv>
   );
};

export default LabelContentComponent;
