import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { IconsCss } from "../styled";
import { ReactComponent as CrossIconLabel } from "../../../../assets/svgs/cross.svg";
import { PRIMARY_COLORS } from "../../../consts/colors";

export const ForgotPasswordForm = styled(Form)`
   &.desktop-form-css {
      padding: 0 40px 20px 40px;
   }
   .mobile-padding-css {
      padding: 20px;
   }
   .header-css {
      font-size: 13px;
   }
   .footer-section-css {
      display: grid;
      grid-template-columns: auto auto;
      &.mobile-footer-css {
         grid-gap: 5%;
      }
      &.desktop-footer-css {
         grid-gap: 30%;
      }
      margin-top: 60px;
      .btn-css {
         font-size: 12px;
         background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
         border-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
      }
   }
`;

export const CrossIconImg = styled(CrossIconLabel)`
   ${IconsCss};
   margin-left: 5px;
`;
