import Button from "react-bootstrap/Button";
import styled from "styled-components";
import { PRIMARY_COLORS } from "../../../../../consts/colors";

export const MapButton = styled(Button)`
   color: ${PRIMARY_COLORS.PALE_SKY};
   font-weight: 700;
   background: ${PRIMARY_COLORS.SOAP_STONE};
   border: 0;
   border-radius: 13px;
   box-shadow: 0 1px 3px 0 rgba(60,64,67,.3), 0 4px 8px 3px rgba(60,64,67,.15);
   svg {
      padding-right: 5px;
      scale: 1.2;
      stroke: ${PRIMARY_COLORS.PALE_SKY};
   }
   &:hover {
      background-color: ${PRIMARY_COLORS.SOAP_STONE};
      border: 1px solid gray;
      color: ${PRIMARY_COLORS.PALE_SKY};
   }
`;

export const Div = styled.div`
   position: sticky;
   bottom: 50px;
   z-index: 2;
`;

export const Container = styled.div`
   position: relative;
   height: calc(100vh - 110px);
`;
