import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import {
   City,
   DateOfBirth,
   Email,
   Gender,
   Name,
   PhoneNumber,
   StatesDropdown,
   StreetAddress,
   ZipCode,
} from "../authComponents";
import {
   useValidEmail,
   useValidFirstName,
   useValidLastName,
   useValidPhoneNumber,
   useValidStreetAddress,
   useValidCity,
   useValidZipCode,
} from "../../../hooks/useAuthValidationHook";
import { CommonModalHeader } from "../styled";
import Alert from "react-bootstrap/Alert";
import {
   CrossIconImg,
   DisplayInformationDiv,
   FormGroupPaddingLeft,
   ForwardIconImg,
   RequiredFieldsDiv,
   SectionHeader,
   StyledDot,
} from "../NewAccount/styled";
import { useMediaQuery } from "react-responsive";
import { RequestAptForm } from "./styled";
import { getAppointmentDataServiceInstance } from "../../../../services/bexWISE/appointmentDataService/getAppointmentDataServiceInstance";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
   IAppointmentDataService,
   INonAuthenticatedDonorAppointmentRequest,
} from "../../../../services/bexWISE/appointmentDataService/IAppointmentDataService";
import { formatDateValue } from "../../../pages/AppointmentDataPage/hooks/useAppointmentData";
import { ROUTE_PATHS } from "../../../consts/RoutePaths";

export interface IRequestAppointmentModalDetailsProps {
   driveId: string | number;
   driveDate: string | Date;
   appointmentSlotId?: number;
}
export interface IRequestAppointmentModalProps {
   onClose: () => void;
   confirmedAptDetails: IRequestAppointmentModalDetailsProps;
}

function RequestAppointment({
   onClose,
   confirmedAptDetails,
}: IRequestAppointmentModalProps) {
   const { email, setEmail, emailIsValid } = useValidEmail("");
   const { firstName, setFirstName, firstNameIsValid } = useValidFirstName("");
   const { lastName, setLastName, lastNameIsValid } = useValidLastName("");
   const { streetAddress, setStreetAddress, streetAddressIsValid } =
      useValidStreetAddress("");
   const { city, setCity, cityIsValid } = useValidCity("");
   const { phoneNumber, setPhoneNumber, phoneNumberIsValid } =
      useValidPhoneNumber("");
   const { zipCode, setZipCode, zipcodeIsValid } = useValidZipCode("");
   const [gender, setGender] = useState<string>("");
   const [stateVal, setStateVal] = useState<string>("");
   const [dob, setDob] = useState<Date | null>(null);
   const [error, setError] = useState<string>("");
   const isLarge: boolean = useMediaQuery({ minWidth: 992 });
   const navigate: NavigateFunction = useNavigate();

   const isInValid: boolean =
      !firstNameIsValid ||
      firstName.length === 0 ||
      !lastNameIsValid ||
      lastName.length === 0 ||
      !dob ||
      !emailIsValid ||
      email.length === 0 ||
      !phoneNumberIsValid ||
      phoneNumber.length === 0 ||
      !streetAddressIsValid ||
      streetAddress.length === 0 ||
      !stateVal ||
      !gender ||
      !city ||
      city.length === 0 ||
      !zipcodeIsValid ||
      zipCode.length === 0;

   const requestAppointmentClicked = (): void => {
      const formattedPh: string = phoneNumber.replace(/\D/g, "");
      if (dob && phoneNumber) {
         const requestData: INonAuthenticatedDonorAppointmentRequest = {
            firstName: firstName,
            lastName: lastName,
            dateOfBirth: formatDateValue(dob),
            sex: gender,
            address: `${streetAddress} ${city}, ${stateVal} ${zipCode}`,
            phoneNumber: formattedPh,
            emailAddress: email,
            driveId: Number(confirmedAptDetails.driveId),
            driveDate: formatDateValue(confirmedAptDetails.driveDate),
            appointmentSlotId: confirmedAptDetails.appointmentSlotId!,
         };
         requestAppointment(requestData);
      }
   };

   const requestAppointment = async (
      nonAuthenticatedUserRequest: INonAuthenticatedDonorAppointmentRequest,
   ): Promise<void> => {
      try {
         const appointmentDataService: IAppointmentDataService =
            getAppointmentDataServiceInstance();
         await appointmentDataService.requestAppointment(
            nonAuthenticatedUserRequest,
         );
         onClose();
         sessionStorage.setItem(
            "confirmedAppointmentDetails",
            JSON.stringify({
               ...confirmedAptDetails,
               userFirstName: firstName,
            }),
         );
         navigate(`${ROUTE_PATHS.LANDING_PATH}`);
      } catch (err) {
         sessionStorage.removeItem("confirmedAppointmentDetails");
         throw err;
      }
   };

   return (
      <>
         <Modal.Header></Modal.Header>
         <Modal.Body>
            <CommonModalHeader className="desktop-header-css">
               <h5>
                  <b>Request an Appointment</b>
               </h5>
            </CommonModalHeader>
            {error && <Alert variant="danger">{error}</Alert>}
            <DisplayInformationDiv>
               In order to provide you with the best possible service and ensure
               a quicker response to your request, please complete the
               information fields below and click Continue.
            </DisplayInformationDiv>
            <RequestAptForm>
               <Row>
                  <FormGroupPaddingLeft
                     as={Col}
                     xs="12"
                     lg="6"
                     className={isLarge ? "" : "mb-3"}
                  >
                     <SectionHeader> Personal Details</SectionHeader>
                     <Name
                        name={firstName}
                        nameIsValid={firstNameIsValid}
                        setName={setFirstName}
                        placeholderValue="Legal First Name" // Added "Legal" to the placeholder (SCREDP-93) 
                     />
                     <Name
                        name={lastName}
                        nameIsValid={lastNameIsValid}
                        setName={setLastName}
                        placeholderValue="Legal Last Name" // Added "Legal" to the placeholder (SCREDP-93) 
                     />
                     {!isLarge && <DateOfBirth dob={dob} setDob={setDob} />}
                  </FormGroupPaddingLeft>
                  <FormGroupPaddingLeft
                     as={Col}
                     xs="12"
                     lg="6"
                     className={isLarge ? "" : "mb-3"}
                  >
                     <SectionHeader> Account Details</SectionHeader>
                     <Email
                        emailIsValid={emailIsValid}
                        setEmail={setEmail}
                        placeholderValue="Email Address"
                        isRequired
                     />
                     <PhoneNumber
                        phoneNumberIsValid={phoneNumberIsValid}
                        setPhoneNumber={setPhoneNumber}
                        phoneNumber={phoneNumber}
                        placeholderValue="Phone Number"
                     />
                  </FormGroupPaddingLeft>
               </Row>
               {isLarge ? (
                  <Row className="mb-3">
                     <FormGroupPaddingLeft as={Col} lg="6">
                        <DateOfBirth dob={dob} setDob={setDob} />
                     </FormGroupPaddingLeft>
                     <FormGroupPaddingLeft
                        as={Col}
                        lg="6"
                        className="gender-desktop-section mb-3"
                     >
                        <h6 className="header">Sex</h6>
                        <Gender gender={gender} setGender={setGender} />
                     </FormGroupPaddingLeft>
                  </Row>
               ) : (
                  <Row className="mb-4">
                     <FormGroupPaddingLeft as={Col}>
                        <SectionHeader>Sex</SectionHeader>
                        <Col>
                           <Gender gender={gender} setGender={setGender} />
                        </Col>
                     </FormGroupPaddingLeft>
                  </Row>
               )}
               <Row>
                  <FormGroupPaddingLeft as={Col} xs="12" lg="6">
                     <SectionHeader>Home Address</SectionHeader>  {/*Replaced "Location" with "Home Address" (SCREDP-93)  */}
                     <StreetAddress
                        streetAddressIsValid={streetAddressIsValid}
                        setStreetAddress={setStreetAddress}
                     />
                     <StatesDropdown
                        stateVal={stateVal}
                        setStateVal={setStateVal}
                     />
                  </FormGroupPaddingLeft>
                  <FormGroupPaddingLeft as={Col} xs="12" lg="6">
                     {isLarge && <SectionHeader></SectionHeader>}
                     <City cityIsValid={cityIsValid} setCity={setCity} />
                     <ZipCode
                        zipcodeIsValid={zipcodeIsValid}
                        setZipCode={setZipCode}
                     />
                  </FormGroupPaddingLeft>
               </Row>
               <Row>
                  <RequiredFieldsDiv className={`${isLarge ? "mb-4" : "mb-5"}`}>
                     <div>
                        <StyledDot />
                        <span>Required Fields</span>
                     </div>
                  </RequiredFieldsDiv>
               </Row>

               <div
                  className={`footer-section-css ${isLarge ? "desktop-footer-css" : "mobile-footer-css"
                     }`}
               >
                  <Button onClick={onClose} className="btn-css">
                     Cancel <CrossIconImg />
                  </Button>
                  <Button
                     disabled={isInValid}
                     onClick={requestAppointmentClicked}
                     className="btn-css"
                  >
                     Continue <ForwardIconImg />
                  </Button>
               </div>
            </RequestAptForm>
         </Modal.Body>
      </>
   );
}

export default RequestAppointment;
