import styled from "styled-components";
import { css } from "styled-components/macro";
import { ReactComponent as FacebookIcon } from "../../../assets/svgs/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/svgs/twitter.svg";
import { ReactComponent as TikTokIcon } from "../../../assets/svgs/tiktok.svg";
import { ReactComponent as YoutubeIcon } from "../../../assets/svgs/youtube.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/svgs/instagram.svg";
import { ReactComponent as GeoLocationIcon } from "../../../assets/svgs/geo-alt-fill.svg";
import { ReactComponent as TelephoneFillIcon } from "../../../assets/svgs/telephone-fill.svg";
import { ReactComponent as BloodCenterIcon } from "../../../assets/svgs/blood-center-icon.svg";
import { PRIMARY_COLORS } from "../../consts/colors";

export const IconsCss = css`
   width: 14px;
   height: 14px;
   fill: gray;
`;

const baseIconStyles = css`
   width: 12px;
   height: 12px;
   fill: gray;
`;

const gridCss = css`
   display: grid;
   gap: 8px;
   align-items: center;
`;
export const FacebookImg = styled(FacebookIcon)`
   ${IconsCss};
`;

export const TwitterImg = styled(TwitterIcon)`
   ${IconsCss};
`;

export const TikTokImg = styled(TikTokIcon)`
   ${IconsCss};
`;

export const YoutubeImg = styled(YoutubeIcon)`
   ${IconsCss};
`;

export const InstagramImg = styled(InstagramIcon)`
   ${IconsCss};
`;

export const StyledTelephoneIcon = styled(TelephoneFillIcon)`
   ${baseIconStyles}
`;

export const StyledGeoLocation = styled(GeoLocationIcon)`
   ${baseIconStyles}
`;

export const BloodCenterImg = styled(BloodCenterIcon)`
   width: 14px;
   height: 14px;
   fill: gray;
`;

export const FooterSection = styled.footer`
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   span {
      font-size: 10px !important;
   }
   height: 100%;
   border-top: 1px solid ${PRIMARY_COLORS.IRON_COLOR};
   .first-section {
      display: flex;
      justify-content: space-between;
      padding-right: 0;

      .each-section {
         ${gridCss};
         grid-template-columns: auto auto;
      }
      .logo-css {
         width: 30px;
         height: 12px;
      }
      .copyright-css {
         font-weight: bold;
      }
   }
   .last-section-css {
      display: flex;
      justify-content: space-between;
      padding-right: 0;
      .address-section-css {
         ${gridCss};
         grid-template-columns: auto auto;
         gap: 7px;
         .each-section {
            ${gridCss};
            grid-template-columns: auto auto;
         }
      }
      .social-network-css {
         ${gridCss};
         grid-template-columns: auto auto auto auto auto;
         margin-right: 10px;
      }
   }
`;
