import React, { ReactElement, useContext } from "react";
import { LayoutContext } from "../../layouts/context/layoutContext";
import LayoutNavigationBarComponent from "./LayoutNavigationBar/LayoutNavigationBarComponent";

export function Navbar(): ReactElement {
   const { isSignedIn, isNavBarExpanded, isMobile } = useContext(LayoutContext);
   return (
      <div
         id={"layout-navbar"}
         className={isNavBarExpanded ? (isMobile ? "overlay expanded" : "expanded") : isMobile ? "hidden contracted" : "contracted"}
      >
         {isSignedIn && <LayoutNavigationBarComponent />}
      </div>
   );
}
