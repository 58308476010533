import React from "react";
import {
   IOpportunitySearchBarView,
   OpportunitySearchBarView,
} from "./views/OpportunitySearchBarView";
import { OpportunitySearchBarFullScreenView } from "./views/OpportunitySearchBarFullScreenView";
import { OpportunitySearchBarLandingView } from "./views/OpportunitySearchBarLandingView";

export const OpportunitySearchBar = ({
   view = OpportunitySearchBarView.LandingView,
}: IOpportunitySearchBarView) => {
   return view === OpportunitySearchBarView.LandingView ? (
      <OpportunitySearchBarLandingView />
   ) : (
      <OpportunitySearchBarFullScreenView />
   );
};
