import { ISelectableOption } from "../components/Form/Inputs/Generic/SingleSelect/ISelectableOption";
import locationIconSrc from "../../assets/svgs/fill-location.svg";
import locationFilledIcon from "../../assets/svgs/fill-location.svg";
import HomeIconSrc from "../../assets/svgs/home.svg";
import SortImage from "../../assets/svgs/arrow-down-short-wide-solid.svg";
import AppointmentType from "../../assets/svgs/assignment.svg";
import DriveIconSrc from "../../assets/svgs/bus.svg";
import personFilledIcon from "../../assets/svgs/personFilled.svg";

export enum LocationOption {
   ALL = "all_locations",
   MOBILE_DRIVE = "mobile_drive",
   DONATION_CENTER = "donation_center",
   Right = "RIGHT",
}

// Added new constant for location permission (SCREDP-44)
export enum NavigatorStatus {
   PROMPT = "prompt",
   DENIED = "denied",
   GRANTED = "granted",
}

export enum AppointmentTypesOptions {
   Plasma = "PLASMA",
   Platelets = "Platelets",
   WholeBlood = "Whole Blood",
   ConvalescentPlasma = "Convalescent Plasma",
}

export enum PastAppointmentSortByOptions {
   Chronological = "chronological",
   Location = "location",
   Appointment = "appt",
}

export enum DonorStatusOptions {
   Presented = "Presented",
   NoShow = "No Show",
   Canceled = "Canceled",
}
export const locationDropDownOptions: ISelectableOption<LocationOption>[] = [
   {
      label: "All Locations",
      value: LocationOption.ALL,
      iconImgSrc: locationIconSrc,
   },
   {
      label: "Mobile Drive",
      value: LocationOption.MOBILE_DRIVE,
      iconImgSrc: DriveIconSrc,
   },
   {
      label: "Donation Center",
      value: LocationOption.DONATION_CENTER,
      iconImgSrc: HomeIconSrc,
   },
];

export const sortByOptions: ISelectableOption<PastAppointmentSortByOptions>[] =
   [
      {
         label: "Chronological",
         value: PastAppointmentSortByOptions.Chronological,
         iconImgSrc: SortImage,
      },
      {
         label: "Location",
         value: PastAppointmentSortByOptions.Location,
         iconImgSrc: locationIconSrc,
      },
      {
         label: "Appt Type",
         value: PastAppointmentSortByOptions.Appointment,
         iconImgSrc: AppointmentType,
      },
   ];
export const donorStatusOptions: ISelectableOption<DonorStatusOptions>[] = [
   {
      label: "Presented",
      value: DonorStatusOptions.Presented,
   },
   {
      label: "No Show",
      value: DonorStatusOptions.NoShow,
   },
   {
      label: "Canceled",
      value: DonorStatusOptions.Canceled,
   },
];

export enum LocationOrAccountOptions {
   LOCATION = "location",
   ACCOUNT = "account",
}

export enum SortBy {
   ALPHABETICAL_ORDER = "alphabetical_order",
   CHRONOLOGICAL_ORDER = "chronological_order",
   DISTANCE_ORDER = "distance_order",
   PROCEDURE_ORDER = "procedure_Type",
   LOCATION = "location",
}

export const locationOrAccountDropdownOptions: ISelectableOption<LocationOrAccountOptions>[] =
   [
      {
         label: "Location",
         value: LocationOrAccountOptions.LOCATION,
         iconImgSrc: locationFilledIcon,
      },
      {
         label: "Account",
         value: LocationOrAccountOptions.ACCOUNT,
         iconImgSrc: personFilledIcon,
      },
   ];

export const AppointmentTypesDropdownLabels: {
   WB: string;
   PL: string;
} = {
   WB: "Whole Blood",
   PL: "Plasma",
};

export enum ModalPageNames {
   INITIAL = "initial",
   SIGN_IN = "signin",
   NEW_ACCOUNT = "newAccount",
   VERIFYING_ACCOUNT = "verifyingAccount",
   REQUEST_APPOINTMENT = "requestAppointment",
   FORGOT_PASSWORD = "forgotPassword",
   APPOINTMENT_CONFIRMATION = "appointmentConfirmation",
   CHANGE_PASSWORD = "changePassword",
}

export enum OpportunityLocatorViews {
   LANDING_LAYOUT = "basic_layout",
   FULL_MAP_CONTRACTED_LAYOUT = "second_view_layout",
   FULL_MAP_EXPANDED_LAYOUT = "third_view_layout",
}

export enum MobileLayoutSteps {
   MOBILE_MAP_VIEW = "mobileMapView",
   MOBILE_LIST_VIEW_WITH_FILTERS = "mobileListViewWithFilters",
   MOBILE_MAP_VIEW_WITH_FILTERS = "mobileMapViewWithFilters",
}

export enum DONOR_CARD_TYPES {
   QR = "qr",
   CODE128 = "code128",
   PDF417 = "pdf417",
}
