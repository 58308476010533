import React, {
   createContext,
   ReactElement,
   useContext,
   useEffect,
   useState,
} from "react";
import { IAppointment } from "../../../../../../types/IAppointment";
import {
   defaultAppointmentTypes,
   defaultDonorStatus,
   defaultSortedBy,
} from "../pastAppointmentFilterDefaults";
import {
   IMyAppointmentsPageContext,
   MyAppointmentsPageContext,
} from "../../../context/MyAppointmentsPageContext";
import {
   LocationOption,
   PastAppointmentSortByOptions,
} from "../../../../../consts";
import { locationFilter } from "../../../../../../utils/filters/filterOperations/locationFilter";
import {
   getDefaultEndDate,
   getDefaultStartDate,
} from "../../../../../../utils/defaultDates";

export interface IPastAppointmentFilters {
   selectedLocationSource: LocationOption;
   selectedAppointmentTypes: any[];
   startDate: Date;
   endDate: Date;
   selectedDonorStatuses: string[];
   sortBySelected: PastAppointmentSortByOptions;
}

const defaultFilters: IPastAppointmentFilters = {
   selectedLocationSource: LocationOption.ALL,
   selectedAppointmentTypes: defaultAppointmentTypes,
   startDate: getDefaultStartDate([], "appointmentDateTime"),
   endDate: getDefaultEndDate([], "appointmentDateTime"),
   selectedDonorStatuses: defaultDonorStatus,
   sortBySelected: defaultSortedBy,
};

function createFiltersFromPastAppointmentData(
   pastAppointments: IAppointment[] | null,
): IPastAppointmentFilters {
   if (pastAppointments === null) {
      return defaultFilters;
   } else {
      return {
         ...defaultFilters,
         endDate: getDefaultEndDate(pastAppointments, "appointmentDateTime"),
         startDate: getDefaultStartDate(
            pastAppointments,
            "appointmentDateTime",
         ),
      };
   }
}

export interface IFilteredPastAppointmentsContext {
   clearFilters: () => void;
   filters: IPastAppointmentFilters;
   updateFilters: (update: Partial<IPastAppointmentFilters>) => void;
   sortedFilteredAppointments: IAppointment[] | null;
}

const defaultFilteredPastAppointmentsContext: IFilteredPastAppointmentsContext =
{
   clearFilters: () => { },
   filters: defaultFilters,
   updateFilters: (update: Partial<IPastAppointmentFilters>) => { },
   sortedFilteredAppointments: null,
};

export const FilteredPastAppointmentsContext =
   createContext<IFilteredPastAppointmentsContext>(
      defaultFilteredPastAppointmentsContext,
   );

export interface IFilteredPastAppointmentsContextProviderProps {
   children: React.ReactElement;
}

function appointmentTypeFilter(
   item: IAppointment,
   appointmentTypesSelected,
): boolean {
   if (!appointmentTypesSelected.length) return true;

   let isMatched: boolean = false;

   appointmentTypesSelected.some((selectedType: any) => {
      if (
         selectedType.toLowerCase() ===
         item.appointmentTypeDescription.toLocaleLowerCase()
      )
         isMatched = true;
   });

   return isMatched;
}

function donorStatusFilter(item: IAppointment, donorStatusSelected): boolean {
   if (!donorStatusSelected.length) return true;

   let isMatched: boolean = false;

   donorStatusSelected.some((selectedType: any) => {
      if (selectedType.toLowerCase() === item.outcome.toLocaleLowerCase())
         isMatched = true;
   });

   return isMatched;
}

function dateFilter(
   appointment: IAppointment,
   startDate: Date,
   endDate: Date,
): boolean {
   const IsDateRangeSelected: boolean = Boolean(startDate && endDate);
   if (!IsDateRangeSelected) {
      return true;
   } else {
      try {
         const appointmentDate: Date = new Date(
            appointment.appointmentDateTime,
         );
         return startDate <= appointmentDate && appointmentDate <= endDate;
      } catch (err) {
         console.error(err);
         return false;
      }
   }
}

function appointmentMatchesFilter(
   appointment: IAppointment,
   filters: IPastAppointmentFilters,
): boolean {
   return (
      dateFilter(appointment, filters.startDate, filters.endDate) &&
      donorStatusFilter(appointment, filters.selectedDonorStatuses) &&
      appointmentTypeFilter(appointment, filters.selectedAppointmentTypes) &&
      locationFilter(appointment.drive.location, filters.selectedLocationSource)
   );
}

function sortAppointmentsChronological(
   a: IAppointment,
   b: IAppointment,
): number {
   return (
      new Date(b.appointmentDateTime).getTime() -
      new Date(a.appointmentDateTime).getTime()
   );
}

function sortFilteredAppointments(
   filteredAppointments: IAppointment[],
   sortBySelected: PastAppointmentSortByOptions,
): IAppointment[] {
   switch (sortBySelected) {
      case PastAppointmentSortByOptions.Appointment:
         return filteredAppointments.sort(
            (a: IAppointment, b: IAppointment) => {
               if (a.appointmentType < b.appointmentType) {
                  return -1;
               }
               if (a.appointmentType > b.appointmentType) {
                  return 1;
               }
               return sortAppointmentsChronological(a, b);
            },
         );
      case PastAppointmentSortByOptions.Location:
         return filteredAppointments.sort(
            (a: IAppointment, b: IAppointment) => {
               if (a.drive.location.name < b.drive.location.name) {
                  return -1;
               }
               if (a.drive.location.name > b.drive.location.name) {
                  return 1;
               }
               return sortAppointmentsChronological(a, b);
            },
         );
      case PastAppointmentSortByOptions.Chronological:
         return filteredAppointments.sort(sortAppointmentsChronological);
      default:
         throw new Error("Invalid sort selected");
   }
}

function applyPastAppointmentFilters(
   appointments: IAppointment[],
   filters: IPastAppointmentFilters,
): IAppointment[] {
   return appointments.filter((appointment: IAppointment) =>
      appointmentMatchesFilter(appointment, filters),
   );
}
export function FilteredPastAppointmentsContextProvider(
   props: IFilteredPastAppointmentsContextProviderProps,
): ReactElement {
   const [sortedFilteredAppointments, setSortedFilteredAppointments] = useState<
      IAppointment[] | null
   >(null);
   const { pastAppointments } = useContext<IMyAppointmentsPageContext>(
      MyAppointmentsPageContext,
   );
   const [filters, setFilters] =
      useState<IPastAppointmentFilters>(defaultFilters);
   const clearFilters = () => setFilters(defaultFilters);
   useEffect(() => {
      if (pastAppointments === null) {
         if (sortedFilteredAppointments !== null) {
            setSortedFilteredAppointments(null);
         }
      } else {
         setSortedFilteredAppointments(
            sortFilteredAppointments(
               applyPastAppointmentFilters(pastAppointments, filters),
               filters.sortBySelected,
            ),
         );
      }
   }, [pastAppointments, filters]);

   useEffect(() => {
      if (sortedFilteredAppointments === null && pastAppointments) {
         // Set filters conditional on the appointments the first time we fetch it.
         setFilters(createFiltersFromPastAppointmentData(pastAppointments));
      }
   }, [pastAppointments]);

   return (
      <FilteredPastAppointmentsContext.Provider
         value={{
            filters,
            updateFilters: (update: Partial<IPastAppointmentFilters>) => {
               setFilters({
                  ...filters,
                  ...update,
               });
            },
            clearFilters,
            sortedFilteredAppointments,
         }}
      >
         {props.children}
      </FilteredPastAppointmentsContext.Provider>
   );
}
