import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {
   ChevronUpButtonContainer,
   ChevronDownButtonContainer,
   ChevronDownIcon,
   ChevronUpIcon,
} from "./styled";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../../../OpportunitySearchComponent/context/OpportunitySearchContext";
import { EnvironmentContext } from "app/contexts/environment/environmentContext";

interface FormInputCounterProps {
   name: string;
   placeholder?: string;
}

const FormInputCounter: React.FC<FormInputCounterProps> = ({ name }) => {
   const {
      filters: { radiusMiles },
      updateFilter,
   } = useContext<IOpportunitySearchContext>(OpportunitySearchContext);

   const { environmentConfig } = useContext(EnvironmentContext);
   const defaultRadiusValue = (environmentConfig?.defaultRadius ? environmentConfig?.defaultRadius + "" : "10"); // admin defined radius value
   const miles = { FIVE: "5", TEN: "10", TWENTY_FIVE: "25" }; // default radius miles

   // Added a new defaultRadiusValue that will be provided in the admin portal. It can be any number from 0 to 25. (SCREDP-92) 
   const handleIncrease = () => {
      switch (radiusMiles) {
         case miles.FIVE:
            if (parseInt(defaultRadiusValue) < parseInt(miles.TEN) && parseInt(defaultRadiusValue) > parseInt(miles.FIVE)) {
               updateFilter({ radiusMiles: defaultRadiusValue });
            } else {
               updateFilter({ radiusMiles: miles.TEN });
            }
            break;
         case miles.TEN:
            if (parseInt(defaultRadiusValue) > parseInt(miles.TEN) && parseInt(defaultRadiusValue) < parseInt(miles.TWENTY_FIVE)) {
               updateFilter({ radiusMiles: defaultRadiusValue });
            } else {
               updateFilter({ radiusMiles: miles.TWENTY_FIVE });
            }
            break;
         case miles.TWENTY_FIVE:
            return;
         default:
            // Check if defaultRadiusValue is between predefined values (SCREPD-92)
            if (parseInt(defaultRadiusValue) < parseInt(miles.FIVE)) {
               updateFilter({ radiusMiles: miles.FIVE });
            } else if (parseInt(defaultRadiusValue) > parseInt(miles.FIVE) && parseInt(defaultRadiusValue) < parseInt(miles.TEN)) {
               updateFilter({ radiusMiles: miles.TEN });
            } else if (parseInt(defaultRadiusValue) > parseInt(miles.TEN) && parseInt(defaultRadiusValue) < parseInt(miles.TWENTY_FIVE)) {
               updateFilter({ radiusMiles: miles.TWENTY_FIVE });
            }
            break;
      }

   };

   const handleDown = () => {
      switch (radiusMiles) {
         case miles.FIVE:
            if (parseInt(defaultRadiusValue) < parseInt(miles.FIVE)) { // defaultRadiusValue = 0
               updateFilter({ radiusMiles: defaultRadiusValue });
            }
            break;
         case miles.TEN:
            if (parseInt(defaultRadiusValue) < parseInt(miles.TEN) && parseInt(defaultRadiusValue) > parseInt(miles.FIVE)) {
               updateFilter({ radiusMiles: defaultRadiusValue });
            } else {
               updateFilter({ radiusMiles: miles.FIVE });
            }
            break;
         case miles.TWENTY_FIVE:
            if (parseInt(defaultRadiusValue) < parseInt(miles.TWENTY_FIVE) && parseInt(defaultRadiusValue) > parseInt(miles.TEN)) {
               updateFilter({ radiusMiles: defaultRadiusValue });
            } else {
               updateFilter({ radiusMiles: miles.TEN });
            }
            break;
         default:
            // Check if defaultRadiusValue is between predefined values (SCREPD-92)
            if (parseInt(defaultRadiusValue) < parseInt(miles.FIVE)) {
               updateFilter({ radiusMiles: defaultRadiusValue });
            } else if (parseInt(defaultRadiusValue) > parseInt(miles.FIVE) && parseInt(defaultRadiusValue) < parseInt(miles.TEN)) {
               updateFilter({ radiusMiles: miles.FIVE });
            } else if (parseInt(defaultRadiusValue) > parseInt(miles.TEN) && parseInt(defaultRadiusValue) < parseInt(miles.TWENTY_FIVE)) {
               updateFilter({ radiusMiles: miles.TEN });
            }
            break;
      }
   };

   return (
      <InputGroup style={{ zIndex: 0 }}>
         <Form.Control
            placeholder="Miles"
            readOnly
            value={`${radiusMiles} Miles`}
         />
         <div className="d-flex flex-column">
            <ChevronUpButtonContainer
               variant="outline-secondary"
               onClick={handleIncrease}
            >
               <ChevronUpIcon />
            </ChevronUpButtonContainer>
            <ChevronDownButtonContainer
               variant="outline-secondary"
               onClick={handleDown}
            >
               <ChevronDownIcon />
            </ChevronDownButtonContainer>
         </div>
      </InputGroup>
   );
};

export default FormInputCounter;
