import { useContext, useEffect, useState } from "react";
import { getBloodDriveDataServiceInstance } from "../../../../services/bexWISE/bloodDriveDataService/getBloodDriveDataServiceInstance";
import { IAppointmentType } from "../../../../types/IAppointmentType";
import { IBloodDrive } from "../../../../types/IBloodDrive";
import { IBloodDriveDataService } from "../../../../services/bexWISE/bloodDriveDataService/IBloodDriveDataService";
import { UserContext } from "../../../contexts/user/userContext";

export const formatDateValue = (date: string | Date): string => {
   const dateSent: Date = new Date(date);
   const year: string = dateSent.toLocaleString("default", { year: "numeric" });
   const month: string = dateSent.toLocaleString("default", {
      month: "2-digit",
   });
   const day: string = dateSent.toLocaleString("default", { day: "2-digit" });
   return `${year}-${month}-${day}`;
};
export const useAppointmentData = (drive_id: string, drive_date: string, origin: string, detailsRef: IBloodDrive) => {
   const [appointmentData, setAppointmentData] = useState<IBloodDrive | null>();
   const [appointmentTypes, setAppointmentTypes] = useState<IAppointmentType[]>([],);
   const [appointmentType, setAppointmentType] = useState<string>("");
   const { eligibilityDates } = useContext(UserContext);

   const getAppointmentData = async (): Promise<void> => {
      try {
         const bloodDriveDataService: IBloodDriveDataService =
            getBloodDriveDataServiceInstance();
         const response: IBloodDrive = await bloodDriveDataService.getDrive(
            drive_id,
            drive_date,
            origin, // Added the origin parameter needed for the API call (SCREDP-77)
         );
         setAppointmentData(response);
         setAppointmentTypes(response.appointmentTypes);
         setAppointmentType(response.appointmentTypes[0].appointmentType);
      } catch (err) {
         throw err;
      }
   };

   useEffect(() => {
      // If the drive details (detailsRef) aren't in memory, then make the call to the bexWISE API to retrieve the drive info. (SCREDP-77)
      if (detailsRef) {
         setAppointmentData(detailsRef);
         setAppointmentTypes(detailsRef.appointmentTypes);
         setAppointmentType(detailsRef.appointmentTypes[0].appointmentType);
      } else {
         getAppointmentData();
      }
   }, []);

   return {
      appointmentData,
      appointmentTypes,
      appointmentType,
      setAppointmentType,
      eligibilityDates,
   };
};
