import React, { ReactElement, useContext } from "react";
import { locationDropDownOptions, SortBy } from "../../../../../consts";
import { DateRangePicker } from "../../../../../components/Form/Inputs/Generic/DateRangePicker/DateRangePicker";
import { SingleSelect } from "../../../../../components/Form/Inputs/Generic/SingleSelect/SingleSelect";
import {
   DonationContext,
   IDonationContext,
   IMyDonationsFilters,
} from "../../../context/DonationContext";
import { CheckMarkImage } from "./checkMarkImageStyled";
import { MultipleSelect } from "../../../../../components/Form/Inputs/Generic/MultipleSelect/MultipleSelect";
import { ReactComponent as DropIcon } from "../../../../../../assets/svgs/drop.svg";
import {
   ModalInputListGroup,
   ModalInputListGroupType,
} from "../../../../../components/FiltersModal/ModalInputListGroup";
import {
   DonationOutcome,
   DonationOutcomes,
} from "../../../../../../services/bexWISE/donorDataService/IDonorDataService";
import { getEndOfDay, getStartOfDay } from "../../../../../../utils/dateUtil";

export interface IMyDonationsFiltersModalBodyProps {
   isMobile: boolean;
   currentFilter: IMyDonationsFilters;
   updateFilter: (updatedFilter: Partial<IMyDonationsFilters>) => void;
}

export function MyDonationsFiltersModalBody({
   isMobile,
   currentFilter,
   updateFilter,
}: IMyDonationsFiltersModalBodyProps): ReactElement<IMyDonationsFiltersModalBodyProps> {
   const { startDate, endDate, creditTo } = currentFilter;
   const { creditToList, procedureList } =
      useContext<IDonationContext>(DonationContext);
   return (
      <div className={"MyDonationsFiltersModalBody"}>
         {isMobile && (
            <>
               <SingleSelect
                  values={locationDropDownOptions}
                  selectedValue={
                     locationDropDownOptions.find(
                        o => o.value === currentFilter.selectedLocationSource,
                     )!
                  }
                  onValueSelected={value =>
                     updateFilter({ selectedLocationSource: value.value })
                  }
               />
               <MultipleSelect
                  selectedValues={currentFilter.selectedProcedures}
                  values={procedureList}
                  placeholder={"Select Procedures"}
                  onSelectedValuesChanges={newValue =>
                     updateFilter({ selectedProcedures: newValue })
                  }
                  staticIconSrc={<DropIcon className={"input-label-image"} />}
               />
               <DateRangePicker
                  startDate={startDate}
                  setStartDate={(newDate: string) =>
                     updateFilter({
                        startDate: getStartOfDay(new Date(newDate)),
                     })
                  }
                  endDate={endDate}
                  setEndDate={(newDate: string) =>
                     updateFilter({
                        endDate: getEndOfDay(new Date(newDate)),
                     })
                  }
               />
               <MultipleSelect
                  selectedValues={currentFilter.selectedDonationOutcomes}
                  values={DonationOutcomes}
                  onSelectedValuesChanges={newValue =>
                     updateFilter({
                        selectedDonationOutcomes: newValue as DonationOutcome[],
                     })
                  }
                  placeholder={"Select Outcome"}
                  staticIconSrc={
                     <CheckMarkImage className={"input-label-image"} />
                  }
               />
            </>
         )}
         <ModalInputListGroup
            title={"Credit To"}
            type={ModalInputListGroupType.CheckList}
            options={creditToList}
            selectedOptions={creditTo}
            name={"creditTo"}
            onSelectedValuesChange={(selectedValues: string[]) => {
               updateFilter({
                  ...currentFilter,
                  creditTo: selectedValues,
               });
            }}
         />
         <ModalInputListGroup
            title={"Sort By"}
            type={ModalInputListGroupType.RadioList}
            options={[
               SortBy.CHRONOLOGICAL_ORDER,
               SortBy.LOCATION,
               SortBy.PROCEDURE_ORDER,
            ]}
            selectedOption={currentFilter.sortBy}
            name={"sortBy"}
            onSelectedValueChange={(selectedValue: string) => {
               updateFilter({
                  ...currentFilter,
                  sortBy: selectedValue,
               });
            }}
         />
      </div>
   );
}
