import React, { ReactElement, useContext } from "react";
import {
   ModalInputListGroup,
   ModalInputListGroupType,
} from "../../../FiltersModal/ModalInputListGroup";
import { SortBy } from "../../../../consts";
import { IOpportunitySearchFilter } from "../../hooks/useOpportunitySearchFilter";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../context/OpportunitySearchContext";

export interface IOpportunitySearchMoreFiltersBodyProps {
   currentFilter: IOpportunitySearchFilter;
   updateFilter: (updatedFilter: Partial<IOpportunitySearchFilter>) => void;
}

export function OpportunitySearchMoreFiltersBody({
   currentFilter,
   updateFilter,
}: IOpportunitySearchMoreFiltersBodyProps): ReactElement<IOpportunitySearchMoreFiltersBodyProps> {
   const { uniqueAppointmentTypes } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );

   return (
      <div className={"OpportunitySearchMoreFiltersBody"}>
         {uniqueAppointmentTypes.length > 0 && (
            <>
               <ModalInputListGroup
                  title={"Appointment Types"}
                  type={ModalInputListGroupType.CheckList}
                  options={uniqueAppointmentTypes}
                  selectedOptions={currentFilter.selectedAppointmentTypes!}
                  name={"sortBy"}
                  onSelectedValuesChange={(selectedValues: string[]) => {
                     updateFilter({
                        selectedAppointmentTypes: selectedValues,
                     });
                  }}
               />
               <hr key={"sep"} className="mt-4 mb-4" />
            </>
         )}
         <ModalInputListGroup
            title={"Sort By"}
            type={ModalInputListGroupType.RadioList}
            options={[
               SortBy.CHRONOLOGICAL_ORDER,
               SortBy.ALPHABETICAL_ORDER,
               SortBy.DISTANCE_ORDER,
            ]}
            selectedOption={currentFilter.sortBy}
            name={"sortBy"}
            onSelectedValueChange={(selectedValue: string) => {
               updateFilter({
                  sortBy: selectedValue as SortBy,
               });
            }}
         />
      </div>
   );
}
