import { IDonorDTO } from "../../../types/IDonorDTO";
import { AppointmentTime } from "../../../types/AppointmentTime";
import { IAccount } from "../../../types/IAccount";
import { ILocation } from "../../../types/ILocation";

export interface IDonorEligibilityDate {
   appointmentType: string;
   eligibleDate: string;
   appointmentTypeDescription: string;
}

export interface IDonorAppointmentDTO {
   appointmentId: string;
   appointmentDate: Date;
   appointmentTime: AppointmentTime;
   canceled: boolean;
   appointmentType: string;
   account: IAccount;
   location: ILocation;
   madeBy: "Staff" | "Donor";
}

export interface IPhysicalExamReading {
   hemoglobin: number;
   hematocrit: number;
   bloodPressureSystolic: number;
   bloodPressureDiastolic: number;
   pulse: number;
   temperature: number;
}
export interface IPhysicalExamData {
   height: number;
   weight: number;
   CuS04: string;
   firstReading: IPhysicalExamReading;
   secondReading: IPhysicalExamReading;
}

export type DonationOutcome = "Successful" | "Failed" | "Deferred";
export const DonationOutcomes: DonationOutcome[] = [
   "Successful",
   "Failed",
   "Deferred",
];
export interface IDonorDonationDTO {
   date: string;
   donationProcedure: string;
   donationProcedureDescription: string;
   location: ILocation;
   physicalExam: IPhysicalExamData;
   creditToLocation: ILocation;
   outcome: DonationOutcome;
}

export interface IDonorContactPreference {
   contactPreferenceCode: string;
   contactPreferenceDescription: string;
}

export interface IDonorQRCodeInterface {
   width: string;
   height: string;
   type: string;
}

export interface IDonorValidationRequest {
   firstName: string;
   lastName: string;
   emailAddress: string;
   donorId: number;
   dateOfBirth: string;
}
export interface IDonorDataService {
   getDonor(donorId: string): Promise<IDonorDTO>;
   getDonorEligibilityDates(donorId: string): Promise<IDonorEligibilityDate[]>;
   getDonorAppointment(
      donorId: string,
      appointmentId: string,
   ): Promise<IDonorAppointmentDTO>;
   getDonorAppointments(donorId: string): Promise<IDonorAppointmentDTO[]>;
   getDonorDonations(donorId: string): Promise<IDonorDonationDTO[]>;
   getDonorIdCard(
      donorId: string,
      qrcodeParameters: IDonorQRCodeInterface,
   ): Promise<Blob>;
   getDonorContactPreferences(
      donorId: string,
   ): Promise<IDonorContactPreference[]>;
   setDonorContactPreferences(
      donorId: string,
      preferences: IDonorContactPreference[],
   ): Promise<void>;
   validateDonor(validationRequest: IDonorValidationRequest): Promise<boolean>;
}
