import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { ReactI18NextChild } from "react-i18next";
import { defaultUserContext, IUserContext, UserContext } from "./userContext";
import { IDonorDTO } from "../../../types/IDonorDTO";
import { IDonorEligibilityDate } from "../../../services/bexWISE/donorDataService/IDonorDataService";
import { AuthContext } from "../auth/authContext";
import { getDonorDataServiceInstance } from "../../../services/bexWISE/donorDataService/getDonorDataServiceInstance";

export interface IUserProps {
   children: ReactI18NextChild;
}
export function UserContextProvider(props: IUserProps) {
   const { userDonorId } = useContext(AuthContext);
   const [context, setContext] = useState<IUserContext>(defaultUserContext);

   useEffect(() => {
      if (userDonorId === "" || !userDonorId) {
         // Donor is signed out
         setContext({
            profileData: null,
            eligibilityDates: null,
            userDonorId: null,
         });
      } else {
         // Donor is signed in
         const profilePromise: Promise<IDonorDTO> =
            getDonorDataServiceInstance().getDonor(userDonorId);
         const eligibilityDatesPromise: Promise<IDonorEligibilityDate[]> =
            getDonorDataServiceInstance().getDonorEligibilityDates(userDonorId);

         Promise.all([profilePromise, eligibilityDatesPromise]).then(
            ([profileData, eligibilityDates]) => {
               setContext({
                  profileData,
                  eligibilityDates,
                  userDonorId,
               });
            },
         );
      }
   }, [userDonorId]);

   return (
      <UserContext.Provider value={context}>
         {props.children}
      </UserContext.Provider>
   );
}
