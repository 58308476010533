import React from "react";
import CommonHeader from "../../../../components/CommonHeader";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import LabelContentComponent from "../LabelContentComponent";
import { IDonorDTO } from "../../../../../types/IDonorDTO";
import { Section } from "../../../../components/Section/Section";
interface PersonalInformationProps {
   profileData: IDonorDTO;
   fullName: string;
   dateDisplay: string;
}
const PersonalInformation: React.FC<PersonalInformationProps> = ({
   profileData,
   fullName,
   dateDisplay,
}) => {
   return (
      <Section className="personal-card-css">
         <CommonHeader title="PERSONAL INFORMATION" />
         <div className="personal-content-css">
            <Row className="personal-section-css">
               <Col>
                  <LabelContentComponent
                     label={"First Name Last Name:"}
                     value={fullName}
                  />
               </Col>
               <Col>
                  <LabelContentComponent
                     label={"Blood Type:"}
                     value={profileData?.bloodType?.symbol}
                  />
               </Col>
            </Row>
            <hr className="line-css" />
            <Row className="personal-section-css">
               <Col>
                  <LabelContentComponent label={"DOB:"} value={dateDisplay} />
               </Col>
               <Col>
                  <LabelContentComponent
                     label={"CMV Status"}
                     value={profileData?.cmvNegative}
                  />
               </Col>
            </Row>
            <hr className="line-css" />
            <Row className="personal-section-css">
               <Col>
                  <LabelContentComponent
                     label={"Sex:"}
                     value={profileData?.sex?.description}
                  />
               </Col>
               <Col>
                  <LabelContentComponent
                     label={"Race:"}
                     value={profileData?.race?.description}
                  />
               </Col>
            </Row>
         </div>
      </Section>
   );
};

export default PersonalInformation;
