import React, { useContext, useState } from "react";
import { Nav, Stack, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
   CalendarImg,
   CircleUserSmallImg,
   CircleUserSolidImg,
   ClipBoardImg,
   DonationImg,
   GearImg,
   LogOutImg,
   PencilImg,
   PersonVcardImg,
   SearchImg,
   StyledNav,
   StyledNavDiv,
} from "./styled";
import { AuthContext, IAuthContext } from "../../../contexts/auth/authContext";
import { NavigateFunction, useLocation } from "react-router-dom";
import { LayoutContext } from "../../../layouts/context/layoutContext";
import { ROUTE_PATHS } from "../../../consts/RoutePaths";
import { EnvironmentContext } from "../../../contexts/environment/environmentContext";
import { IUserContext, UserContext } from "../../../contexts/user/userContext";
import { useMediaQuery } from "react-responsive";

interface NavBarInterface {
   title: string;
   svg: JSX.Element;
   href: string;
}
const LayoutNavigationBarComponent = (): JSX.Element => {
   const { isNavBarExpanded } = useContext(LayoutContext);
   const navigate: NavigateFunction = useNavigate();
   const { pathname } = useLocation();
   const { signOut } = useContext<IAuthContext>(AuthContext);
   const { profileData } = useContext<IUserContext>(UserContext);
   const [displayProfileSection, setDisplayProfileSection] =
      useState<boolean>(true);
   const { environmentConfig } = useContext(EnvironmentContext);
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });

   const NavBarUpperContent: NavBarInterface[] = [
      {
         title: "Profile",
         svg: <PersonVcardImg />,
         href: ROUTE_PATHS.PROFILE,
      },
      {
         title: "Preferences",
         svg: <GearImg />,
         href: ROUTE_PATHS.PREFERENCES,
      },
      {
         title: "Logout",
         svg: <LogOutImg />,
         href: "logout",
      },
   ];
   const NavBarLowerContent: NavBarInterface[] = [
      {
         title: "Opportunity Locator",
         svg: <SearchImg />,
         href: ROUTE_PATHS.LANDING_PATH,
      },
      {
         title: "Donations",
         svg: <DonationImg />,
         href: ROUTE_PATHS.MY_Donations,
      },
      {
         title: "Appointments",
         svg: <CalendarImg />,
         href: ROUTE_PATHS.MY_APPOINTMENTS,
      },
      {
         title: "Physical Exams",
         svg: <ClipBoardImg />,
         href: ROUTE_PATHS.PHYSICAL_EXAMS,
      },
   ];

   const navigationClick = (href: string): void => {
      if (href === "logout") {
         signOut();
      } else {
         navigate(href);
      }
   };

   return (
      <StyledNavDiv id={"NavBarComponent"}>
         <Stack
            gap={2}
            className={
               displayProfileSection ? "profile-name-section active" : "profile-name-section"
            }
            onClick={() => setDisplayProfileSection(!displayProfileSection)}
         >
            {/* Added mouse-over text to the icons (SCREDP-43) */}
            {isNavBarExpanded ?
               <CircleUserSolidImg />
               :
               (
                  <OverlayTrigger
                     placement="right"
                     overlay={<Tooltip>{profileData?.firstName} {profileData?.lastName}</Tooltip>}
                  >
                     <CircleUserSmallImg />
                  </OverlayTrigger>
               )

            }
            {isNavBarExpanded && (
               <div className="fw-bold userName">
                  <span className="mr-2">
                     {profileData?.firstName} {profileData?.lastName}
                  </span>
                  <i className="pi pi-fw pi-small pi-chevron-up"></i>
               </div>
            )}
         </Stack>
         <StyledNav
            className="d-md-block"
            onSelect={selectedKey => alert(`selected ${selectedKey}`)}
         >
            {displayProfileSection && (
               <div className="navbar-upper-section">
                  {NavBarUpperContent.map(eachLink => {
                     {/* Added mouse-over text to each icon (SCREDP-43) */ }
                     return (
                        <Nav.Item key={eachLink.title} >
                           {isMobile ? (
                              <Nav.Link
                                 className={`nav-each-item ${pathname === eachLink.href
                                    ? "selected-link"
                                    : ""
                                    }`}
                                 onClick={() => navigationClick(eachLink.href)}
                              >
                                 {eachLink.svg}
                                 {isNavBarExpanded && eachLink.title}
                              </Nav.Link>
                           )
                              : (
                                 <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip>{eachLink.title}</Tooltip>}
                                 >
                                    <Nav.Link
                                       className={`nav-each-item ${pathname === eachLink.href
                                          ? "selected-link"
                                          : ""
                                          }`}
                                       onClick={() => navigationClick(eachLink.href)}
                                    >
                                       {eachLink.svg}
                                       {isNavBarExpanded && eachLink.title}
                                    </Nav.Link>
                                 </OverlayTrigger>
                              )}

                        </Nav.Item>
                     );
                  })}
               </div>
            )}
            <div className="navbar-lower-section">
               {NavBarLowerContent.map(eachLink => {
                  {/* Added mouse-over text to the icons (SCREDP-43) */ }
                  return (
                     <Nav.Item key={eachLink.title}>
                        {isMobile ? (<Nav.Link
                           className={`nav-each-item ${pathname === eachLink.href ? "selected-link" : ""
                              }`}
                           onClick={() => navigationClick(eachLink.href)}
                        >
                           {eachLink.svg}
                           {isNavBarExpanded && eachLink.title}
                        </Nav.Link>)
                           : (
                              <OverlayTrigger
                                 placement="right"
                                 overlay={<Tooltip>{eachLink.title}</Tooltip>}
                              >
                                 <Nav.Link
                                    className={`nav-each-item ${pathname === eachLink.href ? "selected-link" : ""
                                       }`}
                                    onClick={() => navigationClick(eachLink.href)}
                                 >
                                    {eachLink.svg}
                                    {isNavBarExpanded && eachLink.title}
                                 </Nav.Link>
                              </OverlayTrigger>
                           )}

                     </Nav.Item>
                  );
               })}
            </div>
            {
               environmentConfig &&
               environmentConfig.idhqURL && (
                  <div className="navbar-lower-section">

                     {/* Added the "Questionnaire" button as part of the menu and added mouse-over text to the icons (SCREDP-43) */}
                     <Nav.Item key={"Pre-Donation Questionnaire"}>
                        {/* Show different type of Questionnaire button depending on the view (SCREDP-78) */}
                        {isMobile ? (
                           <Nav.Link
                              className={isNavBarExpanded ? "nav-each-item questionnaire questionnaireOverlay p-button-icon-only" : "questionnaire questionnaireSlim p-button-icon-only"}
                              onClick={() => window.open(environmentConfig.idhqURL, "_blank")}
                           >
                              <PencilImg />
                              {isNavBarExpanded && "Pre-Donation Questionnaire"}
                           </Nav.Link>
                        )
                           : (
                              <OverlayTrigger
                                 placement="right"
                                 overlay={<Tooltip>Pre-Donation Questionnaire</Tooltip>}
                              >

                                 <Nav.Link
                                    className={isNavBarExpanded ? "nav-each-item questionnaire questionnaireOverlay p-button-icon-only" : "questionnaire questionnaireSlim p-button-icon-only"}
                                    onClick={() => window.open(environmentConfig.idhqURL, "_blank")}
                                 >
                                    <PencilImg />
                                    {isNavBarExpanded && "Pre-Donation Questionnaire"}

                                 </Nav.Link>
                              </OverlayTrigger>
                           )}

                     </Nav.Item>
                  </div>

               )}
         </StyledNav>
      </StyledNavDiv>
   );
};
export default LayoutNavigationBarComponent;
