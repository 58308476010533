import { LocationOrAccountOptions, SortBy } from "app/consts";
import { IBloodDrive } from "types/IBloodDrive";
import { locationFilter } from "../../../../utils/filters/filterOperations/locationFilter";
import { dateFilter } from "../../../../utils/filters/filterOperations/dateFilter";
import { IOpportunitySearchFilter } from "../hooks/useOpportunitySearchFilter";
import {
   alphabeticalSorting,
   chronologicalOrder,
   distanceSorting,
} from "../../../../utils/sorting";

const appointmentTypeFilter = (
   item: IBloodDrive,
   appointmentTypes: string[],
): boolean => {
   try {
      if (appointmentTypes.length === 0) {
         return true;
      } else {
         let isMatched: boolean = false;
         item.appointmentTypes.some(item =>
            appointmentTypes.some((selectedType: string) => {
               if (
                  selectedType.toLowerCase() ===
                     item.appointmentTypeDescription.toLocaleLowerCase() &&
                  item.appointmentsRemaining > 0
               ) {
                  isMatched = true;
               }
            }),
         );
         return isMatched;
      }
   } catch (err) {
      console.error(err);
      return false;
   }
};

const mileFilter = (item: IBloodDrive, distanceInMiles: number): boolean => {
   const meterToMilesConversion = 0.000621371;

   if (item?.location?.distanceFromOriginMeters) {
      const convertedDistance: string = (
         item?.location?.distanceFromOriginMeters * meterToMilesConversion
      ).toFixed(2);
      return Number(convertedDistance) <= distanceInMiles;
   }
   return true;
};

const opportunityLocatorFilters = (
   item: IBloodDrive,
   filter: IOpportunitySearchFilter,
): boolean | undefined => {
   try {
      if (filter.searchType === LocationOrAccountOptions.LOCATION) {
         return (
            locationFilter(item.location, filter.locationType) &&
            mileFilter(item, Number(filter.radiusMiles)) &&
            appointmentTypeFilter(
               item,
               filter.selectedAppointmentTypes || [],
            ) &&
            dateFilter(
               filter.startDate,
               filter.endDate,
               new Date(item.startTime),
            )
         );
      } else {
         return (
            locationFilter(item.location, filter.locationType) &&
            appointmentTypeFilter(
               item,
               filter.selectedAppointmentTypes || [],
            ) &&
            dateFilter(
               filter.startDate,
               filter.endDate,
               new Date(item.startTime),
            )
         );
      }
   } catch (err) {
      console.error("Error Occurred While Filtering Results");
   }
};

export const getFilteredData = (
   drives: IBloodDrive[],
   filters: IOpportunitySearchFilter,
): IBloodDrive[] => {
   try {
      const filteredDrives: IBloodDrive[] = drives.filter(item => {
         return opportunityLocatorFilters(item, filters);
      });

      if (filteredDrives.length === 0) {
         return [];
      } else {
         switch (filters.sortBy) {
            case SortBy.ALPHABETICAL_ORDER:
               return alphabeticalSorting(filteredDrives);
            case SortBy.DISTANCE_ORDER:
               return distanceSorting(filteredDrives);
            case SortBy.CHRONOLOGICAL_ORDER:
               return chronologicalOrder(filteredDrives);
            default:
               console.error("Unsupported sorting method");
               return chronologicalOrder(filteredDrives);
         }
      }
   } catch (err) {
      return [];
   }
};
