import React from "react";
import Modal from "react-bootstrap/Modal";
import {
   AptConfirmModalBody,
   CalendarCheckImg,
   CalendarIconImg,
   CalendarXImg,
   CheckMarkImg,
   CrossIconImg,
} from "./styled";
import { Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { getAppointmentDataServiceInstance } from "../../../../services/bexWISE/appointmentDataService/getAppointmentDataServiceInstance";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { IAppointmentDataService } from "../../../../services/bexWISE/appointmentDataService/IAppointmentDataService";
import { handleCalendarDownload } from "./handleCalendarDownload";
import { AppointmentKinds } from "../../../consts/AppointmentKinds";
import { ROUTE_PATHS } from "../../../consts/RoutePaths";
import { IEventInterface } from "./IEventInterface";

function AppointmentConfirmationModal({
   onClose,
   confirmedAptDetails,
   setIsCancelConfirmed,
}) {
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });
   const navigate: NavigateFunction = useNavigate();

   const formattedDate = (date: Date): string => {
      const options: Intl.DateTimeFormatOptions = {
         weekday: "long",
         year: "numeric",
         month: "long",
         day: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
   };

   // Date time string is of the format: 2023-03-17T13:00:00-05:00
   // Return 2023-03-17
   const getDateStringFromDateTimeString = (dateTimeString: string): string => {
      return dateTimeString.substring(0, 10);
   };

   const formattedTime = (time: string): string => {
      return new Date(time.replace("Z", "")).toLocaleTimeString("en-US", {
         hour: "2-digit",
         minute: "2-digit",
      });
   };
   const AddressTwoSection = ({ confirmedAptDetails }): JSX.Element => {
      return (
         <div>
            {confirmedAptDetails?.city.toUpperCase() || ""},{" "}
            {confirmedAptDetails.state.toUpperCase() || ""}{" "}
            {confirmedAptDetails.zipCode || ""}
         </div>
      );
   };

   const IconSection = (
      appointmentKind: AppointmentKinds,
   ): JSX.Element | string => {
      if (
         [
            AppointmentKinds.CONFIRMED,
            AppointmentKinds.REQUESTED,
            AppointmentKinds.RESCHEDULED,
         ].includes(appointmentKind)
      )
         return <CalendarCheckImg />;
      if (
         [AppointmentKinds.CANCEL, AppointmentKinds.NOT_ELIGIBLE].includes(
            appointmentKind,
         )
      )
         return <CalendarXImg />;
      return "";
   };
   const HeaderSection = (
      appointmentKind: AppointmentKinds,
   ): JSX.Element | string => {
      switch (appointmentKind) {
         case AppointmentKinds.CONFIRMED:
            return (
               <>
                  {confirmedAptDetails.userFirstName}, we've confirmed your
                  appointment <br></br>
                  We'll see you there!
               </>
            );
         case AppointmentKinds.REQUESTED:
            return (
               <>
                  {confirmedAptDetails.userFirstName}, a representative will
                  contact you soon
                  <br></br>
                  to confirm your appointment.
               </>
            );
         case AppointmentKinds.RESCHEDULED:
            return <>Are you sure you want to Reschedule this appointment?</>;
         case AppointmentKinds.CANCEL:
            return <>Are you sure you want to Cancel this appointment?</>;
         case AppointmentKinds.NOT_ELIGIBLE:
            return (
               <>
                  {confirmedAptDetails.userFirstName}, you are not eligible to
                  donate
               </>
            );
         default:
            return "";
      }
   };

   const MidSection = ({ isEligibleToDonate }): JSX.Element => {
      return (
         <div className="mid-section">
            {isEligibleToDonate ? (
               <>
                  <div>{formattedTime(confirmedAptDetails?.timeSelected)}</div>
                  <div className="bar-section">|</div>
                  <div className="name-section">
                     {confirmedAptDetails?.name}
                  </div>
               </>
            ) : (
               <>
                  {confirmedAptDetails.appointmentTypeDisplayed} until{" "}
                  {confirmedAptDetails.eligibleDateForSelectedType}
               </>
            )}
         </div>
      );
   };

   const FinalSection = ({ isEligibleToDonate }): JSX.Element => {
      return (
         <div className="final-section">
            {isEligibleToDonate ? (
               <>
                  <div>
                     {formattedDate(
                        new Date(confirmedAptDetails?.dateSelected),
                     )?.toUpperCase()}
                  </div>
                  {isMobile ? (
                     <>
                        <div>
                           <div>
                              {confirmedAptDetails?.addressOne.toUpperCase()}
                           </div>
                           <AddressTwoSection
                              confirmedAptDetails={confirmedAptDetails}
                           />
                        </div>
                     </>
                  ) : (
                     <>
                        <div className="final-second-section">
                           <div>
                              {confirmedAptDetails?.addressOne.toUpperCase()}
                           </div>
                           <div className="bar-section">|</div>
                           <AddressTwoSection
                              confirmedAptDetails={confirmedAptDetails}
                           />
                        </div>
                     </>
                  )}
               </>
            ) : (
               <>Please select a different appointment type</>
            )}
         </div>
      );
   };

   const LeftBtnContent = (
      appointmentKind: AppointmentKinds,
   ): JSX.Element | string => {
      switch (appointmentKind) {
         case AppointmentKinds.CONFIRMED:
            return <>Add to Calendar {!isMobile && <CalendarIconImg />}</>;
         case AppointmentKinds.RESCHEDULED:
            return (
               <>
                  Keep {!isMobile && "Appointment"} <CrossIconImg />
               </>
            );
         case AppointmentKinds.CANCEL:
            return (
               <>
                  Keep {!isMobile && "Appointment"} <CrossIconImg />
               </>
            );
         default:
            return "";
      }
   };

   const rightBtnContent = (
      appointmentKind: AppointmentKinds,
   ): JSX.Element | string => {
      if (
         [
            AppointmentKinds.CONFIRMED,
            AppointmentKinds.NOT_ELIGIBLE,
            AppointmentKinds.REQUESTED,
         ].includes(appointmentKind)
      )
         return (
            <>
               Close <CrossIconImg />
            </>
         );
      if (appointmentKind === AppointmentKinds.RESCHEDULED)
         return (
            <>
               {" "}
               Reschedule {!isMobile && "Appointment"} <CheckMarkImg />
            </>
         );
      if (appointmentKind === AppointmentKinds.CANCEL)
         return (
            <>
               {" "}
               Cancel {!isMobile && "Appointment"} <CheckMarkImg />
            </>
         );
      return "";
   };

   const LeftButtonFunction = (appointmentKind: AppointmentKinds): void => {
      if (
         [AppointmentKinds.RESCHEDULED, AppointmentKinds.CANCEL].includes(
            appointmentKind,
         )
      ) {
         onClose();
      }
      if (appointmentKind === AppointmentKinds.CONFIRMED) {
         const dateSelectedVal: Date = new Date(
            confirmedAptDetails.dateSelected,
         );
         const timeSelectedVal: Date = new Date(
            confirmedAptDetails.timeSelected,
         );
         const event: IEventInterface = {
            title: `${confirmedAptDetails.userFirstName} Donor Appointment Confirmation`,
            start: [
               dateSelectedVal.getFullYear(),
               dateSelectedVal.getMonth(),
               dateSelectedVal.getDate(),
               timeSelectedVal.getHours(),
               timeSelectedVal.getMinutes(),
            ],
            location: confirmedAptDetails?.name,
            duration: { hours: 1 },
            description: `Appointment is Confirmed at ${confirmedAptDetails.addressOne},${confirmedAptDetails.city},${confirmedAptDetails.state},${confirmedAptDetails.zipCode}`,
         };
         handleCalendarDownload(event);
         onClose();
      }
   };

   const cancelAppointment = async (): Promise<void> => {
      try {
         const appointmentDataService: IAppointmentDataService =
            getAppointmentDataServiceInstance();
         await appointmentDataService.cancelAppointment(
            confirmedAptDetails.donorId,
            confirmedAptDetails.appointmentId,
         );
         setIsCancelConfirmed(true);
         onClose();
      } catch (err) {
         throw err;
      }
   };

   const handleNavigate = (): void => {
      const navDateString: string = confirmedAptDetails.dateSelected
         ? getDateStringFromDateTimeString(confirmedAptDetails.dateSelected)
         : confirmedAptDetails.driveDate;
      navigate(
         `${ROUTE_PATHS.APPOINTMENT_LIST}?drive_id=${confirmedAptDetails.driveId}&date=${navDateString}`,
      );
      sessionStorage.setItem(
         "ReschedulingAppointmentId",
         confirmedAptDetails.appointmentId,
      );
   };
   const RightButtonFunction = (appointmentKind: AppointmentKinds): void => {
      if (appointmentKind === AppointmentKinds.RESCHEDULED) {
         handleNavigate();
      }
      if (appointmentKind === AppointmentKinds.CANCEL) {
         cancelAppointment();
      }
      if (
         [
            AppointmentKinds.CONFIRMED,
            AppointmentKinds.NOT_ELIGIBLE,
            AppointmentKinds.REQUESTED,
         ].includes(appointmentKind)
      ) {
         onClose();
      }
   };

   const FooterSection = ({
      isLeftSectionAvailable,
      appointmentKind,
   }): JSX.Element => {
      return (
         <div
            className={`footer-section-css ${
               isLeftSectionAvailable
                  ? "apmt-confirmed-css"
                  : "apmt-not-confirmed-css"
            }`}
         >
            {isLeftSectionAvailable && (
               <Button
                  onClick={() => LeftButtonFunction(appointmentKind)}
                  className="btn-css"
               >
                  {LeftBtnContent(appointmentKind)}
               </Button>
            )}
            <Button
               onClick={() => RightButtonFunction(appointmentKind)}
               className={`btn-css ${
                  isMobile ? "mobile-btn-css" : "desktop-btn-css"
               }`}
            >
               {rightBtnContent(appointmentKind)}
            </Button>
         </div>
      );
   };

   return (
      <>
         <Modal.Header></Modal.Header>
         <AptConfirmModalBody>
            <div
               className={`content-section mb-5 ${
                  isMobile
                     ? "content-mobile-section"
                     : "content-desktop-section"
               }`}
            >
               {IconSection(confirmedAptDetails.appointmentKind)}
               <div className="header-section">
                  {HeaderSection(confirmedAptDetails.appointmentKind)}
               </div>
               <MidSection
                  isEligibleToDonate={
                     confirmedAptDetails.appointmentKind !==
                     AppointmentKinds.NOT_ELIGIBLE
                  }
               />
               <FinalSection
                  isEligibleToDonate={
                     confirmedAptDetails.appointmentKind !==
                     AppointmentKinds.NOT_ELIGIBLE
                  }
               />
            </div>
            <FooterSection
               isLeftSectionAvailable={[
                  AppointmentKinds.CONFIRMED,
                  AppointmentKinds.CANCEL,
                  AppointmentKinds.RESCHEDULED,
               ].includes(confirmedAptDetails.appointmentKind)}
               appointmentKind={confirmedAptDetails.appointmentKind}
            />
         </AptConfirmModalBody>
      </>
   );
}

export default AppointmentConfirmationModal;
