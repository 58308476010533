import React, { ReactElement, useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MobileMoreFiltersButton } from "../../../../components/MobileMoreFiltersButton/MobileMoreFiltersButton";
import { MyDonationFiltersModal } from "./MyDonationsFiltersModal/MyDonationFiltersModal";
import { FilterInputForm } from "../../../../components/Form/Filter/FilterInputForm";
import { FilterInputFormRow } from "../../../../components/Form/Filter/FilterInputFormRow";
import { SingleSelect } from "../../../../components/Form/Inputs/Generic/SingleSelect/SingleSelect";
import { locationDropDownOptions } from "../../../../consts";
import { MultipleSelect } from "../../../../components/Form/Inputs/Generic/MultipleSelect/MultipleSelect";
import { ReactComponent as DropIcon } from "../../../../../assets/svgs/drop.svg";
import { DateRangePicker } from "../../../../components/Form/Inputs/Generic/DateRangePicker/DateRangePicker";
import { CheckMarkImage } from "./MyDonationsFiltersModal/checkMarkImageStyled";
import {
   DonationContext,
   IDonationContext,
} from "../../context/DonationContext";
import { MoreFiltersIconControl } from "../../../../components/Form/Inputs/Instances/MoreFiltersModalControl/MoreFiltersIconControl";
import { FilterControlWrapper } from "../../../../components/Form/Filter/FilterControlWrapper";
import {
   DonationOutcome,
   DonationOutcomes,
} from "../../../../../services/bexWISE/donorDataService/IDonorDataService";
import { getEndOfDay, getStartOfDay } from "../../../../../utils/dateUtil";

export interface IMyDonationsFiltersProps {
   children: React.ReactElement | React.ReactElement[];
}

export function MyDonationsFilters(): ReactElement<IMyDonationsFiltersProps> {
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });
   const { filters, updateDonationFilter, procedureList } =
      useContext<IDonationContext>(DonationContext);
   const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false);
   return (
      <div className={"MyDonationsFilters"}>
         {isMobile ? (
            <>
               <MobileMoreFiltersButton
                  onClick={() => setShowMoreFilters(true)}
               />
               <MyDonationFiltersModal
                  showClose={true}
                  isMobile={true}
                  showModal={showMoreFilters}
                  setShowModal={setShowMoreFilters}
               />
            </>
         ) : (
            <FilterInputForm>
               <FilterInputFormRow>
                  <SingleSelect
                     values={locationDropDownOptions}
                     selectedValue={
                        locationDropDownOptions.find(
                           o => o.value === filters.selectedLocationSource,
                        )!
                     }
                     onValueSelected={value =>
                        updateDonationFilter({
                           selectedLocationSource: value.value,
                        })
                     }
                  />
                  <MultipleSelect
                     selectedValues={filters.selectedProcedures}
                     values={procedureList}
                     placeholder={"Select Procedures"}
                     onSelectedValuesChanges={newValue =>
                        updateDonationFilter({ selectedProcedures: newValue })
                     }
                     staticIconSrc={
                        <DropIcon className={"input-label-image"} />
                     }
                  />
                  <DateRangePicker
                     startDate={filters.startDate}
                     setStartDate={(newDate: string) => {
                        updateDonationFilter({
                           startDate: getStartOfDay(new Date(newDate)),
                        });
                     }}
                     endDate={filters.endDate}
                     setEndDate={(newDate: string) => {
                        updateDonationFilter({
                           endDate: getEndOfDay(new Date(newDate)),
                        });
                     }}
                  />
                  <MultipleSelect
                     selectedValues={filters.selectedDonationOutcomes}
                     values={DonationOutcomes}
                     onSelectedValuesChanges={newValue =>
                        updateDonationFilter({
                           selectedDonationOutcomes:
                              newValue as DonationOutcome[],
                        })
                     }
                     placeholder={"Select Outcome"}
                     staticIconSrc={
                        <CheckMarkImage className={"input-label-image"} />
                     }
                  />
                  <FilterControlWrapper>
                     <MoreFiltersIconControl
                        onClick={() => setShowMoreFilters(true)}
                     />
                     <MyDonationFiltersModal
                        isMobile={false}
                        showClose={false}
                        setShowModal={setShowMoreFilters}
                        showModal={showMoreFilters}
                     />
                  </FilterControlWrapper>
               </FilterInputFormRow>
            </FilterInputForm>
         )}
      </div>
   );
}
