import { IBloodDrive } from "../../../../types/IBloodDrive";
import { useEffect, useState } from "react";
import { getBloodDriveDataServiceInstance } from "../../../../services/bexWISE/bloodDriveDataService/getBloodDriveDataServiceInstance";
import {
   areOpportunitySearchQueriesEqual,
   OpportunitySearchLocationQuery,
   OpportunitySearchAccountQuery,
   OpportunitySearchQuery,
} from "../types/OpportunitySearchQuery";

interface IUseOpportunitySearch {
   currentQuery: OpportunitySearchQuery | null;
   setQuery: (query: OpportunitySearchQuery) => void;
   searchResults: IBloodDrive[] | null;
}
interface IUseOpportunitySearchProps {
   defaultSearch: OpportunitySearchQuery;
}

export function useOpportunitySearchQuery(
   props: IUseOpportunitySearchProps,
): IUseOpportunitySearch {
   const [currentQuery, setCurrentQuery] = useState<OpportunitySearchQuery>(
      props.defaultSearch,
   );
   const [searchResults, setSearchResults] = useState<IBloodDrive[]>([]);

   const setQuery = (newQuery: OpportunitySearchQuery): void => {
      // If query changed, update
      if (!areOpportunitySearchQueriesEqual(currentQuery, newQuery)) {
         setCurrentQuery(newQuery);
      }
   };

   useEffect(() => {
      // Only use the API call if currentQueryValue is not undefined, not blank, or has more than 1 characters (SCREDP-44)
      const currentQueryValue = ((<OpportunitySearchLocationQuery> currentQuery).origin)
            ? (<OpportunitySearchLocationQuery> currentQuery).origin 
            : (<OpportunitySearchAccountQuery> currentQuery).accountName;
      if (currentQueryValue !== "undefined" && typeof currentQueryValue === "string" && currentQueryValue.trim().length >= 1) {
            
            getBloodDriveDataServiceInstance()
               .getDrives(currentQuery)
               .then((results: IBloodDrive[]) => {
                  setSearchResults(results);
            });
      } else {
         setSearchResults([]);
      }
   }, [currentQuery]);

   return {
      searchResults,
      currentQuery,
      setQuery,
   };
}
