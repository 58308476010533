import React, { ReactElement } from "react";
import "./style.scss";
import { InputWrapper } from "../../InputWrapper/InputWrapper";
export interface IIconTextControlProps {
   imgSrc: string;
   text: string;
   onClick: () => void;
}

export function IconTextControl(props: IIconTextControlProps): ReactElement {
   return (
      <InputWrapper>
         <div className={"icon-text-control"} onClick={props.onClick}>
            <img src={props.imgSrc} alt="icon" />
            <div className="icon-label">{props.text}</div>
         </div>
      </InputWrapper>
   );
}
