import React from "react";
import ScheduleDonationImg from "assets/images/schedule-donation.png";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "../../../../../assets/svgs/calender.svg";
import { Section } from "../../../../components/Section/Section";
import "./style.scss";

function ScheduleDonation() {
   const isMobile: boolean = useMediaQuery({ maxWidth: 1000 });
   const navigate = useNavigate();
   return (
      <Section
         header={{
            title: "Schedule a Donation",
            icon: <CalendarIcon fill={"white"} />,
         }}
      >
         <div
            className={`section-css ${
               isMobile ? "mobile-section-css" : "desktop-section-css"
            }`}
         >
            <Image
               src={ScheduleDonationImg}
               fluid
               alt="schedule-donation-img"
               className="sch-donation-css"
            />
            <div>
               <Button
                  className="make-appointment-button"
                  variant="danger"
                  onClick={() => navigate("/")}
               >
                  Make an Appointment
               </Button>
            </div>
         </div>
      </Section>
   );
}

export default ScheduleDonation;
