import { ReactI18NextChild } from "react-i18next";
import * as React from "react";
import { Helmet } from "react-helmet-async";

interface IPageComponentProps {
   name: string;
   title: string;
   description: string;
   children?: ReactI18NextChild;
}
export function PageComponent(props: IPageComponentProps) {
   return (
      <>
         <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
         </Helmet>
         {props.children}
      </>
   );
}
