import React from "react";
import "./appointmentSlot.scss";
import { PRIMARY_COLORS } from "../../consts/colors";
import { BiCheckCircle } from "react-icons/bi";
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from "react-icons/ai";
export interface IAppointmentSlotStatusProps {
   type: "SUCCESS" | "INFO" | "FAIL";
   text: string;
}

function getBadgeBorderColor(type: "SUCCESS" | "INFO" | "FAIL") {
   switch (type) {
      case "SUCCESS":
         return PRIMARY_COLORS.IRISH_GREEN;
      case "INFO":
         return "darkorange";
      case "FAIL":
         return PRIMARY_COLORS.JASPER;
   }
}
function getBadgeIcon(type: "SUCCESS" | "INFO" | "FAIL") {
   switch (type) {
      case "SUCCESS":
         return <BiCheckCircle fill={getBadgeBorderColor(type)} />;
      case "INFO":
         return <AiOutlineInfoCircle fill={getBadgeBorderColor(type)} />;
      case "FAIL":
         return <AiOutlineCloseCircle fill={getBadgeBorderColor(type)} />;
   }
}
export function AppointmentSlotStatusBadge(props: IAppointmentSlotStatusProps) {
   const { type, text } = props;
   return (
      <div
         className={`status-badge ${type.toLowerCase()}`}
         style={{ borderColor: getBadgeBorderColor(type) }}
      >
         {getBadgeIcon(type)}
         <p style={{ color: getBadgeBorderColor(type) }}>{text}</p>
      </div>
   );
}
