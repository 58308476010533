import React from "react";
import CommonHeader from "../../../../components/CommonHeader";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import LabelContentComponent from "../LabelContentComponent";
import { US_STATES } from "../../../../consts/usStates";
import { IDonorDTO } from "../../../../../types/IDonorDTO";
import { Section } from "../../../../components/Section/Section";

interface DemographicInformationProps {
   isMobile: boolean;
   profileData: IDonorDTO;
}
const DemographicInformation: React.FC<DemographicInformationProps> = ({
   isMobile,
   profileData,
}) => {
   return (
      <Section className="personal-card-css">
         <CommonHeader title="DEMOGRAPHIC INFORMATION" />
         <div className="personal-content-css">
            <Row className="personal-section-css">
               <Col>
                  <LabelContentComponent
                     label={"Street Address(Line 1)"}
                     value={profileData.address}
                  />
               </Col>
               <Col>
                  <LabelContentComponent
                     label={"Street Address(Line 2)"}
                     value={profileData?.address2 || ""}
                  />
               </Col>
               <Col>
                  <LabelContentComponent
                     label={"City"}
                     value={profileData.city}
                  />
               </Col>
            </Row>
            <hr className="line-css" />
            <Row className="personal-section-css">
               <Col>
                  <LabelContentComponent
                     label={"State"}
                     value={
                        US_STATES.find(
                           stateVal =>
                              stateVal.abbreviation === profileData.state,
                        )?.name || profileData.state
                     }
                  />
               </Col>
               <Col>
                  <LabelContentComponent
                     label={"Zip Code"}
                     value={profileData.zipCode}
                  />
               </Col>
               <Col></Col>
            </Row>
            <hr className="line-css" />
            <Row className="personal-section-css">
               <Col>
                  <LabelContentComponent
                     label={"Home Phone"}
                     value={profileData.homePhone}
                  />
               </Col>
               <Col>
                  <LabelContentComponent
                     label={"Cell Phone"}
                     value={profileData.cellPhone}
                  />
               </Col>
               <Col>
                  <LabelContentComponent
                     label={"Business Phone"}
                     value={profileData.businessPhone}
                  />
               </Col>
            </Row>
            <hr className="line-css" />
            <Row className="personal-section-css">
               <Col>
                  <LabelContentComponent
                     label={"Home Email"}
                     value={profileData.email}
                  />
               </Col>
               <Col>
                  <LabelContentComponent
                     label={"Work Email"}
                     value={profileData.workEmail}
                  />
               </Col>
               <Col></Col>
            </Row>
         </div>
      </Section>
   );
};

export default DemographicInformation;
