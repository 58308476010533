import React, { ReactElement, useEffect, useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import MoreFilterIcon from "../../../assets/images/more-filters.png";
import "./style.scss";

export interface FiltererRendererComponentProps<T> {
   currentFilter: T;
   updateFilter: (updatedFilter: Partial<T>) => void;
}
export interface IMoreFiltersModalProps<T> {
   FilterRenderer: React.FC<FiltererRendererComponentProps<T>>;
   initialFilterValue: T;
   // Clear all values will be used when clicking clear if provided, if not provided the init values are used.
   clearAllValues?: T;
   onFilterApplied: (newFilters: T) => void;
   show: boolean;
   onHide: () => void;
   fullScreen: boolean;
   showClose: boolean;

   // Default title is "More Filters"
   title?: string;
}

const DEFAULT_FILTERS_MODAL_TITLE: string = "More Filters";
export function FiltersModal<T>(
   props: IMoreFiltersModalProps<T>,
): ReactElement<IMoreFiltersModalProps<T>> {
   const [currentFilter, setCurrentFilter] = useState<T>(
      props.initialFilterValue,
   );

   useEffect(() => {
      setCurrentFilter(props.initialFilterValue);
   }, [props.initialFilterValue]);

   const resetFilters = () => {
      if (props.clearAllValues) {
         setCurrentFilter(props.clearAllValues);
      } else {
         setCurrentFilter(props.initialFilterValue);
      }
   };

   return (
      <Modal
         className={"FiltersModal"}
         show={props.show}
         keyboard={false}
         backdrop={"static"}
         onHide={props.onHide}
         centered={true}
         size={"sm"}
         fullscreen={props.fullScreen ? true : undefined}
      >
         <Modal.Header closeButton={props.showClose}>
            <Modal.Title className="d-flex gap-3 align-items-center justify-content-center fw-bold w-100 fs-5">
               <Image src={MoreFilterIcon} width="25" height="25" />
               <span>{props.title || DEFAULT_FILTERS_MODAL_TITLE}</span>
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="modal-body-css">
               <props.FilterRenderer
                  currentFilter={currentFilter}
                  updateFilter={(updatedPartialFilter: Partial<T>) => {
                     setCurrentFilter({
                        ...currentFilter,
                        ...updatedPartialFilter,
                     });
                  }}
               />
            </div>
         </Modal.Body>
         <Modal.Footer>
            <Button
               className={"footer-button"}
               variant="link"
               onClick={resetFilters}
            >
               Clear All
            </Button>
            <Button
               variant="link"
               className={"footer-button"}
               onClick={() => {
                  props.onFilterApplied(currentFilter);
               }}
            >
               Apply
            </Button>
         </Modal.Footer>
      </Modal>
   );
}
