import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { css } from "styled-components/macro";
import { IconsCss } from "../styled";
import { ReactComponent as CrossIconLabel } from "../../../../assets/svgs/cross.svg";
import { ReactComponent as ForwardArrow } from "../../../../assets/svgs/forward-arrow.svg";
import { ReactComponent as DotSvg } from "../../../../assets/svgs/dot.svg";
import { PRIMARY_COLORS } from "../../../consts/colors";

const PaddingLeftCss = css`
   padding-left: 30px;
`;

export const NewAccountForm = styled(Form)`
   .footer-section-css {
      display: grid;
      grid-template-columns: auto auto;
      .btn-css {
         font-size: 12px;
         background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
         border-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
      }
   }
   .mobile-footer-css {
      padding-left: 10px;
      grid-gap: 5%;
   }
   .desktop-footer-css {
      width: 50%;
      float: right;
      padding-left: 30px;
      grid-gap: 30%;
   }
   .mobile-required-css {
      justify-content: flex-end;
      margin-top: 10px;
   }
`;
export const DisplayInformationDiv = styled.div`
   font-size: 14px;
   margin-bottom: 35px;
`;

export const FormGroupPaddingLeft = styled(Form.Group)`
   ${PaddingLeftCss}
`;

export const RequiredFieldsDiv = styled.div`
   ${PaddingLeftCss};
   display: flex;
   align-items: center;
   color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
   font-size: 13px;
`;

export const SectionHeader = styled.h6`
   color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
   margin-bottom: 20px;
   font-weight: 600;
   height: 1.5em;
`;

export const CrossIconImg = styled(CrossIconLabel)`
   ${IconsCss};
   margin-left: 5px;
`;
export const ForwardIconImg = styled(ForwardArrow)`
   margin-left: 5px;
`;

export const StyledDot = styled(DotSvg)`
   fill: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
`;
