import styled from "styled-components";

export const ProfileSectionDiv = styled.div`
   background-color: white !important;
   box-shadow: 10px 3px 15px 0px rgb(0 0 0 / 28%);
   display: grid;
   grid-template-columns: 220px auto;
   align-items: center;
   height: 130px;
   .middle-section {
      padding: 50px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-weight: bold;
      .name-section {
         font-size: 14px;
      }
      .details-section {
         display: flex;
         font-size: 12px;
         gap: 20px;
      }
   }
`;
