import React, { useContext } from "react";
import { FutureAppointmentDesktop } from "./FutureAppointmentDesktop";
import { useMediaQuery } from "react-responsive";
import FutureAppointmentMobile from "./FutureAppointmentMobile";
import { Button } from "react-bootstrap";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { IDonorEligibilityDate } from "services/bexWISE/donorDataService/IDonorDataService";
import { Section } from "../../../../components/Section/Section";
import { ReactComponent as CalendarIcon } from "assets/svgs/calender.svg";
import "./style.scss";
import { UserContext } from "../../../../contexts/user/userContext";
import { MyAppointmentsPageContext } from "../../context/MyAppointmentsPageContext";

function eligibleDisplay(eligibilityDates: IDonorEligibilityDate[]): string {
   let eligibleTypes: string = "";
   for (let i = 0; i < eligibilityDates.length; i++) {
      let each: IDonorEligibilityDate = eligibilityDates[i];
      const currentTime: number = new Date().getTime();
      const eligibleTime: number = new Date(each.eligibleDate).getTime();
      const middleValue: string =
         i > 0 ? (i === eligibilityDates.length - 1 ? " and " : ", ") : "";
      if (eligibleTime < currentTime) {
         eligibleTypes =
            eligibleTypes + middleValue + each.appointmentTypeDescription;
      }
   }
   return eligibleTypes;
}

const FutureAppointments: React.FC = () => {
   const isMobile: boolean = useMediaQuery({ maxWidth: 1000 });
   const navigate: NavigateFunction = useNavigate();
   const { eligibilityDates } = useContext(UserContext);
   const { pastAppointments, futureAppointments, triggerUpdate } = useContext(
      MyAppointmentsPageContext,
   );
   const isPastAppointmentsAvailable: boolean =
      (pastAppointments && pastAppointments.length > 0) || false;

   return (
      <Section
         header={{
            title: "Future Appointments",
            icon: <CalendarIcon fill={"white"} />,
         }}
         className="mt-3"
      >
         <div>
            {futureAppointments && futureAppointments.length !== 0 ? (
               <>
                  {futureAppointments?.map((appointment, index) => {
                     return (
                        <React.Fragment key={appointment.appointmentId}>
                           {isMobile ? (
                              <FutureAppointmentMobile
                                 appointments={futureAppointments}
                                 index={index}
                                 appointment={appointment}
                                 setTriggerAppointmentPageCall={triggerUpdate}
                              />
                           ) : (
                              <FutureAppointmentDesktop
                                 appointments={futureAppointments}
                                 index={index}
                                 appointment={appointment}
                                 setTriggerAppointmentPageCall={triggerUpdate}
                              />
                           )}
                        </React.Fragment>
                     );
                  })}
               </>
            ) : (
               <div
                  className={`no-future-appointments-section ${isMobile ? "mobile-section-css mt-4" : "desktop-section-css"
                     }`}
               >
                  <div>
                     <div className={isMobile ? "" : "mb-3"}>
                        <b>No upcoming visits to display</b>
                     </div>
                     {isPastAppointmentsAvailable && eligibilityDates && (
                        <div>
                           {eligibleDisplay(eligibilityDates) &&
                              "You are eligible to donate:"}
                           <b>{eligibleDisplay(eligibilityDates)}</b>
                        </div>
                     )}
                  </div>
                  {isPastAppointmentsAvailable && (
                     <Button
                        className={isMobile ? "make-appointment-button mt-2 mb-4" : "make-appointment-button m-4"}
                        onClick={() => navigate("/")}
                     >
                        Make an Appointment
                     </Button>
                  )}
               </div>
            )}
         </div>
      </Section>
   );
};

export default FutureAppointments;
