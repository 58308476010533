import { LocationOption, SortBy } from "app/consts";
import React, { useContext, useEffect, useState } from "react";
import {
   DonationOutcome,
   IDonorDonationDTO,
} from "services/bexWISE/donorDataService/IDonorDataService";
import {
   chronologicalOrderForDonation,
   procedureSorting,
   locationSorting,
} from "utils/sorting";
import { UserContext } from "../../../contexts/user/userContext";
import { getDonorDataServiceInstance } from "../../../../services/bexWISE/donorDataService/getDonorDataServiceInstance";
import {
   getUniqueDonationCreditTo,
   getUniqueDonationProcedure,
} from "../utils/utils";
import { filterAlgoForDonations } from "../../../../utils/filterForDonations";
import {
   getDefaultEndDate,
   getDefaultStartDate,
} from "../../../../utils/defaultDates";

export interface IMyDonationsFilters {
   selectedLocationSource: LocationOption;
   selectedProcedures: string[];
   creditTo: string[];
   selectedDonationOutcomes: DonationOutcome[];
   startDate: Date;
   endDate: Date;
   sortBy: string;
}

const initialMyDonationsFilters: IMyDonationsFilters = {
   selectedLocationSource: LocationOption.ALL,
   selectedProcedures: [],
   creditTo: [],
   selectedDonationOutcomes: [],
   startDate: new Date(new Date().getFullYear(), 0, 1),
   endDate: new Date(),
   sortBy: SortBy.CHRONOLOGICAL_ORDER,
};

export interface IDonationContextState {
   filteredDonations: IDonorDonationDTO[];
   donations: IDonorDonationDTO[];
   procedureList: string[];
   statusList: string[];
   creditToList: string[];
   filters: IMyDonationsFilters;
}

const initialContextState: IDonationContextState = {
   filteredDonations: [],
   donations: [],
   filters: initialMyDonationsFilters,
   procedureList: [],
   statusList: [],
   creditToList: [],
};

export interface IDonationContext extends IDonationContextState {
   updateDonationFilter(update: Partial<IMyDonationsFilters>);
}

const initialDonationContext: IDonationContext = {
   ...initialContextState,
   updateDonationFilter: (update: Partial<IMyDonationsFilters>) => {},
};

interface DonationContextProps {
   children: React.ReactNode;
}

export const DonationContext = React.createContext<IDonationContext>(
   initialDonationContext,
);

export const DonationContextProvider: React.FC<DonationContextProps> = ({
   children,
}) => {
   const { userDonorId } = useContext(UserContext);
   const [state, setState] =
      useState<IDonationContextState>(initialContextState);

   useEffect(() => {
      if (userDonorId) {
         getDonorDataServiceInstance()
            .getDonorDonations(userDonorId)
            .then((donations: IDonorDonationDTO[]) => {
               const sorted: IDonorDonationDTO[] =
                  chronologicalOrderForDonation(donations);
               const creditToList: string[] =
                  getUniqueDonationCreditTo(donations);
               setState({
                  ...state,
                  creditToList,
                  donations: donations,
                  procedureList: getUniqueDonationProcedure(donations),
                  filteredDonations: sorted,
                  filters: {
                     ...state.filters,
                     creditTo: creditToList,
                     startDate: getDefaultStartDate(donations, "date"),
                     endDate: getDefaultEndDate(donations, "date"),
                  },
               });
            });
      }
   }, [userDonorId]);

   const sortingByLocationOrAccountType = (
      donations: IDonorDonationDTO[],
      formValues: IMyDonationsFilters,
   ): IDonorDonationDTO[] => {
      switch (formValues.sortBy) {
         case SortBy.CHRONOLOGICAL_ORDER:
            return chronologicalOrderForDonation(donations);
         case SortBy.LOCATION:
            return locationSorting(donations);
         case SortBy.PROCEDURE_ORDER:
            return procedureSorting(donations);
      }
      return donations;
   };

   useEffect(() => {
      setState({
         ...state,
         filteredDonations: sortingByLocationOrAccountType(
            filterAlgoForDonations(state.filters, state.donations) || [],
            state.filters,
         ),
      });
   }, [state.filters]);

   return (
      <DonationContext.Provider
         value={{
            ...state,
            updateDonationFilter: (update: Partial<IMyDonationsFilters>) =>
               setState({
                  ...state,
                  filters: {
                     ...state.filters,
                     ...update,
                  },
               }),
         }}
      >
         {children}
      </DonationContext.Provider>
   );
};
